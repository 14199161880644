<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                <span *ngIf="!(isLoading$|async)">{{pageTitle}}</span>
                <div class="w-100 d-flex" *ngIf="isLoading$|async">
                    <p-skeleton class="mx-auto" width="250px" height="30px"></p-skeleton>
                </div>
            </h1>
        </div>
    </div>
</div>
<section class="faith-section padding-tb shape-3 mb-100 py-3 my-5">
    <div class="container px-5 py-4">
        <div class="row">
            <div class="col-12" *ngIf="!(isLoading$|async)">
                <div class="faith-content">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade" *ngFor="let substance of substances" [class.show]="substance.selected" [class.active]="substance.selected" [id]="substance.id" role="tabpanel">
                            <div class="lab-item faith-item tri-shape-1 pattern-2">
                                <div class="lab-inner d-flex align-items-center">
                                    <div class="lab-thumb" *ngIf="substance.iconPath">
                                        <img class="card-img-top" [src]="imageUrl+substance.iconPath" alt="religious-information">
                                    </div>
                                    <div class="lab-content">
                                        <h4>{{ substance.title }}</h4>
                                        <p [class.mw-700]="substance.iconPath">{{ substance?.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-pills mb-3 align-items-center justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" *ngFor="let substance of substances">
                            <a href="javascript:void(0)" class="nav-link" (click)="setActiveTab(substance.id)" data-bs-toggle="pill" role="tab" [attr.aria-controls]="substance.id" [class.active]="substance.selected">
                                <img *ngIf="substance.iconPath" class="card-img-top" [src]="imageUrl+substance.iconPath" alt="religious-information">
                                <img *ngIf="!substance.iconPath" class="card-img-top" src="assets/img/no-image-icon.png" alt="religious-information">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- skeleton start -->
            <div class="col-12" *ngIf="isLoading$|async">
                <div class="faith-content">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="lab-item faith-item tri-shape-1 pattern-2">
                                <div class="lab-inner d-flex align-items-center">
                                    <div class="lab-thumb">
                                       <p-skeleton width="300px" height="312px"></p-skeleton>
                                    </div>
                                    <div class="lab-content">
                                        <h4><p-skeleton width="312px" height="28px"></p-skeleton></h4>
                                        <p><p-skeleton min-width="700px" height="150px"></p-skeleton></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-pills mb-3 align-items-center justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                <p-skeleton width="88px" height="88px"></p-skeleton>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                <p-skeleton width="88px" height="88px"></p-skeleton>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                <p-skeleton width="88px" height="88px"></p-skeleton>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
               <!-- skeleton end -->
        </div>
    </div>
</section>
