import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PrayerTime } from "../view-models/prayer-times";

@Injectable({
    providedIn:'root'
})
export class PrayerTimesService{
    constructor
    (
      private http:HttpClient
    ){}

    getPrayerTimes(longitude:number,latitude:number):Observable<PrayerTime>{
        const url = `https://admin.awqatsalah.com/api/PlaceAPI/GetByDailyCityId?latitude=${latitude}&longitude=${longitude}`;
        return this.http.get<PrayerTime>(url);
    }
}