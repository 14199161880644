import { ContentResponse } from './../../../view-models/models/content';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';
import { Content, ContentObject } from 'src/app/view-models/models/content';
@Component({
  selector: 'app-fetva-detail',
  templateUrl: './fetva-detail.component.html',
  styleUrls: ['./fetva-detail.component.scss']
})
export class FetvaDetailComponent {
  fatwa:Content=null;
  fatwas:Content[]=[];

  first: number = 0;
  rows: number = 13;

  fatwasCount:number=0;

  language=localStorage.getItem("language")

  constructor(
    private activatedRoute:ActivatedRoute,
    private contentService:ContentService
  ) {}

  ngOnInit(): void {
    this.getFatwa();
    this.getFatwas();
  }

  getFatwa(){
   this.activatedRoute.data.subscribe(
      (data: { fatwa: ContentObject }) => {
        if (data.fatwa) {
          this.fatwa=data.fatwa.data 
        }
      });
  }

  getFatwas(){
    this.contentService.getContents(this.language,32,this.first,this.rows).subscribe((data)=>{
      this.fatwas=data.data.filter(f=>f.id!=this.fatwa.id).sort((a,b)=>a.order-b.order)
      this.fatwasCount=data.totalCount-1;
    })
   }

   onPageChange(event) {
       this.first = event.page;
       this.rows = event.rows;
       this.getFatwas();
   }
}
