import { Component } from '@angular/core';

@Component({
  selector: 'app-kurban',
  templateUrl: './kurban.component.html',
  styleUrls: ['./kurban.component.scss']
})
export class KurbanComponent {

}
