import { Component, OnInit } from '@angular/core';
import { SmallSliderService } from 'src/app/services/small-slider.service';
import { SmallSlider } from 'src/app/view-models/models/small-slider';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-home-icons',
  templateUrl: './home-icons.component.html',
  styleUrls: ['./home-icons.component.scss']
})
export class HomeIconsComponent implements OnInit{

  smallSliders:SmallSlider[]=[];
  serverUrl=environment.serverUrl;
  languageCode=localStorage.getItem("language");

  constructor
  (
    private sliderService:SmallSliderService
  ){}

  ngOnInit(): void {
    this.getSliders();
  }

  getSliders(){
    this.sliderService.getSmallSliders(this.languageCode).subscribe(data=>{
      this.smallSliders=data.data
      .filter(a => a.isActive)
      .sort((a,b)=>{
        return a.order-b.order;
      });
    })
  }

  makeUrlFriendly(title: string): string {
    return title.toLowerCase() // Tüm harfleri küçük harf yap
        .replace(/ç/g, 'c')     // Türkçe karakterleri değiştir
        .replace(/ğ/g, 'g')
        .replace(/ı/g, 'i')
        .replace(/ö/g, 'o')
        .replace(/ş/g, 's')
        .replace(/ü/g, 'u')
        .replace(/[^a-z0-9]+/g, '-') // Boşluk ve özel karakterleri '-' ile değiştir
        .replace(/^-+|-+$/g, '');    // Başta ve sonda kalan '-' karakterlerini kaldır
}


}
