
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">Hac Kayıtları</h1>
        </div>
    </div>
</div>
<div class="container my-5 px-3">
   <div class="row">
    <div class="col-12 mb-3">
        <img src="assets/img/homeIcon-pages/hac-organizasyon.jpg" alt="">
    </div>
    <div class="col-12">
        <h1 class="color-main3">{{currentYear}} YILI HAC ORGANİZASYONU</h1>
        <p>
            2024 yılında Hac organizasyonu için başlayan ön kayıtlar, milyonlarca Müslüman için manevi bir hazırlık sürecini başlatmıştır. Hac, bir Müslümanın hayatındaki en kutsal anlardan biridir. Bu kutlu yolculuk, takvayı kuşanma ve ömür boyu Allah'ı anma şuuruyla yaşama gibi büyük manevi kazanımlar sunmaktadır. Hac, sadece bireyin kendi iç dünyasına değil, aynı zamanda Müslüman kardeşlerine ve tarihe doğru gerçekleştirdiği bir seferdir.
        </p>
        <p>
            Her adımı, ulu'l-azm peygamberlerin, Kâinatın Efendisinin ve Sahabe-i kiramın hatıraları ve izleriyle dolu olan bu kutlu iklime yapılan yolculuğun, sadece uygulamaların değil, aynı zamanda derin manevi boyutları bulunmaktadır. Hac, sıradan bir seyahatten çok daha fazlasıdır. Bu ibadet yolculuğunun amacı ve muhtevası, diğer yolculuklardan ayrı ve özel kılan unsurlardır.
        </p>
        <p>
            Hac, birbirinden farklı coğrafyalardan gelen Müslümanların bir araya gelip, birlikte ibadet etmeleri, aynı duyguları paylaşmaları ve kardeşlik bağlarını güçlendirmeleri açısından da büyük bir öneme sahiptir. Hac, sadece bireyin kendi manevi gelişimi için değil, aynı zamanda İslam ümmetinin birliğini ve dayanışmasını pekiştiren bir vesiledir.
        </p>
        <p>
            Ön kayıtların başlamasıyla birlikte, Müslümanlar bu kutsal yolculuk için fiziksel ve manevi bir hazırlık içine girmişlerdir. Hac, bir ibadetin ötesinde, insanları bir araya getiren, kardeşlik duygularını pekiştiren ve Allah'a yönelik bir şükran ifadesi olan bir eylemdir. Umuyoruz ki, bu kutsal yolculuk, katılımcılara manevi zenginliklerin yanı sıra hayatlarında kalıcı bir etki bırakacaktır.
        </p>
        <h5 class="color-main3">
            Hac Kayıtları
        </h5>
        <p>
            Hacca gitmek üzere müracaat eden vatandaşlarımızın sayısı her geçen sene artmaktadır. Yurtdışına verilen kontenjanların ise kısıtlı olduğu malumlarıdır. Bu nedenle, hacca kayıt hakkı yalnızca daha önce hiç hacca gitmemiş olanlara tanınmaktadır. Kontenjan dışı kalan başvuru sahiplerine iade işlemleri başlatılacaktır. Başvuru sahibi dilediği taktirde bir sonraki yıl için hakkını korumak üzere talepte bulunabilir.
        </p>
    </div>
   </div>
</div>