<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{"DIRECTOR_DETAIL"|translate}}
            </h1>
        </div>
    </div>
</div>
<ng-container>
    <section class="gorevli-detail my-5 px-1 py-5">
        <div class="container">
           <div class="gorevli-wrapper mx-auto shadow p-3 mb-3 rounded">
                <div class="row">
                   <ng-container *ngIf="user && !(isLoading$|async)">
                    <div class="col-md-3 mb-3">
                        <img *ngIf="user.profilePhoto" class="img-fluid rounded mb-3" [src]="imageUrl+user.profilePhoto" alt="">
                        <img *ngIf="!user.profilePhoto" class="img-fluid rounded mb-3"  src="assets/img/none-profile.png" alt="">
                        <h4 class="text-center color-optional mb-4">
                            {{user?.memberType?.name}}
                        </h4>
                    </div>
                    <div class="col-md-9">
                        <h1 class="mb-3 color-main3">
                            {{user.firstName}} {{user.lastName}}
                        </h1>
                        <p>
                            {{language=='tr'?user.biographyTR:user.biographyEN}}
                        </p>
                    </div>
                   </ng-container>
                   <ng-container *ngIf="isLoading$|async">
                    <div class="col-md-3 mb-3">
                        <p-skeleton min-width="298px" height="313px"></p-skeleton>
                        &nbsp;
                        <p-skeleton min-width="300px" height="30px"></p-skeleton>
                    </div>
                    <div class="col-md-9">
                        <h1 class="mb-3 color-main3">
                            <p-skeleton min-width="500px" height="48px"></p-skeleton>
                        </h1>
                        <p>
                            <p-skeleton min-width="942px" height="246px"></p-skeleton>
                        </p>
                    </div>
                   </ng-container>
                </div>
           </div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="!user && !(isLoading$|async)">
    <section class="no-content text-center my-5 py-5">
       <div class="container">
        <div class="message shadow d-inline-block p-5 rounded">
            <h1 class="color-main3">
                {{"ERROR"|translate}}
            </h1>
            <p>
                {{"NO_USER"|translate}}
            </p>
            <a [routerLink]="['/']" class="default-btn btn-sm float-end w-100"><i class='fa-solid fa-house icon-arrow before'></i><span class="label">
                {{"HOMEPAGE"|translate}}
            </span><i class="fa-solid fa-house icon-arrow after"></i></a>
        </div>
       </div>
    </section>
</ng-container>