import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/department.service';
import { DepartmentUser, DepartmentUserList } from 'src/app/view-models/models/department';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-atasemiz',
  templateUrl: './atasemiz.component.html',
  styleUrls: ['./atasemiz.component.scss']
})
export class AtasemizComponent implements OnInit{

  attache:DepartmentUser;
  previousAttaches:DepartmentUser[]=[];

  language = localStorage.getItem("language");

  imageUrl:string=environment.serverUrl;
 
  constructor(
    private departmentService:DepartmentService
  ) {}

  ngOnInit(): void {
    this.getAttache();
    this.getPreviousAttaches();
  }

  getAttache(){
    this.departmentService.getAllDepartmentUsers("00000000-0000-0000-0000-000000000000",this.language,16,1).subscribe((data:DepartmentUserList)=>{
      this.attache=data.data.filter(x=>x.isActive)[0];
    })
  }

  getPreviousAttaches(){
    this.departmentService.getAllDepartmentUsers("00000000-0000-0000-0000-000000000000",this.language,14,0).subscribe((data:DepartmentUserList)=>{
      this.previousAttaches=data.data.filter(x=>x.isActive).sort((a,b)=>{
        return a.order-b.order;
      });
    })
  }
}
