import { Component, OnInit } from '@angular/core';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { FooterService } from 'src/app/services/footer.service';
import { AppSetting } from 'src/app/view-models/models/app-setting';
import { FooterArea, FooterResponse } from 'src/app/view-models/models/footer';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerAreas:FooterArea[]=[];
  appSettings:AppSetting[]=[];

  language=localStorage.getItem("language")

  serverUrl=environment.serverUrl;

  constructor(
    private footerService:FooterService,
    private appSettingService:AppSettingService
  ) { }

  ngOnInit(): void {
    this.getFooterItems();
    this.getAppSettings();
  }

  getFooterItems(){
    this.footerService.getFooterItems(this.language).subscribe((data:FooterResponse)=>{
      this.footerAreas=data.data;
    })
  }

  getAppSettings(){
    this.appSettingService.getAppSettings().subscribe((data:AppSetting[])=>{
      this.appSettings=data;
    })
  }

  getGoogleMapsUrl(address: string): string {
    return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
  }

}
