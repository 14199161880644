import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';
import { Content, ContentObject } from 'src/app/view-models/models/content';

@Component({
  selector: 'app-hutbe-detail',
  templateUrl: './hutbe-detail.component.html',
  styleUrls: ['./hutbe-detail.component.scss']
})
export class HutbeDetailComponent {
  sermon:Content=null;

  constructor(
    private activatedRoute:ActivatedRoute,
    private contentService:ContentService
  ) {}

  ngOnInit(): void {
    this.getSermons();
  }

  getSermons(){
   this.activatedRoute.data.subscribe(
      (data: { sermon: ContentObject }) => {
        if (data.sermon) {
          this.sermon=data.sermon.data 
        }
      });
  }

}
