import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { Content, ContentObject } from 'src/app/view-models/models/content';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.scss']
})
export class ContentDetailComponent implements OnInit{
  content:Content=null;
  isLoading$:Observable<boolean>;
  private _albums: Array<any> = [];

  serverUrl:string=environment.serverUrl;

  constructor(
    private activatedRoute:ActivatedRoute,
    private contentService:ContentService,
    private _lightbox: Lightbox
  ) {
    this.isLoading$=contentService.isLoading$;
  }

  ngOnInit(): void {
    this.getContent();
  }

  getContent(){
   this.activatedRoute.data.subscribe(
      (data: { content: ContentObject }) => {
        if (data.content) {
          this.content=data.content.data;
          console.log(this.content)
          this.setLightboxAlbum();
        }
      });
  }

  setLightboxAlbum(){
    for (let i = 0; i < this.content?.contentImages.length; i++) {
      const src = this.serverUrl+this.content.contentImages[i].imagePath;
      const caption = `Image ${i+1}`;
      const thumb = this.serverUrl+this.content.contentImages[i].imagePath;
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };
  
      this._albums.push(album);
    }
  }

  owlOptions: OwlOptions = {
    autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoHeight: true,
        autoplay: false,
        dots: true,
        loop: false,
        margin:100,
        items:1,
        nav: false,
        autoplayTimeout: 4000
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }


}
