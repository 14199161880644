<div class="blog-slides">
    <owl-carousel-o [options]="blogSlides">
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/single-blog" class="d-block">
                        <img src="assets/img/information-img.png" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <!-- <ul class="post-meta">
                        <li class="post-author">
                            <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                            By: <a routerLink="/" class="d-inline-block">Steven Smith</a>
                        </li>
                        <li><a routerLink="/">August 30, 2019</a></li>
                    </ul> -->
                    <h3><a routerLink="/single-blog" class="d-inline-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, debitis.</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">İncele <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/single-blog" class="d-block">
                        <img src="assets/img/banner-img4.png" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <!-- <ul class="post-meta">
                        <li class="post-author">
                            <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                            By: <a routerLink="/" class="d-inline-block">Lina D'Souja</a>
                        </li>
                        <li><a routerLink="/">August 29, 2019</a></li>
                    </ul> -->
                    <h3><a routerLink="/single-blog" class="d-inline-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, debitis.</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">İncele <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/single-blog" class="d-block">
                        <img src="assets/img/information-img.png" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <!-- <ul class="post-meta">
                        <li class="post-author">
                            <img src="assets/img/user3.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                            By: <a routerLink="/" class="d-inline-block">David Malan</a>
                        </li>
                        <li><a routerLink="/">August 28, 2019</a></li>
                    </ul> -->
                    <h3><a routerLink="/single-blog" class="d-inline-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, debitis.</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">İncele <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/single-blog" class="d-block">
                        <img src="assets/img/banner-img4.png" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <!-- <ul class="post-meta">
                        <li class="post-author">
                            <img src="assets/img/user5.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                            By: <a routerLink="/" class="d-inline-block">David Warner</a>
                        </li>
                        <li><a routerLink="/">August 27, 2019</a></li>
                    </ul> -->
                    <h3><a routerLink="/single-blog" class="d-inline-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, debitis.</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">İncele <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-blog-post mb-30">
                <div class="post-image">
                    <a routerLink="/single-blog" class="d-block">
                        <img src="assets/img/information-img.png" alt="image">
                    </a>
                </div>
                <div class="post-content">
                    <!-- <ul class="post-meta">
                        <li class="post-author">
                            <img src="assets/img/user6.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                            By: <a routerLink="/" class="d-inline-block">Olivar Waise</a>
                        </li>
                        <li><a routerLink="/">August 26, 2019</a></li>
                    </ul> -->
                    <h3><a routerLink="/single-blog" class="d-inline-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt, debitis.</a></h3>
                    <a routerLink="/single-blog" class="read-more-btn">İncele <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>