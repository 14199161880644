import { Component } from '@angular/core';

@Component({
  selector: 'app-donation-campaigns',
  templateUrl: './donation-campaigns.component.html',
  styleUrls: ['./donation-campaigns.component.scss']
})
export class DonationCampaignsComponent {
  language=localStorage.getItem("language");
}
