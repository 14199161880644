import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CountUpModule } from 'ngx-countup';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SkeletonModule } from 'primeng/skeleton';
import { MatDialogModule } from '@angular/material/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderStyleOneComponent } from './components/common/header-style-one/header-style-one.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { HomeelevenMainBannerComponent } from './components/pages/home-page-eleven/homeeleven-main-banner/homeeleven-main-banner.component';
import { HeaderStyleSixComponent } from './components/common/header-style-six/header-style-six.component';
import { HeaderStyleSevenComponent } from './components/common/header-style-seven/header-style-seven.component';
import { PrayerTimesComponent } from './components/common/prayer-times/prayer-times.component';
import { BirligimizComponent } from './components/pages/birligimiz/birligimiz.component';
import { AtasemizComponent } from './components/pages/atasemiz/atasemiz.component';
import { YonetimKuruluComponent } from './components/pages/yonetim-kurulu/yonetim-kurulu.component';
import { DinGorevlileriComponent } from './components/pages/din-gorevlileri/din-gorevlileri.component';
import { DinHizmetleriComponent } from './components/pages/din-hizmetleri/din-hizmetleri.component';
import { EgitimHizmetleriComponent } from './components/pages/egitim-hizmetleri/egitim-hizmetleri.component';
import { HacVeUmreHizmetleri } from './components/pages/hac-ve-umre-hizmetleri/hac-ve-umre-hizmetleri';
import { KuranMealiComponent } from './components/pages/kuran-meali/kuran-meali.component';
import { IngilizceBrosurlerComponent } from './components/pages/ingilizce-brosurler/ingilizce-brosurler.component';
import { CamilerComponent } from './components/pages/camiler/camiler.component';
import { HomeIconsComponent } from './components/common/home-icons/home-icons.component';
import { HacKayitComponent } from './components/pages/hac-kayit/hac-kayit.component';
import { UmreKayitComponent } from './components/pages/umre-kayit/umre-kayit.component';
import { KurbanComponent } from './components/pages/kurban/kurban.component';
import { ZekatFitreComponent } from './components/pages/zekat-fitre/zekat-fitre.component';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { HomeoneAboutComponent } from './components/pages/home-page-one/homeone-about/homeone-about.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { CamiDetayComponent } from './components/pages/camiler/cami-detay/cami-detay.component';
import { DiniYayinlarComponent } from './components/pages/dini-yayinlar/dini-yayinlar.component';
import { DiniBilgilerComponent } from './components/pages/dini-bilgiler/dini-bilgiler.component';
import { IslaminSartlariComponent } from './components/pages/dini-bilgiler/islamin-sartlari/islamin-sartlari';
import { ImaninSartlariComponent } from './components/pages/dini-bilgiler/imanin-sartlari/imanin-sartlari';
import { NewsSliderComponent } from './components/common/news-slider/news-slider.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { HutbelerFetvalarComponent } from './components/pages/hutbeler-fetvalar/hutbeler-fetvalar.component';
import { HutbeDetailComponent } from './components/pages/hutbe-detail/hutbe-detail.component';
import { FetvaDetailComponent } from './components/pages/fetva-detail/fetva-detail.component';
import { DetayComponent } from './components/pages/din-gorevlileri/detay/detay.component';
import { IhtidaHizmetleriComponent } from './components/pages/ihtida-hizmetleri/ihtida-hizmetleri.component';
import { NewsComponent } from './components/pages/news/news.component';
import { SermonsComponent } from './components/pages/sermons/sermons.component';
import { createTranslateLoader } from './translater-loader';
import { DonationCampaignsComponent } from './components/pages/donation-campaigns/donation-campaigns.component';
import { GeneralComponent } from './components/pages/general/general.component';
import { NoDataComponent } from './components/pages/no-data/no-data.component';
import { ToastrModule } from 'ngx-toastr';
import { YonetimKuruluDetayComponent } from './components/pages/yonetim-kurulu/yonetim-kurulu-detay/yonetim-kurulu-detay.component';
import { MosquePersonDetailComponent } from './components/pages/camiler/mosque-person-detail/mosque-person-detail.component';
import { PreviousAttacheDetailComponent } from './components/pages/atasemiz/previous-attache-detail/previous-attache-detail.component';
import { TabContentsComponent } from './components/common/tab-contents/tab-contents.component';
import { ContentDetailComponent } from './components/pages/content-detail/content-detail.component';
import { SubstanceComponent } from './components/pages/substance/substance.component';
import { RadioComponent } from './components/pages/radio/radio.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderStyleOneComponent,
    HeaderStyleSevenComponent,
    ErrorPageComponent,
    ContactPageComponent,
    HomePageOneComponent,
    HomeoneAboutComponent,
    BlogComponent,
    HomeelevenMainBannerComponent,
    HeaderStyleSixComponent,
    PrayerTimesComponent,
    BirligimizComponent,
    AtasemizComponent,
    YonetimKuruluComponent,
    DinGorevlileriComponent,
    DinHizmetleriComponent,
    EgitimHizmetleriComponent,
    HacVeUmreHizmetleri,
    KuranMealiComponent,
    IngilizceBrosurlerComponent,
    CamilerComponent,
    CamiDetayComponent,
    HomeIconsComponent,
    GeneralComponent,
    NoDataComponent,
    HacKayitComponent,
    UmreKayitComponent,
    KurbanComponent,
    ZekatFitreComponent,
    DiniYayinlarComponent,
    DiniBilgilerComponent,
    SermonsComponent,
    IslaminSartlariComponent,
    ImaninSartlariComponent,
    TabContentsComponent,
    NewsSliderComponent,
    DonationCampaignsComponent,
    NewsDetailComponent,
    HutbelerFetvalarComponent,
    ContentDetailComponent,
    HutbeDetailComponent,
    IhtidaHizmetleriComponent,
    NewsComponent,
    FetvaDetailComponent,
    RadioComponent,
    DetayComponent,
    YonetimKuruluDetayComponent,
    MosquePersonDetailComponent,
    PreviousAttacheDetailComponent,
    SubstanceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    PaginatorModule,
    CountUpModule,
    NgxScrollTopModule,
    LightboxModule,
    FormsModule,
    ReactiveFormsModule,
    LightgalleryModule,
    HttpClientModule,
    SkeletonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MatDialogModule,
    ToastrModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
