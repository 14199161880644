<header class="header-area p-relative">
    <div class="navbar-area navbar-style-three" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand nav-logo" routerLink="/"><img src="assets/img/melbourneLogo.svg" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="" class="nav-link">Anasayfa</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Kurumsal <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/birligimiz" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Birliğimiz</a></li>
                                <li class="nav-item"><a routerLink="/atesemiz" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Din Hizmetleri Ateşesi</a></li>
                                <li class="nav-item"><a routerLink="/yonetim-kurulu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Yönetim Kurulu</a></li>
                                <li class="nav-item"><a routerLink="/din-gorevlilerimiz" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Din Görevlilerimiz</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Hizmetler <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/din-hizmetleri" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Din Hizmetleri</a></li>
                                <li class="nav-item"><a routerLink="/dini-egitim-hizmetleri" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dini Eğitim Hizmetleri</a></li>
                                <li class="nav-item"><a routerLink="/hayri-hizmetler" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hayri Hizmetler</a></li>

                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Dini Bilgiler <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a target="_blank" href="https://yayin.diyanet.gov.tr/e-kitap/kuran-ogreniyorum-elif-ba-ingilizce-/dib/ingilizce/526" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Elif Ba (İngilizce)</a></li>
                                <li class="nav-item"><a target="_blank" href="https://hadis.diyanet.gov.tr/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hadislerle İslam (İngilizce)</a></li>
                                <li class="nav-item"><a target="_blank" href="https://kuran.diyanet.gov.tr/mushaf" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kur'an-ı Kerim</a></li>
                                <li class="nav-item"><a target="_blank" href="https://zekathesapla.tdv.org/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Zekat</a></li>
                                <li class="nav-item"><a routerLink="/brosurler" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">İngilizce Broşürler</a></li>
                                <li class="nav-item"><a routerLink="/kuran-meali" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kur'an-ı Kerim Meali</a></li>
                                <li class="nav-item"><a target="_blank" href="https://yayin.diyanet.gov.tr/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Diyanet Yayınları</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/camiler" class="nav-link">Camiler</a></li>
                        <li class="nav-item"><a routerLink="/iletisim" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">İletişim</a></li>
                    </ul>
                </div>
                <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/tr.jpg" class="shadow" alt="image">
                            <span>TR <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="dropdown-menu">
                            <a href="#" class="dropdown-item d-flex align-items-center">
                                <img src="assets/img/aus.jpg" class="shadow-sm" alt="flag">
                                <span>AUS</span>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div> 
</header>
<app-prayer-times></app-prayer-times>