<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">Haberler</h1>
        </div>

    </div>
</div>

<section class="news my-5 px-4">
    <div class="container">
        <div class="row" *ngIf="news.length">
            <div class="col-sm-6 col-md-3 mb-3" *ngFor="let item of news">
                <a [routerLink]="['/haber-detay/',item.id]">
                    <div class="card">
                        <div class="img-wrapper">
                            <img class="card-img-top" [src]="serverUrl+item.thumbnail" alt="Card image cap">
                        </div>
                        <div class="card-body">
                            <h5 class="news-title">
                                {{item.title}}
                            </h5>
                            <p class="news-content" [innerHTML]="item.description">
                                
                            </p>
                            <div class="published-date">
                            <p class="mb-2">
                                <i class="fa-regular fa-calendar"></i>
                                {{ item.creationDate | date: 'dd/MM/yyyy' }}
                            </p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        
        <ng-container *ngIf="!news.length">
            <div class="data-not-found p-3 my-5 rounded shadow">
               <div class="row">
                    <div class="col-12">
                        <img src="assets/img/data-not-found.jpg" alt="">
                    </div>
                    <div class="col-12">
                        <h1>
                            {{"NO_DATA_FOUND_FOR_PAGE"|translate}}
                        </h1>
                    </div>
               </div>
            </div>
        </ng-container>
        <div class="card flex justify-content-center" *ngIf="news">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="newsLength"></p-paginator>
        </div>
    </div>
</section>