import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { Content, ContentObject, Substance } from 'src/app/view-models/models/content';
import { Pillar } from 'src/app/view-models/models/Pillar';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-substance',
  templateUrl: './substance.component.html',
  styleUrls: ['./substance.component.scss']
})
export class SubstanceComponent implements OnInit{
  imageUrl:string=environment.serverUrl;
  isLoading$:Observable<boolean>;
  substances:Substance[]=[];

  pageTitle:string="";

  constructor(
    private activatedRoute:ActivatedRoute,
    private contentService:ContentService
  ) { 
    this.isLoading$=contentService.isLoading$;
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe(
      (data:{substance:ContentObject})=>{
        this.substances=data.substance.data.substances
        .filter(x=>x.isActive)
        .sort((a,b)=>{
          return a.order-b.order;
        });
        this.substances[0].selected=true;
        this.pageTitle=data.substance.data.title;
      }
    )
  }

setActiveTab(id: number): void {
  this.substances.forEach(s => {
    if (s.id === id) {
      s.selected = true;
    } else {
      s.selected = false;
    }
  });
}
}
