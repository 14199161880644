import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentService } from 'src/app/services/content.service';
import { services } from 'src/app/services/services';
import { Content } from 'src/app/view-models/models/content';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-homeeleven-main-banner',
    templateUrl: './homeeleven-main-banner.component.html',
    styleUrls: ['./homeeleven-main-banner.component.scss']
})
export class HomeelevenMainBannerComponent implements OnInit {

    sliders: Content[] = [];
    photoUrl: string = environment.serverUrl;
    language=localStorage.getItem("language");

    constructor(private contentService:ContentService) { }

    ngOnInit(): void {
        this.getSliders();
    }

    getSliders(){
        this.contentService.getContents(this.language,9,0,100).subscribe(data=>{
            this.sliders=data.data
            .filter(a => a.isActive)
            .sort((a, b) => {
                return a.order - b.order;
            });
        })
    }

    gymHomeSlides: OwlOptions = {
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoHeight: true,
        autoplay: true,
        dots: false,
        loop: true,
        nav: true,
        items: 1,
        navText: [
            "<i class='flaticon-arrows'></i>",
            "<i class='flaticon-right-arrow'></i>"
        ],
        autoplayTimeout: 4000
    }

    makeUrlFriendly(title: string): string {

        return title.toLowerCase() // Tüm harfleri küçük harf yap
            .replace(/ç/g, 'c')     // Türkçe karakterleri değiştir
            .replace(/ğ/g, 'g')
            .replace(/ı/g, 'i')
            .replace(/ö/g, 'o')
            .replace(/ş/g, 's')
            .replace(/ü/g, 'u')
            .replace(/[^a-z0-9]+/g, '-') // Boşluk ve özel karakterleri '-' ile değiştir
            .replace(/^-+|-+$/g, '');    // Başta ve sonda kalan '-' karakterlerini kaldır
    }

}