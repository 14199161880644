<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">{{"RELIGIOUS_INFORMATION"|translate}}</h1>
        </div>
    </div>
</div>

<div class="container my-5 px-3">
    <div class="row">
        <ng-container *ngIf="!(isLoading|async) && religiousInformations.length">
            <div class="col-lg-3 col-md-6 mb-3" *ngFor="let item of religiousInformations">
                <div class="card" *ngIf="item.link">
                    <a  [href]="item.link" target="_blank">
                        <img *ngIf="item.thumbnail" class="card-img-top" [src]="serverUrl+item.thumbnail" alt="religious-information">
                        <img *ngIf="!item.thumbnail" class="card-img-top" src="assets/img/no-image-icon.png" alt="religious-information">
                        <div class="card-body py-4">
                          <h5 class="text-center card-title">
                            {{item.title}}
                          </h5>
                        </div>
                    </a>
                </div>
                <div class="card" *ngIf="item.substances && item.slug">
                    <a [routerLink]="'/substance/'+item.slug+'/'+item.id">
                        <img *ngIf="item.thumbnail" class="card-img-top" [src]="serverUrl+item.thumbnail" alt="religious-information">
                        <img *ngIf="!item.thumbnail" class="card-img-top" src="assets/img/no-image-icon.png" alt="religious-information">
                        <div class="card-body py-4">
                          <h5 class="text-center card-title">
                            {{item.title}}
                          </h5>
                        </div>
                    </a>
                </div>
                <div class="card" *ngIf="!item.substances && item.slug">
                    <a [routerLink]="item.slug">
                        <img *ngIf="item.thumbnail" class="card-img-top" [src]="serverUrl+item.thumbnail" alt="religious-information">
                        <img *ngIf="!item.thumbnail" class="card-img-top" src="assets/img/no-image-icon.png" alt="religious-information">
                        <div class="card-body py-4">
                          <h5 class="text-center card-title">
                            {{item.title}}
                          </h5>
                        </div>
                    </a>
                </div>
                <div class="card" *ngIf="!item.link && !item.slug">
                    <a href="javascript:void(0)">
                        <img *ngIf="item.thumbnail" class="card-img-top" [src]="serverUrl+item.thumbnail" alt="religious-information">
                        <img *ngIf="!item.thumbnail" class="card-img-top" src="assets/img/no-image-icon.png" alt="religious-information">
                        <div class="card-body py-4">
                          <h5 class="text-center card-title">
                            {{item.title}}
                          </h5>
                        </div>
                    </a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoading|async">
            <div class="col-lg-3 col-md-6 mb-3" *ngFor="let item of skeletonArray">
                <div class="card">
                   <p-skeleton min-width="302px" height="244px"></p-skeleton>
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">
                        <p-skeleton minwidth="270px" height="24px"></p-skeleton>
                      </h5>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="!(isLoading|async) && !religiousInformations.length">
        <div class="data-not-found p-3 my-5 rounded shadow">
           <div class="row">
                <div class="col-12">
                    <img src="assets/img/data-not-found.jpg" alt="">
                </div>
                <div class="col-12">
                    <h1>
                        {{"NO_DATA_FOUND_FOR_PAGE"|translate}}
                    </h1>
                </div>
           </div>
        </div>
    </ng-container>
</div>

