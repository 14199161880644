<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">3ZZZ</h1>
        </div>
    </div>
</div>

<section class="my-5 px-4">
    <div class="container">
        <div class="row flex-column align-items-center">
            <div class="col-lg-6 mb-3" *ngIf="language=='tr'">
                <h1 class="color-main mb-4">
                    3ZZZ Radyo Frekans Bilgileri
                </h1>
                <p class="mb-4">
                    <b>
                        Her Cuma Saat 11:00-12:00 arasında 3ZZZ RADYO’da (Frequency 92.3 FM)  Melbourne Diyanet Cuma Saatini takip edebilirsiniz.
                    </b>
                </p>
                <a target="_blank" href="https://www.3zzz.com.au/" class="default-btn rounded"><i class='fa-solid fa-globe icon-arrow before'></i><span class="label">3ZZZ Web Sitesi</span><i class="fa-solid fa-globe icon-arrow after"></i></a>
            </div>
            <div class="col-lg-6 mb-3" *ngIf="language=='en'">
                <h1 class="color-main mb-4">
                    3ZZZ Radio Frequency Information
                </h1>
                <p class="mb-4">
                    <b>
                        You can follow the Melbourne Religious Friday Hour on 3ZZZ RADIO (Frequency 92.3 FM) every Friday between 11:00-12:00.
                    </b>
                </p>
                <a target="_blank" href="https://www.3zzz.com.au/" class="default-btn rounded"><i class='fa-solid fa-globe icon-arrow before'></i><span class="label">3ZZZ Website</span><i class="fa-solid fa-globe icon-arrow after"></i></a>
            </div>
            <div class="col-lg-6 mb-3">
                <img src="assets/img/3zzz.jpg" alt="3zzz-logo" class="img-fluid">
            </div>
        </div>
    </div>
</section>