<!-- <div class="gym-home-slides">
    <owl-carousel-o [options]="gymHomeSlides">
        <ng-template carouselSlide *ngFor="let slide of sliders ; let i = index">
            <img [src]="slide?.img" alt="">
            <div class="container">
                <div class="gym-banner-content">

                </div>
            </div>
        </ng-template>



    </owl-carousel-o>
</div> -->

<div class="gym-home-slides">
    <p-skeleton *ngIf="!sliders.length" width="100%" height="850px"></p-skeleton>
    <owl-carousel-o *ngIf="sliders" [options]="gymHomeSlides">
        <ng-template carouselSlide *ngFor="let slider of sliders; let i = index">
                <a *ngIf="slider.link && slider.link.includes('http')" [href]="slider.link" target="_blank">
                    <img [src]="photoUrl+slider?.contentImages[0]?.imagePath" alt="">
                </a>
                <a *ngIf="slider.link && !slider.link.includes('http')" [href]="slider.link">
                    <img [src]="photoUrl+slider?.contentImages[0]?.imagePath" alt="">
                </a>
                <a *ngIf="slider.frontPageId" [routerLink]="makeUrlFriendly(slider.title)+'/'+slider.frontPageId">
                    <img [src]="photoUrl+slider?.contentImages[0]?.imagePath" alt="">
                </a>
                <img *ngIf="!slider.link && !slider.frontPageId" [src]="photoUrl+slider?.contentImages[0]?.imagePath" alt="">
                <div class="container">
                    <div class="gym-banner-content">
    
                    </div>
                </div>
            </ng-template>

    </owl-carousel-o>
</div>

<app-home-icons></app-home-icons>
