import { Component } from '@angular/core';

@Component({
  selector: 'app-zekat-fitre',
  templateUrl: './zekat-fitre.component.html',
  styleUrls: ['./zekat-fitre.component.scss']
})
export class ZekatFitreComponent {
  language=localStorage.getItem("language");
}
