
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">Kur'an-ı Kerim Meali</h1>
        </div>
    </div>
</div>

<div class="container my-5">
    <div class="row">
        <div class="col-md-6 mb-3">
            <div class="card">
                <a target="_blank" href="https://kuran.diyanet.gov.tr/Tefsir/">
                    <img class="card-img-top" src="assets/img/kuran.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">Kur'an Meali (Türkçe)</h5>
                    </div>
                </a>
            </div>
        </div>
        <div class="col-md-6 mb-3">
            <div class="card">
                <a target="_blank" href="https://yayin.diyanet.gov.tr/e-kitap/hadislerle-islam-ingilizce-1/komisyon/ingilizce/3925">
                    <img class="card-img-top" src="assets/img/kuran.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">Kur'an Meali (İngilizce)</h5>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>