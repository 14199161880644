<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">İmanın Şartları</h1>
        </div>
    </div>
</div>
<section class="faith-section padding-tb shape-3 mb-100 py-3 my-5">
    <div class="container px-5 py-4">
        <div class="row">
            <div class="col-12">
                <div class="faith-content">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade" *ngFor="let pillar of pillars" [class.show]="pillar.selected" [class.active]="pillar.selected" [id]="pillar.ID" role="tabpanel" [attr.aria-labelledby]="pillar.tabID">
                            <div class="lab-item faith-item tri-shape-1 pattern-2">
                                <div class="lab-inner d-flex align-items-center">
                                    <div class="lab-thumb">
                                        <img [src]="'assets/img/iman-sartlari/thumb-images/' + pillar.thumb" alt="faith-image" class="thumb-image">
                                    </div>
                                    <div class="lab-content">
                                        <h4>{{ pillar.title }}</h4>
                                        <p>{{ pillar.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-pills mb-3 align-items-center justify-content-center" id="pills-tab" role="tablist">
                        <li class="nav-item" *ngFor="let pillar of pillars">
                            <a href="javascript:void(0)" class="nav-link" [id]="pillar.tabID" (click)="setActiveTab(pillar.ID)" data-bs-toggle="pill" role="tab" [attr.aria-controls]="pillar.ID" [class.active]="pillar.selected">
                                <img [src]="'assets/img/iman-sartlari/icon-images/' + pillar.icon" alt="faith-icon">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
