import { Component, OnInit, HostListener } from '@angular/core';
import { services } from '../../../services/services';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '../../../services/translation.service';
import { MetaDefinition } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-header-style-six',
    templateUrl: './header-style-six.component.html',
    styleUrls: ['./header-style-six.component.scss']
})
export class HeaderStyleSixComponent implements OnInit {

    prayerTime: any;
    isSticky: boolean = false;
    selectedCity: any = 32;
    selectedCountry: any;
    country: any;
    cities: any;
    langcode: any;
    submenus: any;
    orphanMenus: any;
    photoUrl: any = environment.serverUrl
    isLoading: boolean = true;

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    language: Language;
    languages: Language[] = [];
    displayedColumns: string[] = ['title', 'accountName', 'bsb', 'acc', 'amount', 'description'];
    dataSource: any;
    isVisible: boolean = false;

    constructor(private service: services,
        private route: ActivatedRoute,
        private translationService: TranslationService,
        public dialog: MatDialog) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.detectHeader()
        this.getCountry();
        this.getAllFrontMenus();
        this.getAllCity();
        this.service.getFrontCityById(this.selectedCity).subscribe((data: any) => {
            this.getPrayerTime(data.longitude, data.latitude);
        })
        this.setDefaultLanguage();
        this.getAllLanguages();
        this.getAccounts();
    }

    getAccounts() {
        this.service.getAccountInfo().subscribe((res: any) => {
            // console.log('bank accounts', res);
            this.dataSource = res;
        })
    }
    showDialog() {
        this.isVisible = !this.isVisible;
    }

    getAllLanguages() {
        this.service.getFrontAllLanguages().subscribe((data: any) => {
            this.languages = data;
            // console.log('languages', this.languages);
        })
    }

    setDefaultLanguage() {
        const lang = this.translationService.getSelectedLanguage();
        if (lang)
            this.setLanguageWithRefresh(lang);
    }

    setLanguageWithRefresh(lang: string) {

        this.languages.forEach((language: Language) => {
            if (language.langCode === lang) {
                this.language = language;
            }
        });
        this.translationService.setLanguage(lang);

    }

    setNewLanguageRefresh(lang: string) {
        
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    getPrayerTime(long, lat) {
        this.service.getPrayerTimes(long, lat).subscribe((resp: any) => {
            this.prayerTime = resp.daily.data[0];
            // console.log('namz',this.prayerTime);

        })
    }

    getCountry() {
        this.service.getFrontAllCountry().subscribe((resp: any) => {
            this.country = resp;
            if (this.country.length > 0) {
                this.selectedCountry = this.country[0].langcode;
                // this.getCityByLangCode(this.selectedCountry);
            }
        })
    }


    onCountrySelected(e: any) {
        this.selectedCountry = e.target.value

        // this.getCityByLangCode(this.selectedCountry);
    }

    getAllCity() {
        this.service.getFrontCountryByLangcode('au').subscribe((data: any) => {
            this.cities = data[0].cities.sort((a, b) => a.name.localeCompare(b.name));
            this.getPrayerTime(this.cities[8].longitude, this.cities[8].latitude)
        })
    }

    onCitySelected(x?: any) {
        this.selectedCity = x.target.value
        this.service.getFrontCityById(this.selectedCity).subscribe((data: any) => {
            this.getPrayerTime(data.longitude, data.latitude);
        })
    }

    getAllFrontMenus() {
        this.service.getAllFrontMenuRecord().subscribe((data: any) => {
            const submenus = data.filter(x => x.parentId !== null && x.isActive == true && x.isDeleted == false).sort((a, b) => a.order - b.order);
            this.submenus = submenus.sort((a, b) => a.order - b.order)
            const orphanMenus = data.filter(menu => {
                return menu.parentId == null && !data.some(submenu => submenu.id === menu.parentId && submenu.isActive == true && submenu.isDeleted == false);
            });
            this.orphanMenus = orphanMenus.sort((a, b) => a.order - b.order)

            if (this.orphanMenus) {
                this.isLoading = false;
            }
        })
    }

    hasSubmenus(menu: any): boolean {
        return this.submenus.some(submenu => submenu.parentId === menu.id);
    }

    getSubmenus(menu: any): any[] {
        return this.submenus.filter(submenu => submenu.parentId === menu.id);
    }


    windowScrolled: boolean | undefined;

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.windowScrolled = window.scrollY > 100;
    }
    // Navigation
    navMethod: boolean = false;
    toggleNav() {
        this.navMethod = !this.navMethod;
    }
    // Search
    searchMethod: boolean = false;
    toggleSearch() {
        this.searchMethod = !this.searchMethod;
    }
    // Mobile 
    open: boolean = false;
    trigger(item: { open: boolean; }) {
        item.open = !item.open;
    }
    // Add class on resize and onload window
    visible: boolean = false;
    breakpoint: number = 991;
    public innerWidth: any;
    detectHeader() {
        this.innerWidth = window.innerWidth;
        this.visible = this.innerWidth >= this.breakpoint;
    }
}

interface Language {
    id: number;
    name: string;
    langCode: string;
    flag: string;
}
