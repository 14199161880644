import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DepartmentService } from 'src/app/services/department.service';
import { DepartmentUser, DepartmentUserById } from 'src/app/view-models/models/department';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-mosque-person-detail',
  templateUrl: './mosque-person-detail.component.html',
  styleUrls: ['./mosque-person-detail.component.scss']
})
export class MosquePersonDetailComponent implements OnInit{

  user:DepartmentUser=null;
  isLoading$:Observable<boolean>;

  imageUrl:string=environment.serverUrl;

  language=localStorage.getItem("language");

  constructor(
    private activatedRoute:ActivatedRoute,
    private departmentService:DepartmentService
  ) {
     this.isLoading$=departmentService.isLoading$;
  }
  ngOnInit(): void {
    this.getUser();
  }

  getUser(){
    this.activatedRoute.data.subscribe(
      (data: { person: DepartmentUserById }) => {
        if(data.person){
          this.user=data.person.data;
        }
      }
    )
  }



}
