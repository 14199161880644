import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { SmallSliderResponse } from '../view-models/models/small-slider';

@Injectable({
  providedIn: 'root'
})
export class SmallSliderService {

  private apiUrl:string=environment.apiUrl;

  constructor
  (
    private http:HttpClient
  ){}

  getSmallSliders(lang:string):Observable<SmallSliderResponse>{

    const params=new HttpParams()
    .set("LanguageCode",lang)

    const url=`${this.apiUrl}/GetAllSmallSlider`;
    return this.http.get<SmallSliderResponse>(url,{params:params});
  }
}
