import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContentService } from 'src/app/services/content.service';
import { ContentObject } from 'src/app/view-models/models/content';
@Injectable({providedIn:'root'})
export class ContentResolver implements Resolve<ContentObject> {
    constructor(private contentService: ContentService,private router:Router) { }
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<ContentObject> {
        const id = route.paramMap.get('id');

        return this.contentService.getContent(id).pipe(
            catchError(()=>{
                this.router.navigate(['/no-data']); // Veri bulunamadı sayfasına yönlendir
                return of(null); // Boş bir observable döndürme
            })
        ) as Observable<ContentObject>;
    }
}