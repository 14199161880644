<section class="header mb-100 py-5 my-5">
    <div class="container content-wrapper py-4 px-5 rounded shadow">
        <div class="title mb-4">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <h1 class="main-title m-0">
                        {{"NEWS"|translate}}
                        <div class="underline"></div>
                    </h1>
                </div>
                <div class="col-md-6">
                    <a [routerLink]="['/news']" class="float-end"><span class="label">
                        {{"SEE_ALL"|translate}}
                    </span></a>
                </div>
            </div>
            
        </div>
        <div class="news-slider" >
            <owl-carousel-o [options]="owlOptions" *ngIf="news">
                <ng-template *ngFor="let news of news; let i=index" carouselSlide>
                    <!-- <img [src]="image.src" alt="" (click)="open(i)" class="rounded"> -->
                    <div class="news-wrapper">
                        <a href="/haber-detay/{{news.id}}">
                            <div class="news-card">
                                <div class="news-img mb-3 rounded">
                                    <img [src]="serverUrl+news.thumbnail" class="rounded" alt="">
                                </div>
                                <div class="news-body">
                                    <h5 class="news-title">
                                        {{news.title}}
                                    </h5>
                                    <p class="news-content" [innerHTML]="news.description">
                                        
                                    </p>
                                    <div class="published-date">
                                    <p class="mb-2">
                                        <i class="fa-regular fa-calendar"></i>
                                        {{ news.creationDate | date: 'dd/MM/yyyy' }}
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <div class="haber-detay w-100">
                            <a href="javascript:void(0)" class="" (click)="detailPage(news.id)"><span class="label">
                                {{"GO_DETAIL"|translate}}
                            </span></a>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
            <owl-carousel-o [options]="owlOptions" *ngIf="isLoading$|async">
                <ng-template carouselSlide *ngFor="let item of skeletonItems">
                  <div class="news-wrapper">
                    <a href="javascript:void(0)">
                      <div class="news-card">
                        <div class="news-img mb-3 rounded">
                          <ng-container *ngIf="isLoading$|async; else content">
                            <p-skeleton min-width="291px" height="280px"></p-skeleton>
                          </ng-container>
                          <ng-template #content>
                            <img [src]="item.image" alt="Haber">
                          </ng-template>
                        </div>
                        <div class="news-body">
                          <h5 class="news-title">
                            <ng-container *ngIf="isLoading$|async; else contentTitle">
                              <p-skeleton min-width="291px" height="24px"></p-skeleton>
                            </ng-container>
                            <ng-template #contentTitle>
                              {{ item.title }}
                            </ng-template>
                          </h5>
                          <p class="news-content">
                            <ng-container *ngIf="isLoading$|async; else contentText">
                              <p-skeleton min-width="291px" min-height="54px"></p-skeleton>
                            </ng-container>
                            <ng-template #contentText>
                              {{ item.content }}
                            </ng-template>
                          </p>
                          <div class="published-date">
                            <ng-container *ngIf="isLoading$|async; else contentDate">
                              <p-skeleton min-width="291px" height="27px"></p-skeleton>
                            </ng-container>
                            <ng-template #contentDate>
                              {{ item.date }}
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </a>
                    <div class="haber-detay w-100">
                      <ng-container *ngIf="isLoading$|async; else contentDetail">
                        <p-skeleton width="120px" height="33px"></p-skeleton>
                      </ng-container>
                      <ng-template #contentDetail>
                        {{ item.detail }}
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </owl-carousel-o>
            <div *ngIf="!news.length && !(isLoading$|async)">
                <h3>
                    <div class="alert alert-warning">
                        {{"NO_NEWS"|translate}}
                    </div>
                </h3>
            </div>
        </div>
       
        
    </div>
</section>

