
<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{"OUR_ATTACHÉ"|translate}}
            </h1>
        </div>
    </div>
</div>
<div class="container py-4 mb-3">
    <div class="card mb-5">
        <div class="card-body">
            <div class="atase">
                <div class="row align-items-center">
                    <div class="col-md-3">
                        <div class="img-wrapper">
                            <img *ngIf="attache?.profilePhoto" [src]="imageUrl+attache?.profilePhoto" class="img-fluid rounded" alt="">
                            <img *ngIf="!attache?.profilePhoto" src="assets/img/profil-foto.jpg" alt="image">
                        </div>
                    </div>
                    <div class="col-md-9">
                        <h3 class="color-main3">
                            {{attache?.firstName}} {{attache?.lastName}}
                        </h3>
                        <p *ngIf="language=='tr'">
                            {{attache?.biographyTR}}
                        </p>
                    </div>
                    <p *ngIf="language=='en'">
                        {{attache?.biographyEN}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <h2 class="text-center color-optional2">
        {{"PREVIOUS_ATTACHÉS"|translate}}
    </h2>
    <div class="underline mx-auto mt-3"></div>
    <div class="instructor-area pt-4 pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let attache of previousAttaches">
                    <a *ngIf="attache.biographyEN || attache.biographyTR" [routerLink]="['/previous-attache/detail/',attache.id]" class="pointer">
                        <div class="single-instructor-box mb-30">
                            <div class="image">
                                <img *ngIf="attache?.profilePhoto" [src]="imageUrl+attache?.profilePhoto" class="img-fluid rounded" alt="">
                                <img *ngIf="!attache?.profilePhoto" src="assets/img/profil-foto.jpg" alt="image">
                            </div>
                            <div class="content">
                                <h3>
                                    {{attache?.firstName}} {{attache?.lastName}}
                                </h3>
                            </div>
                        </div>
                    </a>
                    <a *ngIf="!attache.biographyEN && !attache.biographyTR" href="javascript:void(0)">
                        <div class="single-instructor-box mb-30">
                            <div class="image">
                                <img *ngIf="attache?.profilePhoto" [src]="imageUrl+attache?.profilePhoto" class="img-fluid rounded" alt="">
                                <img *ngIf="!attache?.profilePhoto" src="assets/img/profil-foto.jpg" alt="image">
                            </div>
                            <div class="content">
                                <h3>
                                    {{attache?.firstName}} {{attache?.lastName}}
                                </h3>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
