<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{language=='tr'?"İhtida Hizmetleri":"Conversion Services"}}
            </h1>
        </div>
    </div>
</div>

<section class="ihtida-hizmetleri my-5 px-4">
    <div class="container p-3 rounded shadow">
      <ng-container *ngIf="language=='tr'">
        <p>
            İslam, Allah’ın birliğini kabul etmektir. O’na teslim olmaktır. Cenab-ı Hak, Hz.Âdem’den itibaren bütün peygamberlere bu esası tebliğ etmelerini emretmiştir. Asıl itibariyle her peygambere vahyedilen din, tevhid esasına dayalı İslâm’dır. Her peygamber, insanları Allah’ın birliği inancına davet ederek Allah’tan aldıkları emir ve yasakları insanlara iletmiştir. Yüce Allah; insanlığa Tevrat, Zebur, İncil ve en son olarak da Kur’an-ı Kerim’i kitap olarak indirmiştir. Peygamberlik zincirini tamamlamak üzere Hz. Muhammed (s.a.s)’i son peygamber olarak görevlendirmiştir.
           </p>
           <p>
            Biz Müslümanlar bütün peygamberlerin Allah tarafından gönderildiğini kabul ederiz. Hepsinin hak olduğuna inanırız. Son peygamber Hz. Muhammed Mustafa (s.a.s)’dir. Peygamberimize Allah tarafından vahyedilen Kur’an-ı Kerim, son ilahi kitaptır. Onda bildirilenlerin bütününe şeksiz şüphesiz inanırız. Meleklerin, kaza ve kaderin, öldükten sonra dirilmenin ve hesabın yani ahiret gününün varlığını tasdik ederiz.
            İslâm’a girmek; Allah’a, Muhammed Mustafa (s.a.s)’in peygamberliğine ve onun Allah katından getirdiklerinin tamamına inanmakla olur. Bizler bu inancı “Kelime-i Şehadet” ile dile getiririz.
           </p>
            
            <p>
                Melbourne Diyanet Birliğimize bağlı camilerimizde Din Görevlilerimiz tarafından İslam Dinine girmek isteyen bay ve bayanlar için  “İhtida Merasimi” düzenlenmektedir. Bu tören aşamaları aşağıdaki gibidir:
            </p>
            <ul>
                <li>-Müslüman olmak isteyen kişi telefon veya yüz yüze Din Görevlisi ile görüşme yaparak isteğini beyan eder. Din görevlisi de kendisine nasıl bir ihtida programı yaptıklarını anlatır.</li>
                <li>-İhtida edecek olan kişi sertifika için yanında 1 adet vesikalık fotoğraf getirmelidir.</li>
                <li>-Müslüman olmak isteyen kişi veya Din Görevlisi ihtida töreni için en az iki (2) şahit hazır bulundurur</li>
                <li>-Törene başlamadan önce İhtida edecek olan kişinin bilgileri ilgili formlara kaydedilir.. Bunun için herhangi bir resmi belge gerekmemektedir.</li>
                <li>-Törende Müslüman olmak isteyen kişi Din Görevlisi ile beraber Kelime-i Şehadet getirerek Müslümanlığa ilk adımını atmış olur.</li>
                <li>-Tören sonunda Mühtedi tebrik edilerek ilgili formlar imzalanır ve sertifika Mühtediye verilir.</li>
            </ul>        
      </ng-container>
      <ng-container *ngIf="language=='en'">
        <p>
            Islam is to accept the oneness of Allah. It is surrender to Him. Allah Almighty has ordered all prophets from Adam to convey this principle. In fact, the religion revealed to every prophet is Islam based on the principle of tawhid. Every prophet invited people to believe in the unity of Allah and conveyed the orders and prohibitions they received from Allah to people. Almighty God; He sent down the Torah, Psalms, Bible and finally the Quran as a book to humanity. To complete the chain of prophecy, Hz. Muhammad (pbuh) the last prophet appointed as.
           </p>
           <p>
            We Muslims accept that all prophets were sent by Allah. We believe they are all right. The last prophet Hz. Muhammad Mustafa (pbuh). The Qur'an, which was revealed to our Prophet by Allah, is the last divine book. Without a doubt, we believe in all that is revealed in it. We confirm the existence of angels, resurrection after death, and reckoning, that is, the Hereafter.
            To enter Islam; it comes from believing in Allah, the prophethood of Muhammad Mustafa (pbuh) and all that he brought from Allah. We express this belief with “Kalima-i Shadah”.             
           </p>
            
            <p>
                In our mosques affiliated with the Melbourne Diyanet Union, "Conversion Ceremonies" are being organized by our religious officials (Imam) for men and women who wish to embrace Islam. The stages of this ceremony are as follows:
            </p>
            <ul>
                <li>-The person wishing to become a Muslim expresses their intention by speaking with the Imam either over the phone or in person. The imam briefly explains to him/her how the ceremony is performed.</li>
                <li>-The person who will convert to the faith must bring 1 passport-sized photo for the certificate.</li>
                <li>-A person wishing to convert to Islam or a religious official must have at least two (2) witnesses present for the conversion ceremony.</li>
                <li>-Before the ceremony begins, the information of the person who will be converting is recorded in the relevant forms. No official document is required for this.</li>
                <li>-A person who wishes to become a Muslim at the ceremony takes their first step into Islam by reciting the Shahada with the Imam.</li>
                <li>-At the end of the ceremony, the convert will be congratulated, the relevant forms will be signed, and the certificate will be given to the convert.</li>
            </ul>        
      </ng-container>
    </div>
</section>