import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ContentPage } from 'src/app/view-models/models/content-page';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit{

page:ContentPage=null;

private _albums: Array<any> = [];
serverUrl=environment.serverUrl;

constructor(
  private route:ActivatedRoute,private _lightbox: Lightbox
) {}
  ngOnInit(): void {
    this.getPageDetail();
  }

  getPageDetail(){
    this.route.data.subscribe(
      (data: { page: ContentPage }) => {
        if (data.page) {
         this.page=data.page;
         const editorContainer = document.getElementById("dynamic-value");
          const shadow = editorContainer.attachShadow({ mode: "closed" });
          const content = document.createElement("div");
          content.innerHTML = this.page.data.content;
          shadow.appendChild(content);
          this.setLightboxAlbum();
        }
      });
  }

  setLightboxAlbum(){
    for (let i = 0; i < this.page?.data.contentPageGalleries.length; i++) {
      const src = this.serverUrl+this.page?.data.contentPageGalleries[i].imagePath;
      const caption = `Görsel ${i+1}`;
      const thumb = this.serverUrl+this.page?.data.contentPageGalleries[i].imagePath;
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };
  
      this._albums.push(album);
    }
  }

  owlOptions: OwlOptions = {
    autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoHeight: true,
        autoplay: false,
        dots: false,
        loop: false,
        margin:100,
        items:1,
        nav: false,
        navText: [
            "<i class='fa-solid fa-left-long'></i>",
            "<i class='fa-solid fa-right-long'></i>"
        ],
        autoplayTimeout: 4000
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }
}
