import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DepartmentService } from './department.service';
import { DepartmentUserById } from '../view-models/models/department';
@Injectable({providedIn:'root'})
export class DepartmentUserResolver implements Resolve<DepartmentUserById> {
    constructor(private departmentService: DepartmentService,private router:Router) { }
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<DepartmentUserById> {
        const id = route.paramMap.get('id');

        return this.departmentService.getDepartmentUser(id).pipe(
            catchError(()=>{
                this.router.navigate(['/no-data']); // Veri bulunamadı sayfasına yönlendirme
                return of(null); // Boş bir observable döndürme
            })
        ) as Observable<DepartmentUserById>;
    }
}