import { Component } from '@angular/core';
import { Pillar } from 'src/app/view-models/models/Pillar';

@Component({
  selector: 'app-islamin-sartlari',
  templateUrl: './islamin-sartlari.html',
  styleUrls: ['./islamin-sartlari.scss']
})
export class IslaminSartlariComponent {
  pillars:Pillar[] = [
    { ID: 1, tabId: 'sehadet-tab', title: 'Şehadet', description: `Şehadet, İslam'ın Beş Şartından biri olan ve Ezan'ın bir parçası olan İslami bir inançtır. De ki: "Şahitlik ederim ki Allah'tan başka ilah yoktur ve yine şahitlik ederim ki Hz. Muhammed (sav) O'nun kulu ve elçisidir."`, icon: '1.png', thumb:"1.png", selected: true },
    { ID: 2, tabId: 'salah-tab', title: 'Namaz', description: 'Her Müslüman günde beş vakit namaz kılmalıdır: Sabah, Öğlen, ikindi, Akşam ve Yatsı. Namaz, temiz olan her yerde kılınabilir. Cemaat ile birlikte kılınan namazlar tek başına kılınan namazlardan daha hayırlıdır.', thumb:"2.png", icon: '2.png', selected: false },
    { ID: 3, tabId: 'sawm-tab', title: 'Oruç', description: `"Ramazan, 11 Aydan Daha Hayırlıdır." Ramazan ayında oruç tutmak İslam'ın beş şartından biridir. Şafak vaktinden akşam ezanına kadar yeme, içme ve orucu bozabilecek tüm faaliyetlerden uzak durulur. Akşam ezanıyla beraber iftar yapılır, oruç açılır.`, thumb:"3.png", icon: '3.png', selected: false },
    { ID: 4, tabId: 'zakat-tab', title: 'Zekat', description: 'Zekat, kişinin servetinin küçük bir yüzdesini, genellikle her yıl tasarruflarının %2,5 kadarını hayır kurumlarına vermesidir. Belli bir eşiğin üzerinde servet biriktirmiş olanlar için bu bir zorunluluktur.', thumb:"4.png", icon: '4.png', selected: false },
    { ID: 5, tabId: 'hajj-tab', title: 'Hac', description: `Hac, Müslümanlar için en kutsal şehir olan Suudi Arabistan'ın Mekke kentine yapılan yıllık ibadettir. Bu ibadet, fiziksel ve mali açıdan bu sorumluluğu üstlenebilecek tüm yetişkin müslümanlar için zorunlu bir ibadettir.`, thumb:"5.png", icon: '5.png', selected: false }
  ];

  constructor() { }

  setActiveTab(pillarID: number): void {
    this.pillars.forEach(pillar => {
      if (pillar.ID === pillarID) {
        pillar.selected = true;
      } else {
        pillar.selected = false;
      }
    });
  }

open:boolean=false;
pillarToggle(item:{open:boolean}){
  item.open=!item.open;
}
}
