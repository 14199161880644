<div class="page-title-area mb-5">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                <ng-container *ngIf="!(isLoading$|async) && department">
                    {{department?.title}}
                </ng-container>
              
            </h1>
           <div class="w-50 mx-auto" *ngIf="isLoading$|async">
            <p-skeleton max-width="500px" height="48px"></p-skeleton>
           </div>
        </div>
    </div>
</div>

<section class="mosque-detail">
    <div class="container">
        <div class="row">
            <ng-container *ngIf="!(isLoading$|async) && department">
                <div class="mb-5" [ngClass]="{'col-lg-6':department?.picture,'col-12':!department?.picture}">
                    <div class="header-content mb-4">
                        <h3 class="color-main3">
                            {{"ABOUT"|translate}}
                        </h3>
                        <p>
                            {{department?.description?department?.description:'-'}}
                        </p>
                    </div>
                    <div class="contact-informations">
                        <div class="contact-header d-flex align-items-center justify-content-between">
                            <h3 class="color-main3 mb-4">
                                {{"CONTENT_DETAILS"|translate}}
                            </h3>
                        </div>
                        <div class="contact-body">
                            <div class="mb-3">
                                <i class='fa-solid fa-mosque me-2 fa-lg'></i> &nbsp;<span>{{department?.title}}</span>
                            </div>
                            <div class="mb-3">
                                <a *ngIf="department?.address" [href]="getGoogleMapsUrl(department?.address)"
                                    target="_blank"><i class="fa-solid fa-map-location-dot fa-lg me-2"></i>
                                    &nbsp;<span>{{department?.address}}</span></a>
                                <span *ngIf="!department?.address">
    
                                    <i class="fa-solid fa-map-location-dot fa-lg me-2"></i>
                                    -
                                </span>
                            </div>
                            <div class="mb-3">
                                <a *ngIf="department?.phone" href="tel:{{department?.phone}}"><i
                                    class="fa-solid fa-phone fa-lg me-2"></i>&nbsp;<span>{{department?.phone}}</span>
                                </a>
                                <span *ngIf="!department?.phone">
                                    <i
                                    class="fa-solid fa-phone fa-lg me-2"></i>
                                    -
                                </span>
                            </div>
                            <div class="mb-3">
                                <a *ngIf="department?.email" href="mailto:{{department?.email}}"><i
                                    class="fa-solid fa-envelope fa-lg me-2"></i>&nbsp;<span>{{department?.email}}</span>
                                </a>
                                <span *ngIf="!department?.email">
                                    <i
                                    class="fa-solid fa-envelope fa-lg me-2"></i>
                                    -
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr>
    
                    <div class="additional-infos">
                        <ng-container *ngFor="let info of department?.additionalInfos">
                            <div *ngIf="info?.additionalInfoTypeName.toLowerCase().includes('i̇letişim')">
                                <h3 class="color-main3 mb-3">
                                    {{info?.additionalInfoTypeName}}
                                </h3>
                                <div class="d-flex flex-column gap-3">
                                    <ng-container *ngFor="let item of additionalByOrder(info?.ausDepAdditionalInfos)">
                                        <a class="d-flex align-items-center gap-2" *ngIf="item?.key.toLowerCase().includes('adres')" [href]="getGoogleMapsUrl(item?.value)" target="_blank">
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="">
                                            {{item?.value}}
                                        </a>
                                        <a class="d-flex align-items-center gap-2" *ngIf="item?.key.toLowerCase().includes('telefon')" href="tel:{{item?.value}}" target="_blank">
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="">
                                            {{item?.value}}
                                        </a>
                                        <a class="d-flex align-items-center gap-2" *ngIf="item?.key.toLowerCase().includes('mail')" href="mailto:{{item?.value}}">
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="">
                                            {{item?.value}}
                                        </a>
                                        <a class="d-flex align-items-center gap-2" *ngIf="item?.key.toLowerCase().includes('web')" [href]="item?.value">
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="">
                                            {{item?.key}}
                                        </a>
                                        <div class="d-flex flex-wrap gap-2" *ngIf="isSocialMedia(item?.key)">
                                            <a class="d-flex align-items-center gap-2" target="_blank" [href]="item?.value">
                                                <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="">
                                                {{item?.value}}
                                            </a>
                                        </div>
                                    </ng-container>
                                </div>
                                <hr>
                            </div>
                            <div *ngIf="info?.additionalInfoTypeName.toLowerCase().includes('sosyal medya')">
                                <h3 class="color-main3 mb-3">
                                    {{info?.additionalInfoTypeName}}
                                </h3>
                                <div class="d-flex align-items-center flex-wrap gap-3">
                                    <ng-container *ngFor="let item of additionalByOrder(info?.ausDepAdditionalInfos)">
                                        <a [href]="item.value" target="_blank">
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="social-media-icon">
                                        </a>
                                    </ng-container>
                                </div>
                                <hr>
                            </div>
                            <div *ngIf="info?.additionalInfoTypeName.toLowerCase()=='diğer hizmetler'">
                                <h3 class="color-main3 mb-3">
                                    {{info?.additionalInfoTypeName}}
                                </h3>
                                <div class="d-flex flex-column gap-3">
                                    <ng-container *ngFor="let item of additionalByOrder(info?.ausDepAdditionalInfos)">
                                        <a *ngIf="item?.key.toLowerCase().includes('i̇letişim') || item?.key.toLowerCase().includes('telefon')" class="d-flex align-items-center gap-2" href="tel:{{item?.value}}" target="_blank" >
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="other-service-icon">
                                            {{item.key}}: {{item.value}}
                                        </a>
                                        <a *ngIf="item?.key.toLowerCase().includes('adres')" class="d-flex align-items-center gap-2" [href]="getGoogleMapsUrl(item?.value)" target="_blank" >
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="other-service-icon">
                                            {{item.key}}: {{item.value}}
                                        </a>
                                        <a *ngIf="item?.key.toLowerCase().includes('mail')" class="d-flex align-items-center gap-2" href="mailto:" target="_blank" >
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="other-service-icon">
                                            {{item.key}}: {{item.value}}
                                        </a>
                                        <a *ngIf="item?.key.toLowerCase().includes('web')" class="d-flex align-items-center gap-2" [href]="item?.value" target="_blank" >
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="other-service-icon">
                                            {{item.key}}: {{item.value}}
                                        </a>
                                    </ng-container>
                                </div>
                                <hr>
                            </div>
                            <div *ngIf="!otherAdditional(info?.additionalInfoTypeName)">
                                <h3 class="color-main3 mb-3">
                                    {{info?.additionalInfoTypeName}}
                                </h3>
                                <div class="d-flex flex-column gap-3">
                                    <ng-container *ngFor="let item of additionalByOrder(info?.ausDepAdditionalInfos)">
                                        <a class="d-flex align-items-center gap-2" href="javascript:void(0)">
                                            <img *ngIf="item?.icon" [src]="imageUrl+item?.icon" alt="other-service-icon">
                                            {{item.key}}: {{item.value}}
                                        </a>
                                    </ng-container>
                                </div>
                                <hr>
                            </div>
                            
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-6 mb-5 mosque-image" *ngIf="department?.picture">
                    <img [src]="imageUrl+department?.picture" alt="" class="h-100 rounded mx-auto d-block">
                </div>
            </ng-container>
            <div class="tab-menu mt-5 my-3" *ngIf="department?.departmentUsers.length">
                <div class="tab-titles mb-4">
                    <div class="row flex-wrap text-center align-items-center justify-content-center">
                        <!-- Department başlıkları -->
                        <div class="col-md-3 mb-3" *ngFor="let item of department?.departmentUsers">
                            <h5 class="py-2" [class.selected]="selectedView == item.memberTypeName" (click)="setView(item.memberTypeName)">
                                {{item?.memberTypeName}}
                            </h5>
                        </div>
                    </div>
                </div>
                <!-- Kullanıcılar -->
                <div class="tab-content">
                    <div class="managers shadow rounded p-5" *ngIf="selectedView">
                        <div class="row justify-content-center align-items-center">
                            <!-- Filtrelenmiş kullanıcılar gösterilecek -->
                            <ng-container *ngFor="let userGroup of department?.departmentUsers">
                                <ng-container *ngIf="selectedView == userGroup.memberTypeName">
                                    <div class="col-md-3 mb-4" *ngFor="let user of usersByOrder(userGroup.users)">
                                        <a class="pointer" *ngIf="user.biographyTR || user.biographyEN" [routerLink]="['/mosque-person/detail/',user.id]">
                                            <div class="person-card text-center">
                                                <img *ngIf="!user.profilePhoto" src="assets/img/profil-foto.png" alt="foto" class="img-fluid mb-3">
                                                <img *ngIf="user.profilePhoto" [src]="imageUrl+user.profilePhoto" alt="user-photo" class="img-fluid mb-3">
                                                <h5>{{user.firstName}} {{user.lastName}}</h5>
                                            </div>
                                        </a>
                                        <a *ngIf="!user.biographyTR && !user.biographyEN" href="javascript:void(0)">
                                            <div class="person-card text-center">
                                                <img *ngIf="!user.profilePhoto" src="assets/img/profil-foto.png" alt="foto" class="img-fluid mb-3">
                                                <img *ngIf="user.profilePhoto" [src]="imageUrl+user.profilePhoto" alt="user-photo" class="img-fluid mb-3">
                                                <h5>{{user.firstName}} {{user.lastName}}</h5>
                                            </div>
                                        </a>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isLoading$|async">
                <div class="mb-5 col-lg-6">
                    <div class="header-content mb-4">
                        <h3 class="color-main3">
                            {{"ABOUT"|translate}}
                        </h3>
                        <p>
                            <p-skeleton width="100%" height="100px"></p-skeleton>
                        </p>
                    </div>
                    <div class="contact-informations">
                        <div class="contact-header d-flex align-items-center justify-content-between">
                            <h3 class="color-main3 mb-4">
                                {{"CONTENT_DETAILS"|translate}}
                            </h3>
                        </div>
                        <div class="contact-body">
                            <div class="mb-3 d-flex align-items-center">
                                <i class='fa-solid fa-mosque me-2 fa-lg'></i> &nbsp;<span>
                                    <p-skeleton width="250px" height="19px"></p-skeleton>
                                </span>
                            </div>
                            <div class="mb-3 d-flex align-items-center">
                               <i class="fa-solid fa-map-location-dot fa-lg me-2"></i>
                                    &nbsp;<span>
                                        <p-skeleton width="250px" height="19px"></p-skeleton>
                                    </span>
                            </div>
                            <div class="mb-3 d-flex align-items-center">
                                <i class="fa-solid fa-phone fa-lg me-3"></i>
                                <span>
                                    <p-skeleton width="250px" height="19px"></p-skeleton>
                                </span>
                            </div>
                            <div class="mb-3 d-flex align-items-center">
                                <i class="fa-solid fa-envelope fa-lg me-3"></i>
                                <span *ngIf="!department?.email">
                                    <p-skeleton width="250px" height="19px"></p-skeleton>
                                </span>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="col-lg-6 mb-5 mosque-image">
                    <p-skeleton max-width="405px" height="450px"></p-skeleton>
                    <!-- <img [src]="imageUrl+department?.picture" alt="" class="h-100  d-block"> -->
                </div>
            </ng-container>
        

        </div>
    </div>
</section>

<!-- <div class="container my-5 px-4">
    <div class="row">
        <div class="col-12 mb-3">
            <img [src]="mosque.image" alt="mosque" class="img-fluid w-100 rounded">
        </div>
        <div class="col-12 mb-3">
            <div class="description-area p-3 rounded">
                <p class="description">
                    {{mosque.description}}
                </p>
            </div>
        </div>
        <div class="col-12 mb-3">
            <div class="contact-wrapper p-3 rounded">
                <div class="title">
                    <h1>İletişim Bilgileri</h1>
                    <div class="underline mb-4"></div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <div class="mb-3">
                            <i class='fa-solid fa-mosque me-2 fa-lg'></i> <b>Cami Adı: </b>&nbsp;<h5>{{mosque.name}}</h5>
                        </div>
                        <div class="mb-3">
                            <a [href]="mosque.mapsUrl" target="_blank"><i class="fa-solid fa-map-location-dot fa-lg me-2"></i> <b>Adres: </b>&nbsp;<span>{{mosque.address}}</span></a>
                        </div>
                        <div class="mb-3">
                            <a href="tel:${{mosque.phone}}"><i class="fa-solid fa-phone fa-lg me-2"></i><b>Telefon: </b>&nbsp;<span>{{mosque?.phone?mosque.phone:"-"}}</span></a>
                        </div>
                       <div class="mb-3" *ngIf="mosque.educationalInstitutionAdressInformation">
                            <i class="fa-solid fa-school fa-lg me-2"></i> <b>Eğitim Kurumu Adres Bilgileri: </b> <span>{{mosque.educationalInstitutionAdressInformation}}</span>
                       </div>
                       <div class="mb-3" *ngIf="mosque.educationalInstitutionPhoneInformation">
                        <i class="fa-solid fa-phone fa-lg me-2"></i> <b>Eğitim Kurumu Telefon Bilgileri: </b> <span>{{mosque.educationalInstitutionPhoneInformation}}</span>
                       </div>
                       <div class="social-icons">
                        <a *ngIf="mosque.facebook" [href]="mosque.facebook" target="_blank"><i class="fa-brands fa-facebook"></i></a>
                        <a *ngIf="mosque.instagram" [href]="mosque.instagram" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                        <a *ngIf="mosque.youtube" [href]="mosque.youtube" target="_blank"><i class="fa-brands fa-youtube"></i></a>
                    </div>
                    </div>
                    <div class="col-md-6 mb-3">
                        <div class="mb-3" *ngIf="mosque.funeralServicesContactInformation">
                            <a href="tel:${{mosque.funeralServicesContactInformation}}"><img src="assets/img/tombstone.png" class="me-2" alt=""> <b>Cenaze Hizmetleri İletişim Bilgileri: </b>&nbsp; <span>{{mosque.funeralServicesContactInformation}}</span></a>
                        </div>
                        <div class="mb-3" *ngIf="mosque.elderlyHomeAddressInformation">
                            <i class="fa-solid fa-person-shelter fa-xl me-2"></i> <b>Yaşlılar Evi Adres Bilgileri: </b>&nbsp; <span>{{mosque.elderlyHomeAddressInformation}}</span>
                        </div>
                        <div class="mb-3" *ngIf="mosque.elderlyHomePhoneInformation">
                            <a href="tel:${{mosque.elderlyHomePhoneInformation}}"><i class="fa-solid fa-phone fa-xl me-2"></i> <b>Yaşlılar Evi Telefon Bilgileri: </b>&nbsp; <span>{{mosque.elderlyHomePhoneInformation}}</span></a>
                        </div>
                        <div class="mb-3" *ngIf="mosque.elderlyHomeWebInformation">
                            <a [href]="mosque.elderlyHomeWebInformation" target="_blank"><i class="fa-solid fa-globe fa-xl me-2"></i> <b>Yaşlılar Evi Web Sitesi </b></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="managers-wrapper p-3 rounded ">
            <div class="row">
                <div class="col-lg-6 mb-3" *ngIf="mosque.maleClergyWhoContributed || mosque.clergyFromPastToPresent">
                    <ng-container *ngIf="mosque.maleClergyWhoContributed">
                        <ul class="list third p-3 rounded ">
                            <h4 class="mb-4">Emeği Geçen Din Görevlileri</h4>
                            <div class="underline mb-4"></div>
                            <li class="list-item" *ngFor="let clergy of mosque.maleClergyWhoContributed">
                                {{clergy}}
                            </li>
                        </ul>
                    </ng-container>
                   
                    <ng-container *ngIf="mosque.clergyFromPastToPresent">
                        <ul class="list fourth p-3 rounded ">
                            <h4 class="mb-4">Geçmişten Günümüze Din Görevlileri</h4>
                            <div class="underline mb-4"></div>
                            <li class="list-item" *ngFor="let clergy of mosque.clergyFromPastToPresent">
                                {{clergy}}
                            </li>
                        </ul>
                    </ng-container>
                </div>
                <div class="col-lg-6 mb-3" *ngIf="mosque.femaleClergyWhoContributed">
                    <ul class="list fifth p-3 rounded ">
                        <h4 class="mb-4">Emeği Geçen Kadın Din Görevlileri</h4>
                        <div class="underline mb-4"></div>
                        <li class="list-item" *ngFor="let clergy of mosque.femaleClergyWhoContributed">
                            {{clergy}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
       
    </div>
  
</div> -->