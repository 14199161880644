
<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{"RELIGIOUS_SERVICES"|translate}}
            </h1>
        </div>
    </div>
</div>
<div class="container my-5">
    <div class="row">
       <ng-container *ngIf="!(isLoading$|async) && contents">
        <div class="col-md-4 mb-3" *ngFor="let item of contents">
            <div class="card">
                <a *ngIf="item?.link" [href]="item?.link" target="_blank" class="pointer">
                    <img *ngIf="item?.thumbnail" class="card-img-top" [src]="imageUrl+item?.thumbnail" alt="image">
                    <img *ngIf="!item?.thumbnail" class="card-img-top" src="assets/img/no-image-icon.png" alt="">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">{{item?.title}}</h5>
                    </div>
                </a>
                <a *ngIf="!item?.link" href="javascript:void(0)">
                    <img *ngIf="item?.thumbnail" class="card-img-top" [src]="imageUrl+item?.thumbnail" alt="irsat-hizmetleri">
                    <img *ngIf="!item?.thumbnail" class="card-img-top" src="assets/img/no-image-icon.png" alt="">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">{{item?.title}}</h5>
                    </div>
                </a>
            </div>
        </div>
       </ng-container>
       <ng-container *ngIf="isLoading$|async">
        <div class="col-md-4 mb-3" *ngFor="let skeleton of skeletonArray">
            <div class="card">
                <p-skeleton max-width="414px" height="280px"></p-skeleton>
                <div class="card-body py-4">
                    <p-skeleton max-width="414px" height="20px"></p-skeleton>
                </div>
            </div>
        </div>
       </ng-container>
    </div>
    <ng-container *ngIf="!(isLoading$|async) && !contents.length">
        <div class="data-not-found p-3 my-5 rounded shadow">
           <div class="row">
                <div class="col-12">
                    <img src="assets/img/data-not-found.jpg" alt="">
                </div>
                <div class="col-12">
                    <h1>
                        {{"NO_DATA_FOUND_FOR_PAGE"|translate}}
                    </h1>
                </div>
           </div>
        </div>
    </ng-container>
</div>