import { Component } from '@angular/core';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent {
  language:any;
  constructor() {
    this.language=localStorage.getItem("language");
    
  }
}
