<div class="about-area py-5 mb-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/cami.jpg"  class="rounded shadow" alt="image">
                    <!-- <img src="assets/img/advLogo.png" class="shadow" alt="image"> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span class="sub-title">Hakkımızda</span> -->
                    <h2 style="color: var(--mainColor3);">{{"ASSOCIATION"|translate}}</h2>
                    <h6><i class="fa-solid fa-location-dot me-2" style="color: var(--mainColor2);"></i>Melbourne</h6>
                    <div class="features-text">
                        <h5>
                            <i class="fa-solid fa-mosque me-3"></i>   
                            <span>{{"ASSOCIATION_TEXT_ONE"|translate}}</span></h5>
                        <p>{{"ASSOCIATION_TEXT_TWO"|translate}}</p>
                    </div>
                    <a routerLink="/birligimiz/16" class="default-btn rounded mb-3"><i class='fa-solid fa-book-quran icon-arrow before'></i><span class="label">{{"OUR_ASSOCIATION"|translate}}</span><i class="fa-solid fa-book-quran icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>