<ng-container *ngIf="sermon">
    <div class="page-title-area item-bg2 mb-3">
        <div class="container">
            <div class="page-title-content">
                <h1 class="text-center color-optional3">{{"KHUTBA_DETAIL"|translate}}</h1>
            </div>
        </div>
    </div>
    <section class="hutbe-detail my-5 px-5 py-5">
        <div class="container">
           <div class="hutbe-wrapper mx-auto shadow p-3 rounded">
                <div class="hutbe-title">
                    <hr>
                        <span>{{sermon.title}}</span>
                    <hr>
                </div>
                <hr>
                <p [innerHTML]="sermon.description">
                    
                </p>
                <hr>
                <div class="date d-flex">
                    <span class="ms-auto">{{sermon.creationDate|date:'dd/MM/yyyy'}}</span>
                </div>
           </div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="!sermon">
    <section class="no-content text-center my-5 py-5">
       <div class="container">
        <div class="message shadow d-inline-block p-5 rounded">
            <h1 class="color-main3">HATA!</h1>
            <p>
                Böyle bir hutbe bulunamadı.
            </p>
            <a [routerLink]="['/']" class="default-btn btn-sm float-end w-100"><i class='fa-solid fa-house icon-arrow before'></i><span class="label">Anasayfa</span><i class="fa-solid fa-house icon-arrow after"></i></a>
        </div>
       </div>
    </section>
</ng-container>