<ng-container>
    <div class="donate-modal" [class.show]="dialogVisible">
        <div class="modal-inner rounded shadow p-3">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h3 class="m-0">{{"DONATION_DETAILS"|translate}}</h3>
            <div class="close-modal rounded" (click)="showDialog()">
                X
            </div>
        </div>
        <!-- <img class="donate-icon" src="assets/img/donate-icon.png" alt=""> -->
        <div class="modal-text">
           <table class="table" border="0">
            <thead>
                <tr>
                    <th>
                        <span class="cursor-p" (click)="copyToClipboard(1)">
                            <i class="fa-regular fa-copy me-1"></i>
                            {{"ACCOUNT_NAME"|translate}}
                        </span>         
                    </th>
                    <th>
                        <span class="cursor-p" (click)="copyToClipboard(2)">
                            <i class="fa-regular fa-copy me-1"></i>
                            BSB
                        </span>   
                    </th>
                    <th>
                        <span class="cursor-p" (click)="copyToClipboard(3)">
                            <i class="fa-regular fa-copy me-1"></i>
                            ACC
                        </span>   
                    </th>
                    <th>
                        {{"QUANTITY"|translate}}
                    </th>
                    <th>
                        {{"DESCRIPTION"|translate}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                   <td>
                    Australian Diyanet Association
                   </td>
                   <td>
                    063-243
                   </td>
                   <td>
                    1035 5051
                   </td>
                   <td>
                    {{"FREE_QUANTITY"|translate}}
                   </td>
                   <td>
                    {{"DONATION_NOTE"|translate}}
                   </td>
                </tr>
            </tbody>
           </table>
        </div>
    </div>
</div>
    <header [ngClass]="{'sticky':isSticky}">
        <nav class="container">
            <div class="logo">
                <a [routerLink]="['/']" class="isActive">
                    <img src="assets/img/melbourneLogo.svg" alt="">
                </a>
            </div>
            <div class="links-wrapper" [ngClass]="{'menu-opened':navbarOpened}">
                <ul class="nav-links flex-wrap">
                  <ng-container *ngIf="links.length">
                    <li class="link" *ngFor="let parent of links">
                        <a [class.isActive]="parent.slug||parent.link" (click)="navbarToggle()" *ngIf="parent.slug.length && !parent.frontPageId" [routerLink]="parent.slug">
                            {{parent.name}}
                        </a>
                        <a [class.isActive]="parent.slug||parent.link" (click)="navbarToggle()" *ngIf="parent.slug.length && parent.frontPageId" [routerLink]="parent.slug+'/'+parent.frontPageId">
                            {{parent.name}}
                        </a>
                        <a [class.isActive]="parent.slug||parent.link" *ngIf="parent.link.length" [href]="parent.link">
                            {{parent.name}}
                        </a>
                        <a [class.isActive]="parent.slug||parent.link" *ngIf="!parent.link.length && !parent.slug.length" href="javascript:void(0)">
                            {{parent.name}}
                        </a>
                        <span (click)="trigger(parent)">
                            <i *ngIf="getChildsByParentID(parent.id).length>0" class="fa-solid fa-chevron-down ms-1"></i>
                        </span>
                        <ul class="submenu first" *ngIf="getChildsByParentID(parent.id).length>0" [ngClass]="parent.open?'d-block':''">
                            <li class="sub-link" *ngFor="let child of getChildsByParentID(parent.id)">
                                <a [class.isActive]="child.slug||child.link||child.frontPageId" *ngIf="child.link.length" [href]="child.link" target="_blank">{{child.name}}</a>
                                <a [class.isActive]="child.slug||child.link||child.frontPageId" *ngIf="child.slug.length && !child.frontPageId" [routerLink]="child.slug" (click)="navbarToggle()">{{child.name}}</a>
                                <a [class.isActive]="child.slug||child.link||child.frontPageId" *ngIf="child.slug.length && child.frontPageId" [routerLink]="child.slug+'/'+child.frontPageId" (click)="navbarToggle()">{{child.name}}</a>
                                <a [class.isActive]="child.slug||child.link||child.frontPageId" *ngIf="!child.link.length && !child.slug.length" href="javascript:void(0)">{{child.name}}</a>
                                <span (click)="childTrigger(child)">
                                    <i *ngIf="getSideChilds(child.id).length>0" class="fa-solid ms-1" [ngClass]="child.open?'fa-chevron-down':' fa-chevron-right'"></i>
                                </span>
                                <ul class="submenu second" *ngIf="getSideChilds(child.id).length > 0" [ngClass]="child.open ? 'd-block' : ''">
                                    <li class="sub-link second" *ngFor="let sideChild of getSideChilds(child.id)">
                                       <a [class.isActive]="sideChild.slug||sideChild.link||sideChild.frontPageId" *ngIf="sideChild.link.length" [href]="sideChild.link" target="_blank"> {{sideChild.name}}</a>
                                       <a [class.isActive]="sideChild.slug||sideChild.link||sideChild.frontPageId" *ngIf="sideChild.slug.length && !sideChild.frontPageId" [routerLink]="sideChild.slug" (click)="navbarToggle()"> {{sideChild.name}}</a>
                                       <a [class.isActive]="sideChild.slug||sideChild.link||sideChild.frontPageId" *ngIf="sideChild.slug.length && sideChild.frontPageId" [routerLink]="sideChild.slug+'/'+sideChild.frontPageId" (click)="navbarToggle()"> {{sideChild.name}}</a>
                                       <a [class.isActive]="sideChild.slug||sideChild.link||sideChild.frontPageId" *ngIf="!sideChild.slug.length && !sideChild.link.length" href="javascript:void(0)"> {{sideChild.name}}</a>
                                        <span (click)="sideChildTrigger(sideChild)">
                                            <i *ngIf="getSideLinks(sideChild.id).length > 0" class="fa-solid ms-1" [ngClass]="sideChild.open?'fa-chevron-down':' fa-chevron-right'"></i>
                                        </span>
                                        <ul class="submenu third" *ngIf="getSideLinks(sideChild.id).length > 0" [ngClass]="sideChild.open ? 'd-block' : ''">
                                            <li class="sub-link" *ngFor="let sideLink of getSideLinks(sideChild.id)">
                                                <a [class.isActive]="sideLink.slug||sideLink.link||sideLink.frontPageId" *ngIf="sideLink.slug.length && !sideLink.frontPageId" [routerLink]="sideLink.slug" (click)="navbarToggle()">{{sideLink.name}}</a>
                                                <a [class.isActive]="sideLink.slug||sideLink.link||sideLink.frontPageId" *ngIf="sideLink.slug.length && sideLink.frontPageId" [routerLink]="sideLink.slug+'/'+sideLink.frontPageId" (click)="navbarToggle()">{{sideLink.name}}</a>
                                                <a [class.isActive]="sideLink.slug||sideLink.link||sideLink.frontPageId" target="_blank" *ngIf="sideLink.link.length" [href]="sideLink.link">{{sideLink.name}}</a>
                                                <a [class.isActive]="sideLink.slug||sideLink.link||sideLink.frontPageId" target="_blank" *ngIf="!sideLink.link.length && !sideLink.slug.length" href="javascript:void(0)">{{sideLink.name}}</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <div class="donate">
                        <button class="donate-button btn btn-sm" (click)="showDialog()">
                            <i class="fa-solid fa-hand-holding-medical align-middle me-2"></i>
                            {{"DONATE"|translate}}
                        </button>
                    </div>
                  </ng-container>
                    
                    <ul class="social-link m-0 pt-1 ps-3">
                        <li><a  target="_blank" [href]="facebook?.value" class="d-block isActive"><i class='bx bxl-facebook'></i></a></li>
                        <li><a target="_blank" [href]="instagram?.value" class="d-block isActive"><i class='bx bxl-instagram'></i></a></li>
                    </ul>

                    <ul class="flags m-0 ps-3">
                        <li>
                            <img (click)="changeLanguage(2)" src="assets/img/aus.jpg" alt="aus">
                        </li>
                        <li>
                            <img (click)="changeLanguage(1)" src="assets/img/tr.jpg" alt="tr">
                        </li>
                    </ul>
                    <!-- <div class="language-switcher pt-1" [class.active]="classApplied2">
                        <button class="language-toggler" (click)="toggleClass2()">
                            <img src="assets/img/{{currentLanguage.Flag}}" alt="" class="flag-image">
                            <span>{{currentLanguage.Name}}  <i class='bx bx-chevron-down'></i></span>
                        </button>
                        <div class="language-dropdown">
                            <a *ngFor="let lang of returnLanguages(currentLanguage.ID)" href="javascript:void(0)" class="dropdown-item" (click)="changeLanguage(lang)">
                                <img src="assets/img/{{lang.Flag}}" alt="" class="flag-image">
                                <span>{{lang.Name}}</span>
                            </a>
                        </div>
                    </div> -->
                </ul>  
               
            </div>
           
            <button id="navbar-toggle" (click)="navbarToggle()" [ngClass]="{'menu-opened':navbarOpened}">
                <span></span><span></span><span></span>
            </button>
        </nav>
    </header>
</ng-container>
<app-prayer-times></app-prayer-times>

