import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DepartmentService } from 'src/app/services/department.service';
import { DinGorevlileriService } from 'src/app/services/din-gorevlileri.service';
import { DepartmentUser, DepartmentUserList } from 'src/app/view-models/models/department';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-din-gorevlileri',
  templateUrl: './din-gorevlileri.component.html',
  styleUrls: ['./din-gorevlileri.component.scss']
})
export class DinGorevlileriComponent implements OnInit{

  departmentUsers:DepartmentUser[]=[];
  language=localStorage.getItem("language")

  imageUrl:string=environment.serverUrl;

  skeletonArray = Array(4);

  isLoading$:Observable<boolean>;

  constructor(
    private departmentService:DepartmentService
  ) {
    this.isLoading$=this.departmentService.isLoading$;
  }

  ngOnInit(): void {
    this.getDepartmentUsers();
  }

  getDepartmentUsers(){
    this.departmentService.getAllDepartmentUsers("00000000-0000-0000-0000-000000000000",this.language,4,0).subscribe((data:DepartmentUserList)=>{ //4 din görevlilerini ifade ediyor
      this.departmentUsers=data.data.filter(x=>x.isActive).sort((a,b)=>{
        return a.order-b.order;
      })
    })
  }

}
