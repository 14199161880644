import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { NavbarMenuResponse } from '../view-models/models/parent-menu';

@Injectable({
  providedIn: 'root'
})
export class NavbarMenuService {
  apiUrl:string=environment.apiUrl;
  constructor(
    private http:HttpClient
  ) { }

  //Navbar menü linkleri
  getMenuItems(langCode:string):Observable<NavbarMenuResponse>{
    const url=`${this.apiUrl}/GetAllMenuItems?LanguageCode=${langCode}`;
    return this.http.get<NavbarMenuResponse>(url);
  }
}
