import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { BirligimizComponent } from './components/pages/birligimiz/birligimiz.component';
import { AtasemizComponent } from './components/pages/atasemiz/atasemiz.component';
import { YonetimKuruluComponent } from './components/pages/yonetim-kurulu/yonetim-kurulu.component';
import { DinGorevlileriComponent } from './components/pages/din-gorevlileri/din-gorevlileri.component';
import { DinHizmetleriComponent } from './components/pages/din-hizmetleri/din-hizmetleri.component';
import { EgitimHizmetleriComponent } from './components/pages/egitim-hizmetleri/egitim-hizmetleri.component';
import { HacVeUmreHizmetleri } from './components/pages/hac-ve-umre-hizmetleri/hac-ve-umre-hizmetleri';
import { KuranMealiComponent } from './components/pages/kuran-meali/kuran-meali.component';
import { IngilizceBrosurlerComponent } from './components/pages/ingilizce-brosurler/ingilizce-brosurler.component';
import { CamilerComponent } from './components/pages/camiler/camiler.component';
import { ZekatFitreComponent } from './components/pages/zekat-fitre/zekat-fitre.component';
import { CamiDetayComponent } from './components/pages/camiler/cami-detay/cami-detay.component';
import { DiniYayinlarComponent } from './components/pages/dini-yayinlar/dini-yayinlar.component';
import { DiniBilgilerComponent } from './components/pages/dini-bilgiler/dini-bilgiler.component';
import { IslaminSartlariComponent } from './components/pages/dini-bilgiler/islamin-sartlari/islamin-sartlari';
import { ImaninSartlariComponent } from './components/pages/dini-bilgiler/imanin-sartlari/imanin-sartlari';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { HutbeDetailComponent } from './components/pages/hutbe-detail/hutbe-detail.component';
import { FetvaDetailComponent } from './components/pages/fetva-detail/fetva-detail.component';
import { ContentResolver } from './services/content.resolver';
import { DetayComponent } from './components/pages/din-gorevlileri/detay/detay.component';
import { RadioComponent } from './components/pages/radio/radio.component';
import { IhtidaHizmetleriComponent } from './components/pages/ihtida-hizmetleri/ihtida-hizmetleri.component';
import { NewsComponent } from './components/pages/news/news.component';
import { SermonsComponent } from './components/pages/sermons/sermons.component';
import { DonationCampaignsComponent } from './components/pages/donation-campaigns/donation-campaigns.component';
import { GeneralComponent } from './components/pages/general/general.component';
import { ContentPageResolver } from './components/pages/general/content-page.resolver';
import { NoDataComponent } from './components/pages/no-data/no-data.component';
import { DepartmentUserResolver } from './services/department-user.resolver';
import { YonetimKuruluDetayComponent } from './components/pages/yonetim-kurulu/yonetim-kurulu-detay/yonetim-kurulu-detay.component';
import { MosquePersonDetailComponent } from './components/pages/camiler/mosque-person-detail/mosque-person-detail.component';
import { PreviousAttacheDetailComponent } from './components/pages/atasemiz/previous-attache-detail/previous-attache-detail.component';
import { ContentDetailComponent } from './components/pages/content-detail/content-detail.component';
import { SubstanceComponent } from './components/pages/substance/substance.component';

const routes: Routes = [
    {path: '', component: HomePageOneComponent},
    {path: 'our-attaché', component: AtasemizComponent},
    {path: 'board-of-directors', component: YonetimKuruluComponent},
    {path: 'our-religious-officers', component: DinGorevlileriComponent},
    {path: 'religious-services', component: DinHizmetleriComponent},
    {path: 'education-services', component: EgitimHizmetleriComponent},
    {path: 'hac-ve-umre-hizmetleri', component: HacVeUmreHizmetleri},
    {path: 'kuran-meali', component: KuranMealiComponent},
    {path: 'brosurler', component: IngilizceBrosurlerComponent},
    {path: 'our-mosques', component: CamilerComponent},
    {path: 'our-mosques/:id', component: CamiDetayComponent},
    {path: 'fitrah-zakah', component: ZekatFitreComponent},
    {path: 'conversion-services', component: IhtidaHizmetleriComponent},
    {path: 'news', component: NewsComponent},
    {path: 'donation-campaigns', component: DonationCampaignsComponent},
    {path: 'religious-publications', component: DiniYayinlarComponent},
    {path: '3zzz', component: RadioComponent},
    {path: 'religious-information', component: DiniBilgilerComponent},
    {path: 'hutbeler', component: SermonsComponent},
    {path: 'hajj-and-umrah-services', component: HacVeUmreHizmetleri},
    {path: 'islamin-sartlari', component: IslaminSartlariComponent},
    {path: 'imanin-sartlari', component: ImaninSartlariComponent},
    {path: 'haber-detay/:id', component: NewsDetailComponent, resolve: { news: ContentResolver }},
    {path: 'content-detail/:id', component: ContentDetailComponent, resolve: { content: ContentResolver }},
    {path: 'hutbe-detay/:id', component: HutbeDetailComponent,resolve: { sermon: ContentResolver }},
    {path: 'fetva-detay/:id', component: FetvaDetailComponent,resolve: { fatwa: ContentResolver }},
    {path: 'board-of-directors/detail/:id', component: YonetimKuruluDetayComponent,resolve:{user:DepartmentUserResolver}},
    {path: 'our-religious-officers/detail/:id', component: DetayComponent,resolve:{officer:DepartmentUserResolver}},
    {path: 'mosque-person/detail/:id', component: MosquePersonDetailComponent,resolve:{person:DepartmentUserResolver}},
    {path: 'previous-attache/detail/:id', component: PreviousAttacheDetailComponent,resolve:{attache:DepartmentUserResolver}},
    {path: 'no-data', component: NoDataComponent},
    {path: 'error-404', component: ErrorPageComponent},
     {path: ':slug/:id', component: GeneralComponent, resolve:{page:ContentPageResolver}},
    {path: 'contact', component: ContactPageComponent},
    {path:'substance/:slug/:id',component:SubstanceComponent,resolve: { substance: ContentResolver }},
    {path: '**', component: ErrorPageComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }