<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">{{"FATWA_DETAIL"|translate}}</h1>
        </div>
    </div>
</div>

<ng-container *ngIf="fatwa">
    <section class="fetva-detail py-5 mt-5 px-3">
        <div class="container shadow rounded p-3">
            <b>{{"QUESTION"|translate}}:</b>&nbsp;
            <span class="question">
                {{fatwa.title}}
            </span>
            <hr>
            <b>{{"ANSWER"|translate}}:</b>&nbsp;
            <p [innerHTML]="fatwa.description">
            </p>
            <hr>
            <div class="d-flex">
                <span class="ms-auto color-main3">
                    {{fatwa.creationDate|date:'dd/MM/yyyy'}}
                </span>
            </div>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="fatwas.length">
    <section class="diger-fetvalar">
        <div class="container mb-5">
            <h1 class="text-center color-main">
                {{"OTHER_FATWAS"|translate}}
            </h1>
            <hr>
            <div class="row">
                <div class="col-md-3 mb-3" *ngFor="let fatwa of fatwas">
                    <div class="fetva-box shadow p-3 text-center">
                        <b class="fetva-name mb-3">
                            {{fatwa.title}}
                        </b>
                        <span class="d-block mb-3 color-main">
                            {{fatwa.creationDate|date:'dd/MM/yyyy'}}
                        </span>
                        <a class="detail" href="/fetva-detay/{{fatwa.id}}">
                            {{"REVIEW"|translate}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-content-center" *ngIf="fatwas">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="fatwasCount"></p-paginator>
        </div>
    </section>
</ng-container>

<ng-container *ngIf="!fatwa">
    <section class="no-content text-center my-5 py-5">
       <div class="container">
        <div class="message shadow d-inline-block p-5 rounded">
            <h1 class="color-main3">HATA!</h1>
            <p>
                Böyle bir fetva bulunamadı.
            </p>
            <a [routerLink]="['/']" class="default-btn btn-sm float-end w-100"><i class='fa-solid fa-house icon-arrow before'></i><span class="label">Anasayfa</span><i class="fa-solid fa-house icon-arrow after"></i></a>
        </div>
       </div>
    </section>
</ng-container>