import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'src/app/services/content.service';
import { Content } from 'src/app/view-models/models/content';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit{
  news:Content[]=[];
  serverUrl=environment.serverUrl;
  newsLength:number=0;

  first: number = 0;

  rows: number = 12;

  onPageChange(event) {
      this.first = event.page;
      this.rows = event.rows;
      this.getNews();
  }

  language=localStorage.getItem("language")

  constructor(
    private contentService:ContentService,
    private router:Router
  ){}

  ngOnInit(): void {
    this.getNews();
  }

  getNews(){
    this.contentService.getContents(this.language,10,this.first,this.rows).subscribe(data=>{
      this.news=data.data
      .filter(a => a.isActive)
      .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
      this.newsLength = data.totalCount;
      console.log(data.totalCount)
    })
  }
}
