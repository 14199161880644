<div class="times-wrapper">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 p-0 times-col d-flex align-items-center mb-sm-3 mt-3">
                <h5 class="me-3 ms-2 mt-2 color-optional3">
                    {{"PRAYER_TIMES"|translate}}
                </h5>
                <div class="custom-select-wrapper">
                    <select class="custom-select rounded" (change)="onCitySelected($event)" [(ngModel)]="selectedCity">
                        <option [value]="item.id" *ngFor="let item of sortCitiesByName()">
                            {{item.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-lg-6 mt-2">
                <div class="row" *ngIf="prayerTime">
                    <div class="col-4 p-0 mb-2">
                        <div class="item rounded border py-1 px-2 mx-2">
                            <span>{{"FAJR" | translate}}:</span>&nbsp;<span class="ms-lg-1 fw-bold">{{prayerTime.daily.data[0].fajr}}</span>
                        </div>
                    </div>
                    <div class="col-4 p-0 mb-2">
                        <div class="item rounded border py-1 px-2 mx-2">
                            <span>{{"SUNRISE" | translate}}:</span>&nbsp;<span class="ms-lg-1 fw-bold">{{prayerTime.daily.data[0].sunrise}}</span>
                        </div>
                    </div>
                    <div class="col-4 p-0 mb-2">
                        <div class="item rounded border py-1 px-2 mx-2">
                            <span>{{"DUHUR" | translate}}:</span>&nbsp;<span class="ms-lg-1 fw-bold">{{prayerTime.daily.data[0].dhuhr}}</span>
                        </div>
                    </div>
                    <div class="col-4 p-0 mb-2">
                        <div class="item rounded border py-1 px-2 mx-2">
                            <span>{{"ASR" | translate}}:</span>&nbsp;<span class="ms-lg-1 fw-bold">{{prayerTime.daily.data[0].asr}}</span>
                        </div>
                    </div>
                    <div class="col-4 p-0 mb-2">
                        <div class="item rounded border py-1 px-2 mx-2">
                            <span>{{"MAGHRIB" | translate}}:</span>&nbsp;<span class="ms-lg-1 fw-bold">{{prayerTime.daily.data[0].maghrib}}</span>
                        </div>
                    </div>
                    <div class="col-4 p-0 mb-2">
                        <div class="item rounded border py-1 px-2 mx-2">
                            <span>{{"ISHA" | translate}}:</span>&nbsp;<span class="ms-lg-1 fw-bold">{{prayerTime.daily.data[0].isha}}</span>
                        </div>
                    </div>
                </div>
                <div class="loading" *ngIf="!prayerTime">
                    <span>
                        {{"LOADING_PRAYER_TIMES"|translate}}
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="72" viewBox="0 0 200 200"><circle fill="#faebbb" stroke="#faebbb" stroke-width="15" r="15" cx="40" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#faebbb" stroke="#faebbb" stroke-width="15" r="15" cx="100" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#faebbb" stroke="#faebbb" stroke-width="15" r="15" cx="160" cy="65"><animate attributeName="cy" calcMode="spline" dur="2" values="65;135;65;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="d-flex align-items-center flex-column rounded border py-1 time-col">
                    <span>{{cityName}} {{"CITY_TIME"|translate}}:&nbsp;</span>
                    <span class="fw-bold">{{melbourneTime}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
