import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { Content } from 'src/app/view-models/models/content';

@Component({
  selector: 'app-hutbeler-fetvalar',
  templateUrl: './hutbeler-fetvalar.component.html',
  styleUrls: ['./hutbeler-fetvalar.component.scss']
})
export class HutbelerFetvalarComponent implements OnInit{

  isLoading$:Observable<boolean>;

  skeletonArray = Array(4);

  sermons:Content[]=[];
  fatwas:Content[]=[];

  language=localStorage.getItem("language")

  constructor
  (
    private contentService:ContentService,
  ) {
    this.isLoading$=contentService.isLoading$;
  }
  ngOnInit(): void {
    this.getSermons();
    this.getFatwas();
  }
  

  getSermons(){
    this.contentService.getContents(this.language,8,0,12).subscribe(data=>{
      this.sermons=data.data .filter(a => a.isActive)
      .sort((a, b) => a.order-b.order);
    })
  }
  getFatwas(){
    this.contentService.getContents(this.language,32,0,100).subscribe(data=>{
      this.fatwas=data.data .filter(a => a.isActive)
      .sort((a, b) => a.order-b.order);
    })
  }
}
