
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">{{"OUR_MOSQUES"|translate}}</h1>
        </div>
        
    </div>
</div>

<div class="container my-5 px-4">
    <div class="row">
        <ng-container *ngIf="!(isLoading$|async) && departments">
            <div class="col-lg-4 col-md-6 mb-3" *ngFor="let mosque of departments">
                <div class="card">
                        <img *ngIf="mosque?.picture" class="card-img-top" [src]="imageUrl+mosque.picture" alt="">
                        <img *ngIf="!mosque?.picture" class="card-img-top" src="assets/img/no-image-icon.png" alt="">
                        <div class="card-body d-flex flex-column ">
                          <h4 class="card-title">{{mosque.title}}</h4>
                          <div class="adress mb-3">
                              <b style="font-size: 18px;">{{"ADDRESS"|translate}}: </b>
                              <span style="color: var(--paragraphColor);">
                                {{mosque.address?mosque.address:"-"}}
                            </span>
                          </div>
                          
                            <div class="mb-3">
                                <i class="fa-solid fa-phone fa-lg me-1 color-main"></i>
                                <a *ngIf="mosque.phone" href="tel:{{mosque.phone}}">{{mosque.phone}}</a>
                                <span *ngIf="!mosque.phone">-</span>
                            </div>
                            <div class="mb-3">
                                <i class="fa-solid fa-map-location-dot fa-xl me-2 color-main"></i> <a *ngIf="mosque.address" target="_blank" [href]="getGoogleMapsUrl(mosque.address)">
                                    {{"DIRECTIONS"|translate}}
                                </a>
                                <span *ngIf="!mosque.address">-</span>
                            </div>
                            <a href="javascript:void(0)" class="default-btn rounded mt-auto" (click)="detailPage(mosque.id)"><i class='fa-solid fa-mosque icon-arrow before'></i><span class="label"><span class="px-2">{{"DETAIL"|translate}}</span></span><i class="fa-solid fa-mosque icon-arrow after"></i></a>
                        </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoading$|async">
            <div class="col-lg-4 col-md-6 mb-3" *ngFor="let skeleton of skeletonArray">
                <div class="card">
                        <p-skeleton min-width="406px" height="451px"></p-skeleton>
                        <div class="card-body d-flex flex-column ">
                          <h4 class="card-title">
                            <p-skeleton min-width="374px" height="29px"></p-skeleton>
                          </h4>
                          <div class="mb-5">
                            <p-skeleton min-width="374px" height="45px"></p-skeleton>
                          </div>
                          
                            <div class="mb-3">
                                <p-skeleton width="97px" height="19px"></p-skeleton>
                            </div>
                            <div class="mb-3">
                                <p-skeleton width="62px" height="19px"></p-skeleton>
                            </div>
                        </div>
                </div>
            </div>
        </ng-container>
        <!-- <div class="col-lg-4 col-md-6 mb-3" *ngFor="let mosque of mosques">
            <div class="card">
                    <img class="card-img-top" [src]="mosque.image" alt="">
                    <div class="card-body d-flex flex-column ">
                      <h4 class="card-title">{{mosque.name}}</h4>
                      <div class="adress mb-3">
                          <b style="font-size: 18px;">{{"ADDRESS"|translate}}: </b><span style="color: var(--paragraphColor);">{{mosque.address}}</span>
                      </div>
                      
                        <div class="mb-3">
                            <i class="fa-solid fa-phone fa-lg me-1 color-main"></i><a href="tel:${{mosque.phone}}">{{mosque.phone}}</a>
                        </div>
                        <div class="mb-3">
                            <i class="fa-solid fa-map-location-dot fa-xl me-2 color-main"></i> <a target="_blank" [href]="mosque.mapsUrl">
                                {{"DIRECTIONS"|translate}}
                            </a>
                        </div>
                        <a href="javascript:void(0)" class="default-btn rounded mt-auto" (click)="detailPage(mosque.id)"><i class='fa-solid fa-mosque icon-arrow before'></i><span class="label"><span class="px-2">{{"DETAIL"|translate}}</span></span><i class="fa-solid fa-mosque icon-arrow after"></i></a>
                    </div>
            </div>
        </div> -->
    </div>
    <ng-container *ngIf="!departments.length && !(isLoading$|async)">
        <div class="data-not-found p-3 rounded shadow">
           <div class="row">
                <div class="col-12">
                    <img src="assets/img/data-not-found.jpg" alt="">
                </div>
                <div class="col-12">
                    <h1>
                        {{"NO_DATA_FOUND_FOR_PAGE"|translate}}
                    </h1>
                </div>
           </div>
        </div>
    </ng-container>
</div>