<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">{{"DETAIL"|translate}}</h1>
        </div>
    </div>
</div>
<section class="content-detail my-5 px-5 py-5">
    <div class="container">
        <ng-container *ngIf="isLoading$|async">
            <div class="content-wrapper mx-auto shadow p-3 rounded">
                <p-skeleton max-width="336px" height="28px"></p-skeleton>
                <hr>
                <p-skeleton max-width="100%" height="81px"></p-skeleton>
                &nbsp;
                <p-skeleton max-width="100%" height="33px"></p-skeleton>
                &nbsp;
                <p-skeleton max-width="100%" height="55px"></p-skeleton>
                &nbsp;
                <p-skeleton max-width="100%" height="17px"></p-skeleton>
                &nbsp;
                <hr>
                <div class="date d-flex">
                    <div class="ms-auto">
                        <p-skeleton width="75px" height="20px"></p-skeleton>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="content && !(isLoading$|async)">
            <div class="content-wrapper mx-auto shadow p-3 rounded">

                <div class="content-title">
                    <hr>
                    <span>{{content.title}}</span>
                    <hr>
                </div>
                <hr>
                <div class="row mb-3" *ngIf="content.thumbnail">
                    <div class="col-12">
                        <img [src]="serverUrl+content.thumbnail" alt="image">
                    </div>
                </div>
                <p [innerHTML]="content.description">

                </p>
                <div class="row" *ngIf="content?.contentImages.length || content?.videoPath">
                    <hr>
                    <div class="col-12">
                        <ng-container *ngIf="content?.contentImages.length">
                            <h3>{{"IMAGES"|translate}}</h3>
                            <div class="row mb-3">
                                <owl-carousel-o>
                                    <ng-template *ngFor="let image of content?.contentImages;let i=index;" carouselSlide>
                                        <div class="col-11">
                                            <img (click)="open(i)" class="rounded d-block" [src]="serverUrl+image.imagePath"
                                                alt="">
                                        </div>
                                    </ng-template>
                                </owl-carousel-o>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="content?.videoPath">
                            <hr>
                            <h3>Video</h3>
                            <video [src]="serverUrl+content.videoPath" controls class="rounded"></video>
                        </ng-container>
                        <ng-container *ngIf="content.folderPath">
                            <hr>
                            <a [href]="serverUrl+content.folderPath" target="_blank" class="default-btn rounded"><i class='fa-solid fa-file icon-arrow before'></i><span class="label">{{"SEE_FILE"|translate}}</span><i class="fa-solid fa-file icon-arrow after"></i></a>
                        </ng-container>
                    </div>
                </div>
                <hr>
                <div class="date d-flex">
                    <span class="ms-auto">{{content.creationDate|date:'dd/MM/yyyy'}}</span>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!(isLoading$|async) && !content">
            <div class="message shadow d-inline-block p-5 rounded">
                <h1 class="color-main3">
                    {{"ERROR"|translate}}
                </h1>
                <p>
                    {{"NO_DATA"|translate}}
                </p>
                <a [routerLink]="['/']" class="default-btn btn-sm float-end w-100"><i
                        class='fa-solid fa-house icon-arrow before'></i><span
                        class="label">{{"HOMEPAGE"|translate}}</span><i
                        class="fa-solid fa-house icon-arrow after"></i></a>
            </div>
        </ng-container>
    </div>
</section>
