
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">English Brochures</h1>
        </div>
    </div>
</div>

<div class="container my-5">
    <div class="row">
        <div class="col-lg-3 col-md-6 mb-3">
            <a target="_blank" href="assets/pdf/hz-muhammed.pdf">
            <div class="card">
                    <img class="card-img-top" src="assets/img/brosurler/hz-muhammed.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">The Prophet Muhammad</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-3 col-md-6 mb-3">
            <a target="_blank" href="assets/pdf/islam-nedir.pdf">
            <div class="card">
                    <img class="card-img-top" src="assets/img/brosurler/islam-nedir.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">What is Islam?</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-3 col-md-6 mb-3">
            <a target="_blank" href="assets/pdf/kuran-son-vahiy.pdf">
            <div class="card">
                    <img class="card-img-top" src="assets/img/brosurler/son-vahiy.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">The Qur'an: The Final Revelation</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-3 col-md-6 mb-3">
            <a target="_blank" href="https://diniyayinlar.diyanet.gov.tr/Documents/Hz-Isa-Peygamber-Ingilizce.pdf">
            <div class="card">
                    <img class="card-img-top" src="assets/img/brosurler/hz-isa.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">Jesus: A Prophet of Islam</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-3 col-md-6 mb-3">
            <a target="_blank" href="https://diniyayinlar.diyanet.gov.tr/Documents/Musluman-Kimdir-Ingilizce.pdf">
            <div class="card">
                    <img class="card-img-top" src="assets/img/brosurler/who-is-a-muslim.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">Who is a Muslim?</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-3 col-md-6 mb-3">
            <a target="_blank" href="https://diniyayinlar.diyanet.gov.tr/Documents/Son-Peygamber-Hz-Muhammedin-Insanliga-Seslenisi-Ingilizce.pdf">
            <div class="card">
                    <img class="card-img-top" src="assets/img/brosurler/the-last-prophet.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">The Last Prophet Muhammad’s (s.a.w)</h5>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-lg-3 col-md-6 mb-3">
            <a target="_blank" href="https://diniyayinlar.diyanet.gov.tr/Documents/Islam-ve-Cevre-Ingilizce.pdf">
            <div class="card">
                    <img class="card-img-top" src="assets/img/brosurler/islam-and-the-environment.jpg" alt="Card image cap">
                    <div class="card-body py-4">
                      <h5 class="text-center card-title">Islam and the Environment</h5>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>