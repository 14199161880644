import { Component } from '@angular/core';

@Component({
  selector: 'app-ihtida-hizmetleri',
  templateUrl: './ihtida-hizmetleri.component.html',
  styleUrls: ['./ihtida-hizmetleri.component.scss']
})
export class IhtidaHizmetleriComponent {
language=localStorage.getItem("language");
}
