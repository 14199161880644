import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { ContentPageService } from './content-page.service';
import { ContentPage } from 'src/app/view-models/models/content-page';
@Injectable({providedIn:'root'})
export class ContentPageResolver implements Resolve<ContentPage> {
    language = localStorage.getItem("language")
    constructor(private contentPageService: ContentPageService) { }
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<ContentPage> {
        const id = route.paramMap.get('id');

        return this.contentPageService.getContentPageById(id,this.language) as Observable<ContentPage>;
    }
}