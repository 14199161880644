import { AppSetting } from './../../../view-models/models/app-setting';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { ContactService } from 'src/app/services/contact.service';
import { TranslationService } from 'src/app/services/translation.service';
import { Contact } from 'src/app/view-models/models/contact';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    isLoading$:Observable<boolean>;
    contactForm:FormGroup;

    location:AppSetting=null;
    email:AppSetting=null;
    phone:AppSetting=null;

    error:string="";
    success:string="";


    constructor(
        private fb:FormBuilder,
        private contactService:ContactService,
        private appSettingService:AppSettingService,
        private translateService:TranslationService
    ) {
        this.isLoading$=contactService.isLoading$;
     }

    ngOnInit(): void {
        this.createForm();
        this.getAppSettings();

    }

    getAppSettings(){
        this.appSettingService.getAppSettings().subscribe((data:AppSetting[])=>{
            data.forEach(appSetting=>{
                switch (appSetting.key){
                    case "adres":
                        this.location=appSetting;
                        break;
                    case "telefon":
                        this.phone=appSetting;
                        break;
                    case "email":
                        this.email=appSetting
                    break;
                }
            })
        })
    }

    getGoogleMapsUrl(address: string): string {
        return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
      }

    createForm(){
        this.contactForm = this.fb.group({
            fullName: ['',[Validators.required,Validators.minLength(3),Validators.maxLength(50)]],
            email:['',[Validators.required]],
            subject:['',[Validators.required]],
            message:['',[Validators.required]],
            phone:['',[Validators.required]]
          });
    }

    submit(){
        const contact=this.createBuildObject();
        this.contactService.addContact(contact).subscribe({
            next:()=>{
                this.error="";
                this.success=this.translateService.getValue("FORM_SEND_SUCCESS");
                this.createForm();
            },
            error:()=>{
                this.success="";
                this.error=this.translateService.getValue("AN_ERROR");
            }
        }).add(()=>{
            setTimeout(() => {
                this.error="";
                this.success="";
            }, 1500);
        })
    }

    createBuildObject(): Contact {
        const contact: Contact = {
          fullName: this.contactForm.get('fullName').value,
          email: this.contactForm.get('email').value,
          subject: this.contactForm.get('subject').value,
          message: this.contactForm.get('message').value,
          phone: this.contactForm.get('phone').value,
        }
        return contact;
      }

}