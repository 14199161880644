
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">Kurban</h1>
        </div>
    </div>
</div>
<div class="container my-5 px-3">
   <div class="row">
    
    <div class="col-12 mb-3">
        <img src="assets/img/homeIcon-pages/kurban.webp" alt="">
    </div>
    <div class="col-12">
        <h2 class="color-main3">Hesap Bilgileri</h2>
        <table [cellSpacing]="1" [cellPadding]="4" class="mb-4">
            <tr>
                <td><b>Hesap Adı&nbsp;:</b></td>
                <td>Australian Diyanet Association</td>
            </tr>
            <tr>
                <td><b>BSB&nbsp;:</b></td>
                <td>063-243</td>
            </tr>
            <tr>
                <td><b>ACC&nbsp;:</b></td>
                <td>1035 5051</td>
            </tr>
            <tr>
                <td><b>Miktar&nbsp;:</b></td>
                <td>200 AUD</td>
            </tr>
        </table>
        <p>
            Vacip, Adak, Aşikar, Şükür ve Nafile kurbanlarınızı birliğimize bağışlayabilirsiniz.
        </p>
        <h1 class="color-main3 mb-3">Diyanet Güvencesiyle Kurban İbadeti</h1>
        <p>
            “Şimdi sen Rabbine kulluk et ve kurban kes.” <b>(Kevser, 2)</b> 
        </p>
        <p>
            Allah’a yakınlaşmaya vesile olan şey anlamına gelen kurban, birçok güzelliği ve manayı içerisinde barındırması bakımından da önemlidir. Kurban olur insan, kestiği/kestirdiği kurbanlar ile. Ulaşır takvası ve niyeti ameliyle beraber. Yaklaşır Allah’a, yakınlaşır kardeşlerine onları görmese bile.
        </p>
       <p>
        Bu şuur ve ihlasla,
       </p>
       <p>
        Keseceği kurbanı ihtiyaç sahiplerine ulaştırmak isteyen vatandaşlarımıza yardımcı olmak, bu ibadetin dini hükümler doğrultusunda ve amacına uygun şekilde ifasını sağlamak için <strong>“Diyanet İşleri Başkanlığı ile Türkiye Diyanet Vakfı işbirliğinde”</strong>  vekâlet yoluyla kurban kesim organizasyonu düzenlenmektedir.
       </p>
       <h2 class="color-main3 mb-3">Kurbanlarınız Diyanet Vakfı Güvencesinde</h2>
       <p>
        Vekâletini aldığımız Adak, Akika, Şükür ve Nafile kurbanlarınızı, yurt dışında İslami usullere göre <strong>“Türkiye Diyanet Vakfı Güvencesiyle”</strong>  kesiyor ve ihtiyaç sahiplerine ulaştırıyoruz.
       </p>
       <p>
        İbadet şuuru ile büyükbaş ve küçükbaş olarak kestiğimiz <b>kurbanlarınızı</b> dünyanın farklı coğrafyalarında, yardımlarınızı bekleyen ihtiyaç sahiplerine ulaştırıyoruz. Kategorimizin sadece <b>“yurt dışı kurban”</b> şeklinde olması, kesilecek kurban türü niyetinin size ait olması; yani bizce bilinmesinin gerekli olmaması, bizim <b>sizlerin niyetine</b>  kurbanınızı kesme faaliyeti yürütmemiz nedeniyledir.
       </p>
       <p>
        Gelin, kurban ibadetimizi ifa ederken mahzun ve mağdur gönüllere bayram coşkusu yaşatalım.
       </p>
       <p>
        Allah’a yakınlaşmaya vesile olan şey anlamına gelen kurban, birçok güzelliği ve manayı içerisinde barındırması bakımından da önemlidir. Kurban olur insan, kestiği/kestirdiği kurbanlar ile. Ulaşır takvası ve niyeti ameliyle beraber. Yaklaşır Allah’a, yakınlaşır kardeşlerine onları görmese bile.
       </p>
    </div>
   </div>
</div>