<div class="container text-center">
    <div class="row justify-content-center">
        <div class="mb-2" *ngFor="let slider of smallSliders">
            <div class="box">
                <a *ngIf="!slider.frontPageId && !slider.url"  href="javascript:void(0)">
                    <div class="inner py-2 rounded">
                        <img class="mb-3" [src]="serverUrl+slider.image" alt="">
                        <h5>{{slider.title}}</h5>
                    </div>
                </a>
                <a *ngIf="slider.url && slider.url.includes('http')" [href]="slider.url" target="_blank">
                    <div class="inner py-2 rounded">
                        <img class="mb-3" [src]="serverUrl+slider.image" alt="">
                        <h5>{{slider.title}}</h5>
                    </div>
                </a>
                <a *ngIf="slider.url && !slider.url.includes('http')" [href]="slider.url">
                    <div class="inner py-2 rounded">
                        <img class="mb-3" [src]="serverUrl+slider.image" alt="">
                        <h5>{{slider.title}}</h5>
                    </div>
                </a>
                <a *ngIf="slider.frontPageId && !slider.url"  [routerLink]="makeUrlFriendly(slider.title)+'/'+slider.frontPageId">
                    <div class="inner py-2 rounded">
                        <img class="mb-3" [src]="serverUrl+slider.image" alt="">
                        <h5>{{slider.title}}</h5>
                    </div>
                </a>
            </div>
        </div>
        
    </div>
</div>
