import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { Content, ContentResponse } from 'src/app/view-models/models/content';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-dini-yayinlar',
  templateUrl: './dini-yayinlar.component.html',
  styleUrls: ['./dini-yayinlar.component.scss']
})
export class DiniYayinlarComponent implements OnInit{
  contents:Content[]=[];
  isLoading$:Observable<boolean>;
  
  language=localStorage.getItem("language");
  imageUrl:string=environment.serverUrl;

  skeletonArray=Array(6);
  
  constructor(
    private contentService:ContentService
  ) {
    this.isLoading$=contentService.isLoading$;
  }

  ngOnInit(): void {
   this.getContents();
  }

  getContents(){
    this.contentService.getContents(this.language,41,0,1000).subscribe((data:ContentResponse)=>{ //41 dini yayınlar
      this.contents=data.data.filter(x=>x.isActive).sort((a,b)=>{
        return a.order-b.order;
      })
    }) 
  }
}
