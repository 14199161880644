import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ContentObject, ContentResponse } from '../view-models/models/content';
import { Contact } from '../view-models/models/contact';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  isLoadingSubject:BehaviorSubject<boolean>;
  isLoading$:Observable<boolean>;
  private apiUrl:string=environment.apiUrl;

  constructor
  (
    private http:HttpClient
  ){
    this.isLoadingSubject=new BehaviorSubject<boolean>(false);
      this.isLoading$=this.isLoadingSubject.asObservable();
  }

  addContact(model:Contact):Observable<Contact>{
    this.isLoadingSubject.next(true);
    const url=`${this.apiUrl}/AddSiteContact`;
    return this.http.post<Contact>(url,model).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }
}
