import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { Content } from 'src/app/view-models/models/content';

@Component({
  selector: 'app-news-slider',
  templateUrl: './news-slider.component.html',
  styleUrls: ['./news-slider.component.scss']
})
export class NewsSliderComponent implements OnInit{
  news:Content[]=[];
  skeletonItems=Array(4)
  serverUrl=environment.serverUrl;
  language=localStorage.getItem("language")
  // isLoading$:Observable<boolean>;
  isLoading$:Observable<boolean>;
  constructor(
    private contentService:ContentService,
    private router:Router
  ){
    this.isLoading$=contentService.newsSliderIsLoading$;
  }

  ngOnInit(): void {
    this.getNews();
  }

  getNews(){
    this.contentService.getContents(this.language,10,0,10).subscribe(data=>{
      this.news=data.data
      .filter(a => a.isActive)
      .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    })
  }

  owlOptions: OwlOptions = {
    autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoHeight: false,
        autoplay: false,
        dots: false,
        loop: false,
        margin:20,
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 2
          },
          992: {
            items: 4
          }
        },
        nav: true,
        navText: [
            "<i class='fa-solid fa-left-long'></i>",
            "<i class='fa-solid fa-right-long'></i>"
        ],
        autoplayTimeout: 4000
  }



 detailPage(id:number){
  this.router.navigate([`/haber-detay/${id}`])
 }
  
}