<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{"CONTACT"|translate}}
            </h1>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>   {{"EMAIL"|translate}}</h3>
                    <p><a [href]="'mailto:'+email?.value">
                        {{email?.value}}
                    </a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{"LOCATION"|translate}}</h3>
                    <p><a [href]="getGoogleMapsUrl(location?.value)" target="_blank">
                        {{location?.value}}
                    </a></p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{"PHONE"|translate}}</h3>
                    <p><a [href]="'tel:'+phone?.value">
                        {{phone?.value}}
                    </a></p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="color-optional" style="font-size: 1.2rem;">{{"CONTACT"|translate}}</span>
            <h2 class="color-main3 m-0">{{"CONTACT_TEXT"|translate}}</h2>
        </div>
        <div class="contact-form">
            <form [formGroup]="contactForm" (ngSubmit)="submit()">
                <div class="text-success mb-3" *ngIf="success" style="font-size: 1.2rem;">
                    {{success}}
                </div>
                <div class="text-danger mb-3" *ngIf="error" style="font-size: 1.2rem;">
                    {{error}}
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" class="form-control" formControlName="fullName" placeholder="{{'NAME'|translate}}">
                            <div class="text-danger mt-3" *ngIf="contactForm.get('fullName')?.touched && contactForm.get('fullName')?.errors">
                                <div *ngIf="contactForm.get('fullName')?.errors?.['required']">
                                    {{"REQUIRED"|translate}}
                                </div>
                                <div *ngIf="contactForm.get('fullName')?.errors?.['minlength']">
                                    İsim en az 3 karakter olmalıdır.
                                </div>
                                <div *ngIf="contactForm.get('fullName')?.errors?.['maxlength']">
                                    İsim en fazla 50 karakter olmalıdır.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" formControlName="email" class="form-control"
                                id="email" placeholder="{{'EMAIL'|translate}}">
                                <div class="text-danger mt-3" *ngIf="contactForm.get('email')?.touched && contactForm.get('email')?.errors">
                                    <div *ngIf="contactForm.get('email')?.errors?.['required']">
                                        {{"REQUIRED"|translate}}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" formControlName="phone" class="form-control"
                                id="number" placeholder="{{'PHONE'|translate}}">
                                <div class="text-danger mt-3" *ngIf="contactForm.get('phone')?.touched && contactForm.get('phone')?.errors">
                                    <div *ngIf="contactForm.get('phone')?.errors?.['required']">
                                        {{"REQUIRED"|translate}}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input formControlName="subject" type="text" class="form-control"
                                id="subject" placeholder="{{'SUBJECT'|translate}}">
                                <div class="text-danger mt-3" *ngIf="contactForm.get('subject')?.touched && contactForm.get('subject')?.errors">
                                    <div *ngIf="contactForm.get('subject')?.errors?.['required']">
                                        {{"REQUIRED"|translate}}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea  formControlName="message" cols="30" rows="5"
                                class="form-control" placeholder="{{'MESSAGE'|translate}}"></textarea>
                                <div class="text-danger mt-3" *ngIf="contactForm.get('message')?.touched && contactForm.get('message')?.errors">
                                    <div *ngIf="contactForm.get('message')?.errors?.['required']">
                                        {{"REQUIRED"|translate}}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [disabled]="contactForm.invalid || (isLoading$|async)">
                           <ng-container *ngIf="isLoading$">
                            <i class='bx bx-paper-plane icon-arrow before'></i><span class="label">{{'SEND'|translate}}</span><i
                            class="bx bx-paper-plane icon-arrow after"></i>
                           </ng-container>
                           <ng-container *ngIf="(isLoading$|async)==true">
                            <div class="d-flex align-items-center gap-2" style="margin-left: -3rem;">
                                <div class="spinner-border text-success" role="status">
                                  </div>
                                  <span>{{'SENDING'|translate}}</span>
                            </div>
                           </ng-container>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div> -->
</div>

<div id="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.1489484536187!2d144.96863327671068!3d-37.8333987351618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642a811644245%3A0xdebeb07db2ff5ebd!2s24%20Albert%20Rd%2C%20South%20Melbourne%20VIC%203205%2C%20Avustralya!5e0!3m2!1str!2str!4v1712129227196!5m2!1str!2str"></iframe>
</div>