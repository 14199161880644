<section>
    <header class="header-area header-color-white">
            <!-- <div class="navbar-gap"> </div> -->
            <header class="header-one sticky-header" [ngClass]="{'sticky': isSticky}">
                    <div class="header-navigation" [ngClass]="[visible ? '' : 'breakpoint-on']"
                            (window:resize)="detectHeader()">
                            <div
                                    class="container-fluid d-flex align-items-center justify-content-evenly container-1470 ">
                                    <div class="header-left">
                                            <a routerLink="/">
                                                <img src="assets/img/melbourneLogo.svg" alt="logo" class="logo">
                                            </a>
                                    </div>
                                    <div class="header-right d-flex align-items-center justify-content-between">
                                            <div class="site-nav-menu" [ngClass]="navMethod ? 'menu-opened':''">
                                                    <ul class="primary-menu" style="display: inline-block !important; margin-bottom: 1rem !important;
                                                    margin-top:1rem !important;" *ngFor="let item of orphanMenus">
                                                            <li>
                                                                    <a
                                                                            *ngIf="item?.frontMenuRecords[0]?.slug === '/'">
                                                                            {{item?.frontMenuRecords[0]?.name }}</a>
                                                                    <a href="javascript:void(0)"
                                                                            *ngIf="item?.frontMenuRecords[0]?.slug != '/' && item?.frontMenuRecords[0]?.slug != '/home'"
                                                                            [routerLink]="item?.frontMenuRecords[0]?.slug">{{
                                                                            item?.frontMenuRecords[0]?.name }}</a>

                                                                    <a href="javascript:void(0)"
                                                                            *ngIf="item?.frontMenuRecords[0]?.slug === '/home'"
                                                                            [routerLink]="'/'">{{
                                                                            item?.frontMenuRecords[0]?.name }}</a>

                                                                    <span class="dd-trigger"
                                                                            *ngIf="hasSubmenus(item)"
                                                                            (click)="trigger(item)">
                                                                            <i class="fal"
                                                                                    [ngClass]="item.open ? 'fa-solid fa-caret-up' : 'fa-solid fa-caret-down'"></i>


                                                                    </span>

                                                                    <ul class="submenu" *ngIf="hasSubmenus(item)"
                                                                            [ngClass]="item.open ? 'd-block' : ''">
                                                                            <li
                                                                                    *ngFor="let submenu of getSubmenus(item)">
                                                                                    <ng-container
                                                                                            *ngIf="submenu?.url">
                                                                                            <a [href]="submenu?.url"
                                                                                                    target="_blank">
                                                                                                    {{
                                                                                                    submenu.frontMenuRecords[0]?.name
                                                                                                    }}
                                                                                            </a>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                            *ngIf="!submenu?.url && submenu.frontPageId==null">
                                                                                            <a
                                                                                                    [href]="submenu.frontMenuRecords[0]?.slug + '/' + submenu.code">
                                                                                                    {{
                                                                                                    submenu.frontMenuRecords[0]?.name
                                                                                                    }}
                                                                                            </a>
                                                                                    </ng-container>
                                                                                    <ng-container
                                                                                            *ngIf="!submenu?.url && submenu.frontPageId!=null">
                                                                                            <a
                                                                                                    [href]="'pages'+submenu.frontMenuRecords[0]?.slug + '/' + submenu.code">
                                                                                                    {{
                                                                                                    submenu.frontMenuRecords[0]?.name
                                                                                                    }}
                                                                                            </a>
                                                                                    </ng-container>
                                                                            </li>
                                                                    </ul>
                                                            </li>
                                                            <!-- Data -->
                                                    </ul>
                                                    <!-- <li style="list-style: none !important; margin-left:20px;"
                                                            class="btn-donate">
                                                            <button (click)="showDialog()" type="submit"
                                                                    class="btn-bagis">{{'DONATE'|translate}}</button>
                                                    </li> -->
                                                    <!-- <a href="javascript:void(0)" class="nav-close" (click)="toggleNav()">
                                                            <i class="fal fa-times"></i>
                                                    </a> -->
                                            </div>
                                            <div class="header-extra d-flex align-items-center">
                                                    <div class="offcanvas-widget d-lg-block d-none">

                                                    </div>
                                                    <div class="nav-toggler"
                                                            [ngClass]="navMethod ? 'menu-opened':''"
                                                            (click)="toggleNav()">
                                                            <span></span><span></span><span></span>
                                                    </div>
                                            </div>
                                            
                                    </div>
                                    
                                    <!-- <button (click)="showDialog()" type="submit" class="btn-donate2 btn-bagis"
                                            style="padding: 6px 20px !important;">{{'DONATE'|translate}}</button> -->
                                    <div _ngcontent-ng-c2405407033="" class="language-selector">
                                            <img _ngcontent-ng-c2405407033="" *ngFor="let item of languages"
                                                    class="country-flag ng-star-inserted"
                                                    [src]="photoUrl+item?.flag" alt="{{item.name}}"
                                                    (click)="setNewLanguageRefresh(item?.langCode)">
                                    </div>
                            </div>
                    </div>
            </header>
    </header>
</section>
<app-prayer-times></app-prayer-times>
