<div class="page-title-area item-bg2 mb-3">
  <div class="container">
    <div class="page-title-content">
      <h1 class="text-center color-optional3">
        {{"OUR_RELIGIOUS_OFFICERS"|translate}}
      </h1>
    </div>
  </div>
</div>
<div class="instructor-area pt-100 pb-70">
  <div class="container">
    <div class="row justify-content-center">
      <ng-container *ngIf="!(isLoading$|async) && departmentUsers">
        <div class="col-lg-3 col-md-12" *ngFor="let gorevli of departmentUsers">
          <a [routerLink]="['/our-religious-officers/detail/',gorevli.id]">
            <div class="single-instructor-box mb-30">
              <div class="image">
                <img *ngIf="gorevli.profilePhoto" [src]="imageUrl+gorevli.profilePhoto" alt="image">
                <img *ngIf="!gorevli.profilePhoto" src="assets/img/none-profile.png" alt="foto">
              </div>
              <div class="content">
                <h3>{{gorevli.firstName}} {{gorevli.lastName}}</h3>
                <span>{{gorevli.title}}</span>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoading$|async">
        <div class="col-lg-3 col-md-12" *ngFor="let skeleton of skeletonArray">
          <div class="single-instructor-box mb-30">
            <div class="image">
              <p-skeleton min-width="278px" height="292px"></p-skeleton>
            </div>
            <div class="content">
              <p-skeleton min-width="306px" height="24px"></p-skeleton>
              <br>
              <p-skeleton min-width="306px" height="26px"></p-skeleton>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>