
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">Birliğimiz</h1>
        </div>
    </div>
</div>

<div class="container my-5">
    <h1 class="mb-5 text-center" style="color: var(--mainColor);">
        AUSTRALIAN DIYANET ASSOCIATION 
    </h1>
    <p class="mb-4">
        Australian Diyanet Association Türkiye Cumhuriyeti Melbourne Başkonsolosluğu İdari ve Sosyal İşleri Ataşeliği tarafından 2015 yılında kar amacı gütmeyen bir organizasyon olarak Melbourne merkezde kurulmuştur.
    </p>
    <h2 class="mb-3" style="color: var(--mainColor3);">
        Vizyonumuz
    </h2>
    <div class="underline"></div>
    <p>
        Bir arada yaşama kültürünü, uyum ve barışı teşvik etmek.
    </p>
    <h2 class="mb-3" style="color: var(--mainColor3);">
        Misyonumuz
    </h2>
    <div class="underline"></div>
    <ul>
        <li class="mb-3">
            İslam dininin itikat, ibadet, ahlak ve muamelat ile ilgili mesajını topluma ulaştırmak,
        </li>
        <li class="mb-3">
            Sahih dini bilgi ile toplumu aydınlatmak,
        </li>
        <li class="mb-3">
            Din eğitimi, din hizmetleri ve manevi rehberlik faaliyetleri yürütmek,
        </li>
        <li class="mb-3">
            Sosyal ve kültürel faaliyetler düzenlemek,
        </li>
        <li class="mb-3">
            Müslüman toplumun dini ve manevi, sosyal ve kültürel ihtiyaçlarını karşılamak,
        </li>
        <li class="mb-3">
            Karar vericilerle birlikte Müslüman toplumun çıkarlarını savunma süreçlerinin aktif ve önde gelen katılımcısı ve destekçisi olmak,
        </li>
        <li class="mb-3">
            Eğitim-öğretim, dini, sosyal veya kültürel hizmet kurumlarını teşvik etmek ve oluşturmak.
        </li>
    </ul>

    <h2 class="mb-3" style="color: var(--mainColor3);">
        Birliğin Amacı ve Hedefleri
    </h2>
    <div class="underline"></div>
    <ul class="mb-4" style="color: var(--paragraphColor);">
        <li class="mb-3">
            Avustralya’da, Avustralyalı Türklere ve aynı şekilde diğer Müslümanlara dini, milli ve kültürel hizmetler sunan kültür merkezleri tesis etmek.
        </li>
        <li class="mb-3">
            Avustralya’daki diğer dini toplumlardaki kişi, kurum ve vakıflarla diyalog sağlayarak evrensel değerleri, barışı ve toleransı desteklemek;
        </li>
        <li class="mb-3">
            Avustralya’da yaşayan Türk vatandaşlarını birleştirmek; birbirleriyle olan işbirliği, danışma ve etkileşimi güçlendirmek;
        </li>
        <li class="mb-3">
            Kültürel etkinlikler, konferanslar, seminerler ve toplantılar organize ederek, İslam dininin, Türk kültürünün ve Türk gelenek ve göreneklerinin farkındalığını, anlaşılabilirliğini, kabul edilebilirliğini ve korunmasını sağlamak;
        </li>
        <li class="mb-3">
            Avustralya’da yaşayan Türkleri organize etmek, kanuni haklarını korumak ve güçlendirmek; 
        </li>
        <li class="mb-3">
            İslam dininin, Türk ve İslam kültürünün ve Temes İslam Bilimlerinin araştırılması için araştırma merkezleri, kütüphaneler ve arşivler kurup sürdürmek;
        </li>
        <li class="mb-3">
            Gazete, dergi, süreli yayınlar, kitaplar, kitapçıklar veya broşürler basmak veya yayınlamak, ayrıca vakfın amaçlarını gerçekleştirebilmesi için kitapevleri tesis etmek.
        </li>
        <li class="mb-3">
            Türk ve Müslüman öğrencileri ağırlamak (imkânlar dâhilinde öğrenciler için barınma ihtiyacını karşılayacak mekânlar kiralamak veya inşa ettirmek).
        </li>
        <li class="mb-3">
            Türklere ve Müslümanlara, Türkçe ve din eğitimini desteklemek ve bu imkânları sunmak; Avustralya eğitim sistemiyle uyumlu ilk ve orta öğrenim kurumları tesis etmek.
        </li>
        <li class="mb-3">
            Türk ve Müslümanlar için cenaze vakıf fonu oluşturmak ve cenaze hizmetlerini yürütmek.
        </li>
        <li class="mb-3">
            Melbourne’deki Idari ve Sosyal İşler Ataşesine, din hizmetleri konusunda ve gerek Türklerin gerekse diğer kişilerin İslam konusunda aydınlatılması hususunda destek ve yardımcı olmak.
        </li>
        <li class="mb-3">
            Dernek'den onaylanmış olan Diyanet İşleri Başkanlığı tarafından işletilen ve kendi adı altında eğitim gören Avustralyalı ve yabancı öğrencilere din eğitim bursu ve yardım sağlamak;  
        </li>
        <li class="mb-3">
            Avustralyalı Türklere ve bütün Müslümanlara sadaka-zekât gibi bağışlardan oluşan dini mali yükümlülüklerini düzenlemede ve uygun yerlere harcamada yardımcı olmaktır.
        </li>
    </ul>
    <h2 class="mb-3" style="color: var(--mainColor3);">
        Yönetim Kurulu
    </h2>
    <div class="underline"></div>
    <p class="managers">

        <span class="d-block">
            Başkan:
            <b>
                <a routerLink="/atasemiz">
                    Prof. Dr. Harun IŞIK
                </a>
            </b>
        </span>
        <span class="d-block">
            Başkan Yardımcısı:
            <b>
                <a routerLink="/yonetim-kurulumuz">
                    Abdurrahman ŞAHİN
                </a>
            </b>
        </span>
        <span class="d-block">
            Muhasip:
            <b>
                <a routerLink="/yonetim-kurulumuz">
                    Hüseyin Durmaz
                </a>
            </b>
        </span>
        <span class="d-block">
            Halkla İlişkiler:
            <b>
                <a routerLink="/yonetim-kurulumuz">
                    Atilla Mete Ulaş
                </a>
            </b>
        </span>
        <span class="d-block">
            Genel Sekreter:
            <b>
                <a routerLink="/yonetim-kurulumuz">
                    Fatıma Sezgin
                </a>
            </b>
        </span>
    </p>
</div>