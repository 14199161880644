import { Component, OnInit } from '@angular/core';
import { CityService } from 'src/app/services/city.service';
import { PrayerTimesService } from 'src/app/services/prayer-times.service';
import { City } from 'src/app/view-models/models/city';
import { PrayerTime } from 'src/app/view-models/prayer-times';

@Component({
  selector: 'app-prayer-times',
  templateUrl: './prayer-times.component.html',
  styleUrls: ['./prayer-times.component.scss']
})
export class PrayerTimesComponent implements OnInit{
  cities:City[]=[];
  prayerTime:PrayerTime=null;

  cityName:string="";

  constructor(
    private cityService:CityService,
    private preyerTimesService:PrayerTimesService
  ) {
  }

  ngOnInit(): void {
      this.getCities();
  }
  selectedCity:number=1;
  latitude:number;
  longitude:number;
  melbourneTime: string;

  onCitySelected(e:any){
  this.selectedCity=e.target.value;
  const city = this.cities.find(c=>c.id==this.selectedCity);
  this.cityName=city.name;
  this.getMelbourneTime(this.cityName);
      setInterval(() => this.getMelbourneTime(this.cityName), 1000);
  this.latitude=city.latitude;
  this.longitude=city.longitude;
  this.getPrayerTimes();
  }
  sortCitiesByName(): City[] {
    return this.cities.sort((a, b) => a.name.localeCompare(b.name));
  }

  getMelbourneTime(cityName:string) {
    try {
      this.melbourneTime = new Intl.DateTimeFormat('en-AU', {
        timeZone: `Australia/${cityName}`,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(new Date());
    } catch (error) {
      return
    }
  }

  getCities(){
    this.cityService.getCities().subscribe((data:City[])=>{
      this.cities=data;
      const city = this.cities.find(c=>c.id==this.selectedCity);
      this.cityName=city.name
      this.getMelbourneTime(this.cityName);
      setInterval(() => this.getMelbourneTime(this.cityName), 1000);
      this.latitude=city.latitude;
      this.longitude=city.longitude;
      this.getPrayerTimes();
    })
  }

  getPrayerTimes(){
    this.preyerTimesService.getPrayerTimes(this.longitude,this.latitude).subscribe((data:PrayerTime)=>{
      this.prayerTime=data;
    })
  }


}
