import { Injectable } from "@angular/core";
import { Department, DepartmentById, DepartmentList, DepartmentUserById, DepartmentUserList } from "../view-models/models/department";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { environment } from "src/environments/environment.prod";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService{
    isLoadingSubject:BehaviorSubject<boolean>;
  isLoading$:Observable<boolean>;
  private apiUrl:string=environment.apiUrl;

  constructor
  (
    private http:HttpClient
  ){
    this.isLoadingSubject=new BehaviorSubject<boolean>(false);
      this.isLoading$=this.isLoadingSubject.asObservable();
  }

  getDepartments(departmentTypeId:number,language:string):Observable<DepartmentList>{
    this.isLoadingSubject.next(true);
    const params=new HttpParams()
    .set("departmentTypeId",departmentTypeId)
    .set("languageCode",language)

    const url=`${this.apiUrl}/GetAllDepartments`;
    return this.http.get<DepartmentList>(url,{params:params}).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }

  getDepartment(id:string):Observable<DepartmentById>{
    this.isLoadingSubject.next(true)
    const url=`${this.apiUrl}/GetDepartment?Id=${id}`;
    return this.http.get<DepartmentById>(url).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }

  getAllDepartmentUsers(departmentId:string,languageCode:string,memberTypeId:number|null,position:number|null):Observable<DepartmentUserList>{
    this.isLoadingSubject.next(true)

    const params=new HttpParams()
    .set("departmentId",departmentId)
    .set("languageCode",languageCode)
    .set("memberTypeId",memberTypeId)
    .set("position",position)
    
    const url=`${this.apiUrl}/GetAllDepartmentUsers`;
    return this.http.get<DepartmentUserList>(url,{params:params}).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }

  getDepartmentUser(id:string):Observable<DepartmentUserById>{
    this.isLoadingSubject.next(true)

    const url=`${this.apiUrl}/GetDepartmentUser?Id=${id}`;
    return this.http.get<DepartmentUserById>(url).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }
}