

<app-homeeleven-main-banner></app-homeeleven-main-banner>

<app-homeone-about></app-homeone-about>

<app-news-slider></app-news-slider>

<app-tab-contents></app-tab-contents>

<div class="blog-area">
    <div class="container">
        <app-hutbeler-fetvalar></app-hutbeler-fetvalar>
    </div>
</div>
