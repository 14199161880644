
<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">Umre Kayıtları</h1>
        </div>
    </div>
</div>
<div class="container my-5 px-3">
   <div class="row">
    <div class="col-12 mb-3">
        <img src="assets/img/homeIcon-pages/umre-organizasyon.jpg" alt="">
    </div>
    <div class="col-12">
        <h1 class="color-main3">UMRE ORGANİZASYONU</h1>
        <p>
            Umre kelimesi, ziyaret etmek anlamına gelmektedir.
        </p>
        <p class="py-3">
            Dinî bir terim olarak umre, ‘Belirli bir zamana bağlı olmaksızın ihrama girerek Kâbe’yi tavaf etmek, Safâ ile Merve arasında sa’y yapmak ve tıraş olup ihramdan çıkmak suretiyle yerine getirilen ibadet’ demektir. Umrenin iki farzı vardır: İhram ve tavaf. Bunlardan ihram şart, tavaf rükündür. Vacipleri ise sa’y ile tıraş olup ihramdan çıkmaktır. Ömürde bir defa umre yapmak sünnettir. Bazı âlimler farz olduğunu söylemişlerdir.        </p>
       
        <h5 class="color-main3 mb-3">
            Umrenin faziletine ilişkin bazı hadis-i şerifler vardır.Bunlardan ikisinin anlamı şöyledir: “Umre, diğer bir umre ile arasındaki günahları siler.”
        </h5>
        <p>
            ( Müslim, Hac, 437 (Hadis No:1349)
        </p>
        <h5 class="color-main3 mb-3">
            “Ramazan’da yapılan umrenin sevabı bir haccın sevabına denktir.”        </h5>
        <p>
            ( İbn-i Mace, Menasik, 45 (Hadis No:2991)
        </p>
    </div>
   </div>
</div>