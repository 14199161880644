import { Component } from '@angular/core';

@Component({
  selector: 'app-hac-kayit',
  templateUrl: './hac-kayit.component.html',
  styleUrls: ['./hac-kayit.component.scss']
})
export class HacKayitComponent {
  currentYear=new Date().getFullYear();
}
