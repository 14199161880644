import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.prod";
import { City } from "../view-models/models/city";

@Injectable({
    providedIn:'root'
})
export class CityService{
    private apiUrl:string=environment.apiUrl;

    constructor
    (
      private http:HttpClient
    ){}

    getCities():Observable<City[]>{
        const url = `${this.apiUrl}/City/GetList?id=1`;
        return this.http.get<City[]>(url);
    }
}