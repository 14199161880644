<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{page?.data.title}}
            </h1>
        </div>
    </div>
</div>
<div class="container my-5 px-3">
    <div class="row" *ngIf="page.data.thumbnail">
        <div class="col-12 mb-3">
            <img [src]="serverUrl+page.data.thumbnail" alt="">
        </div>
    </div>
   
    <div class="article-content">
        <p id="dynamic-value"></p>
    </div>
    <ng-container *ngIf="page?.data?.contentPageGalleries.length">
        <h3>{{"IMAGES"|translate}}</h3>
        <div class="row my-3">
            <owl-carousel-o>
                <ng-template *ngFor="let image of page?.data?.contentPageGalleries;let i=index;" carouselSlide>
                    <div class="col-11">
                        <img (click)="open(i)" class="rounded d-block" [src]="serverUrl+image.imagePath"
                            alt="">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </ng-container>
    <ng-container *ngIf="page?.data.videoPath">
        <a [href]="serverUrl+page?.data.videoPath" target="_blank" class="default-btn rounded me-3"><i class='fa-solid fa-video icon-arrow before'></i><span class="label">{{"SEE_VIDEO"|translate}}</span><i class="fa-solid fa-video icon-arrow after"></i></a>
    </ng-container>
    <ng-container *ngIf="page?.data.filePath">
        <a [href]="serverUrl+page?.data.filePath" target="_blank" class="default-btn rounded"><i class='fa-solid fa-file icon-arrow before'></i><span class="label">{{"SEE_FILE"|translate}}</span><i class="fa-solid fa-file icon-arrow after"></i></a>
    </ng-container>
</div>

