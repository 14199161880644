
<div class="page-title-area item-bg2 mb-3">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{"BOARD_OF_DIRECTORS"|translate}}
            </h1>
        </div>
    </div>
</div>
<div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let user of boardOfDirectiors">
               <a class="pointer" *ngIf="user.biographyEN || user.biographyTR" [routerLink]="['/board-of-directors/detail/',user.id]">
                <div class="single-instructor-box mb-30">
                    <div class="image">
                        <img *ngIf="!user?.profilePhoto" src="assets/img/profil-foto.jpg" alt="image">
                        <img *ngIf="user?.profilePhoto" [src]="imageUrl+user?.profilePhoto" alt="image">
                    </div>
                    <div class="content">
                        <h3>
                            {{user?.firstName}} {{user?.lastName}}
                        </h3>
                        <span>
                            {{user?.memberType?.name}}
                        </span>
                    </div>
                </div>
               </a>
               <a *ngIf="!user.biographyEN && !user.biographyTR" href="javascript:void(0)">
                <div class="single-instructor-box mb-30">
                    <div class="image">
                        <img *ngIf="!user?.profilePhoto" src="assets/img/profil-foto.jpg" alt="image">
                        <img *ngIf="user?.profilePhoto" [src]="imageUrl+user?.profilePhoto" alt="image">
                    </div>
                    <div class="content">
                        <h3>
                            {{user?.firstName}} {{user?.lastName}}
                        </h3>
                        <span>
                            {{user?.memberType?.name}}
                        </span>
                    </div>
                </div>
               </a>
            </div>
        </div>
    </div>
</div>