import { Component } from '@angular/core';

@Component({
  selector: 'app-ingilizce-brosurler',
  templateUrl: './ingilizce-brosurler.component.html',
  styleUrls: ['./ingilizce-brosurler.component.scss']
})
export class IngilizceBrosurlerComponent {

}
