<section class="news-detail-section my-5 px-4">
    <div class="container">
        <div class="news-wrapper">
            <div class="row">
                <ng-container *ngIf="news && !(isLoading$|async)">
                    <div class="col-lg-8 mb-5">
                        <div class="row">

                            <div class="col-12 mb-3 left">
                                <h3 class="mb-4 color-main3">
                                    {{news.title}}
                                    <div class="underline"></div>
                                </h3>
                                <img *ngIf="serverUrl+news?.thumbnail" [src]="serverUrl+news.thumbnail" alt=""
                                    class="rounded">
                            </div>
                            <div class="col-12">
                                <div class="my-2 d-flex align-items-center gap-2">
                                    <span>
                                        <i class="fa-regular fa-calendar color-main3"></i>
                                    </span>
                                    <h5 class="d-inline-block m-0 color-main3">
                                        {{ news.creationDate | date: 'dd/MM/yyyy' }}
                                    </h5>
                                </div>
                                <p [innerHTML]="news.description">

                                </p>

                                <ng-container *ngIf="news?.contentImages.length">
                                    <h3>{{"OTHER_IMAGES"|translate}}</h3>
                                    <div class="row mb-3">
                                        <owl-carousel-o>
                                            <ng-template *ngFor="let image of news?.contentImages;let i=index;" carouselSlide>
                                                <div class="col-11">
                                                    <img (click)="open(i)" class="rounded d-block" [src]="serverUrl+image.imagePath"
                                                        alt="">
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="news?.videoPath">
                                    <hr>
                                    <h3>{{"NEWS_VIDEO"|translate}}</h3>
                                    <video [src]="serverUrl+news.videoPath" controls class="rounded"></video>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <aside class="widgets">
                            <h3 class="d-flex flex-column gap-2 color-main3">
                                {{"OTHER_NEWS"|translate}}
                                <div class="underline"></div>
                            </h3>
                            <div class="widget" *ngFor="let news of newsList">
                                <a href="/haber-detay/{{news.id}}">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <img [src]="serverUrl+news?.thumbnail" alt=""
                                                class="img-fluid widget-image rounded me-3 d-inline-block">
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="news-content">
                                                <div
                                                    class="my-2 d-flex align-items-center gap-2 date-wrapper color-main3">
                                                    <span class="creation-date">
                                                        <i class="fa-regular fa-calendar"></i>
                                                    </span>
                                                    <span class="creation-date">
                                                        {{ news.creationDate | date: 'dd/MM/yyyy' }}
                                                    </span>
                                                </div>
                                                <h6>
                                                    {{news.title}}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </aside>
                    </div>
                </ng-container>
                <ng-container *ngIf="isLoading$|async">
                    <div class="col-lg-8 mb-5">
                        <div class="row">

                            <div class="col-12 mb-3 left">
                                <h3 class="mb-4 color-main3">
                                    <p-skeleton height="35px"></p-skeleton>
                                </h3>
                                <p-skeleton min-width="291px" height="280px"></p-skeleton>
                            </div>
                            <div class="col-12">
                                <div class="my-2 d-flex align-items-center gap-2">
                                    <span>
                                        <i class="fa-regular fa-calendar color-main3"></i>
                                    </span>
                                    <h5 class="d-inline-block m-0 color-main3">
                                        <p-skeleton width="107px" height="24px"></p-skeleton>
                                    </h5>
                                </div>
                                <p >
                                    <p-skeleton max-width="736px" height="190px"></p-skeleton>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <aside class="widgets">
                            <h3 class="d-flex flex-column gap-2 color-main3">
                                {{"OTHER_NEWS"|translate}}
                                <div class="underline"></div>
                            </h3>
                            <div class="widget" *ngFor="let news of newsList">
                                <a href="javascript:void(0)">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <p-skeleton max-width="121px" height="118px"></p-skeleton>
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="news-content">
                                                <div
                                                    class="my-2 d-flex align-items-center gap-2 date-wrapper color-main3">
                                                    <span class="creation-date">
                                                        <i class="fa-regular fa-calendar"></i>
                                                    </span>
                                                    <span class="creation-date">
                                                        <p-skeleton width="107px" height="24px"></p-skeleton>
                                                    </span>
                                                </div>
                                                <h6>
                                                    <p-skeleton width="255px" height="38px"></p-skeleton>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </aside>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</section>

<ng-container *ngIf="!news">
    <section class="no-content text-center my-5 py-5">
        <div class="container">
            <div class="message shadow d-inline-block p-5 rounded">
                <h1 class="color-main3">{{"ERROR"|translate}}</h1>
                <p>
                    {{"NO_NEWS_DETAIL"|translate}}
                </p>
                <a [routerLink]="['/haberler/']" class="default-btn btn-sm float-end w-100"><i
                        class='fa-solid fa-newspaper icon-arrow before'></i><span class="label">{{"NEWS"|translate}}</span><i
                        class="fa-solid fa-newspaper icon-arrow after"></i></a>
            </div>
        </div>
    </section>
</ng-container>