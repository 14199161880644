import { Injectable } from "@angular/core";
import { AppSetting } from "../view-models/models/app-setting";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment.prod";

@Injectable({
    providedIn:'root'
})
export class AppSettingService{
    private apiUrl:string=environment.apiUrl;
    constructor(
      private http:HttpClient
    ) { }
  
    //App settings linkleri
    getAppSettings():Observable<AppSetting[]>{
      const url=`${this.apiUrl}/AppSetting`;
      return this.http.get<AppSetting[]>(url);
    }
}