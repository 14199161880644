import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ContentPage, ContentPageList } from '../../../view-models/models/content-page';
import { Injectable } from "@angular/core";

@Injectable({
    providedIn:'root'
})
export class ContentPageService{
    isLoadingSubject:BehaviorSubject<boolean>;
  isLoading$:Observable<boolean>;
  private apiUrl:string=environment.apiUrl;

  constructor
  (
    private http:HttpClient
  ){
    this.isLoadingSubject=new BehaviorSubject<boolean>(false);
      this.isLoading$=this.isLoadingSubject.asObservable();
  }

  getAllContentPage():Observable<ContentPageList>{
    this.isLoadingSubject.next(true);
    const url=`${this.apiUrl}/GetAllContentPage`;
    return this.http.get<ContentPageList>(url).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }

  getContentPageById(id:string,langCode:string):Observable<ContentPage>{
    this.isLoadingSubject.next(true);
    const url=`${this.apiUrl}/GetContentPageById?Id=${id}&LanguageCode=${langCode}`;
    return this.http.get<ContentPage>(url).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }
}