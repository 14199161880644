import { Component } from '@angular/core';

@Component({
  selector: 'app-birligimiz',
  templateUrl: './birligimiz.component.html',
  styleUrls: ['./birligimiz.component.scss']
})
export class BirligimizComponent {

}
