<footer class="footer-area">
    <div class="container">
        <div class="row text-center">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget mb-30">
                    <h2>{{'CONTACT'|translate}}</h2>
                    <div class="w-100">
                        <div class="underline mx-auto"></div>
                    </div>
                    <ul class="contact-us-link">
                        <li *ngFor="let setting of appSettings">
                            <a *ngIf="setting.key=='adres'" [href]="getGoogleMapsUrl(setting.value)" target="_blank"><i class='bx bx-map'></i>
                            {{setting.value}}
                            </a>
                            <a *ngIf="setting.key=='telefon'" [href]="'tel:'+setting.value"><i class='bx bx-phone-call'></i>
                                {{setting.value}}
                            </a>
                            <a *ngIf="setting.key=='email'" [href]="'mailto:'+setting.value"><i class='bx bx-envelope'></i>
                                {{setting.value}}
                            </a>
                        </li>
                    </ul>
                    <ul class="social-link" >
                        <li *ngFor="let setting of appSettings">
                            <a *ngIf="setting.key=='facebook'" [href]="setting.value" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a>
                            <a *ngIf="setting.key=='instagram'" [href]="setting.value" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-md-6" *ngFor="let item of footerAreas">
                <div class="single-footer-widget mb-30">
                    <h2>{{item.areaName}}</h2>
                    <div class="w-100">
                        <div class="underline mx-auto"></div>
                    </div>
                    <ul class="support-link" *ngIf="item?.footerItems">
                        <li *ngFor="let child of item.footerItems">
                            <a *ngIf="child.slug.length" [routerLink]="[child.slug]" [ngClass]="{'d-flex align-items-center gap-1 justify-content-center':child.iconUrl}">
                                <ng-container *ngIf="child.iconUrl">
                                    <img [src]="serverUrl+child?.iconUrl" alt="icon">
                                </ng-container>
                                {{child.name}}
                            </a>
                            <a *ngIf="child.linkUrl.length" [href]="child.linkUrl" target="_blank">{{child.name}}</a>
                            <a *ngIf="!child.linkUrl.length && !child.slug.length" href="javascript:void(0)">{{child.name}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <!-- <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div> -->
            <p class="m-0">© Designed and Developed by <a href="https://vakifglobal.com/"
                    style="display:unset !important;" target="_blank"><img
                        src="../../../../assets/img/vakifGlobalLogo.png"
                        style="margin-left:3px; " alt=""></a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>