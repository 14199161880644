import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeone-about',
  templateUrl: './homeone-about.component.html',
  styleUrls: ['./homeone-about.component.scss']
})
export class HomeoneAboutComponent {

  constructor() { 
  }


}
