<section class="hutbeler-fetvalar py-5 my-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 mb-5 p-0">
                <div class="title mb-4">
                    <div class="d-flex flex-column">
                        <div>
                            <h1>{{"SERMONS"|translate}}</h1>
                            <div class="underline"></div>
                        </div>
                        <div>
                            <a [routerLink]="['/hutbeler']" class="float-end"><span
                                    class="label">{{"SEE_ALL"|translate}}</span></a>
                        </div>
                    </div>
                </div>
                <div class="hutbe-wrapper py-4 px-5 shadow">
                    <div *ngIf="sermons && !(isLoading$|async)">
                        <ng-container *ngFor="let hutbe of sermons">
                            <div class="hutbe">
                                <div class="hutbe-title">
                                    <b class="mb-2">{{hutbe.title}}</b>
                                    <span [routerLink]="['/hutbe-detay/',hutbe.id]">
                                        {{"SEE_DETAILS"|translate}}
                                    </span>
                                </div>
                                <div class="date">
                                    {{hutbe.creationDate|date:'dd/MM/yyyy'}}
                                </div>
                            </div>
                            <hr>
                        </ng-container>
                    </div>
                    <div *ngIf="isLoading$|async">
                        <ng-container *ngFor="let skeleton of skeletonArray">
                            <div class="hutbe">
                                <div class="hutbe-title">
                                    <b class="mb-2">
                                        <p-skeleton width="200px" height="40px"></p-skeleton>
                                    </b>
                                </div>
                                <div class="date">
                                    <p-skeleton width="80px" height="22px"></p-skeleton>
                                </div>
                            </div>
                            <hr>
                        </ng-container>
                    </div>
                    <div *ngIf="!sermons.length && !(isLoading$|async)">
                        {{"NO_SERMON"|translate}}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-3 px-5">
                <div class="title mb-4">
                    <h1>
                        {{"FATWAS"|translate}}
                    </h1>
                    <div class="underline"></div>
                </div>
                <div class="fetva-wrapper pb-5 px-4 shadow" [ngClass]="{'pt-5':fatwas[0]}">
                    <ng-container *ngIf="fatwas && !(isLoading$|async)">
                        <b class="mb-3 d-block">
                            {{fatwas[0]?.title}}
                        </b>
                        <p class="mb-4" [innerHTML]="fatwas[0]?.description"></p>
                        <div class="w-100">
                            <button *ngIf="fatwas[0]" class="mx-auto d-block"
                                [routerLink]="['/fetva-detay/',fatwas[0]?.id]">
                                {{"SEE_MORE"|translate}}
                            </button>
                        </div>
                    </ng-container>

                    <div *ngIf="!fatwas.length && !(isLoading$|async)">
                        {{"NO_FATWA"|translate}}
                    </div>

                    <!-- skeleton -->
                    <ng-container *ngIf="isLoading$|async">
                        <b class="mb-3 d-block">
                            <p-skeleton max-width="288px" height="22px"></p-skeleton>
                        </b>
                        <p-skeleton max-width="288px" height="216px"></p-skeleton>
                        <div class="w-100">
                        </div>
                    </ng-container>

                    <!-- skeleton end -->

                    <ng-container *ngIf="!fatwas && !(isLoading$|async)">
                        {{"NO_FATWA"|translate}}
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</section>