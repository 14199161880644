import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { DepartmentService } from 'src/app/services/department.service';
import { AusDepAdditionalInfos, Department, DepartmentById } from 'src/app/view-models/models/department';
import { Mosque } from 'src/app/view-models/models/Mosque';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-cami-detay',
  templateUrl: './cami-detay.component.html',
  styleUrls: ['./cami-detay.component.scss']
})
export class CamiDetayComponent implements OnInit{
  selectedView:string="";
  mosque:Mosque;
  department:Department=null;

  imageUrl:string=environment.serverUrl;

  isLoading$:Observable<boolean>;

  constructor(
    private activatedRoute:ActivatedRoute,
    private departmentService:DepartmentService
  ){
    this.isLoading$=departmentService.isLoading$;
  }

  ngOnInit(): void {
    this.getMosque();
  }

  getMosque() {
    this.activatedRoute.params.subscribe(data => {
      this.departmentService.getDepartment(data["id"]).subscribe((data: DepartmentById) => {
        this.department = data.data;
        // İlk değer olarak bir memberType seçelim
        if (this.department.departmentUsers.length > 0) {
          this.selectedView = this.department.departmentUsers[0].memberTypeName;
        }
      });
    });
  }

  setView(view: string): void {
    this.selectedView = view;
  }
  getGoogleMapsUrl(address: string): string {
    return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
  }

  isSocialMedia(value:string):boolean{
    const socialMedias:string[]=["facebook","instagram","youtube","x","twitter"]
    return socialMedias.includes(value.trim().toLowerCase())
  }

  otherAdditional(value: string): boolean {
    const values: string[] = ["sosyal medya", "diğer hizmetler", "i̇letişim"];
    
    // Herhangi bir value, values dizisindeki bir değeri içeriyorsa true döner.
    return values.some(val => value?.toLowerCase().includes(val));
  }

  usersByOrder(users:any){
    return users.sort((a,b)=>{
      return a.order-b.order;
    })
  }

  additionalByOrder(additionalInfos:AusDepAdditionalInfos[]){
    return additionalInfos.sort((a,b)=>{
      return a.order-b.order
    })
  }

}
