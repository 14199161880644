<section class="announcements-section my-5 py-5">
    <div class="container">
        <div class="pcss3t pcss3t-effect-scale pcss3t-theme-1">
            <ng-container *ngFor="let tab of tabTypes;let i = index;">
                <input type="radio" name="pcss3t" [checked]="i==0" id="tab{{i+1}}" class="tab-content-{{i+1}}" (change)="(setTab(tab.id,i+1))">
                <label for="tab{{i+1}}"><img  *ngIf="tab.icon" [src]="imageUrl+tab.icon" class="align-middle" alt="icon"><span>
                    {{tab.title}}
                </span></label>
            </ng-container>
            
            <ul>
                <li class="tab-content tab-content-{{index}} typography">
                    <div *ngIf="contents.length && !(isLoading$|async)">
                        <ng-container *ngFor="let content of contents">
                            <div class="announcements-wrapper">
                                <div class="announcement responsive">
                                    <div class="title p-0">
                                        {{content.title}}
                                    </div>
                                   <div class="d-flex justify-content-between">
                                    <div class="date">
                                        {{content.creationDate|date:'dd/MM/yyyy'}}
                                    </div>
                                    <a class="ms-auto" [routerLink]="['/duyuru-detay/',content.id]">{{"SEE_DETAIL"|translate}}</a>
                                   </div>
                                </div>
                                <div class="announcement d-flex align-items-center">
                                    <div class="date">
                                        {{content.creationDate|date:'dd/MM/yyyy'}}
                                    </div>
                                    <div class="title ">
                                        {{content.title}}
                                    </div>
                                    <a *ngIf="!content.contentTypeName.trim().toLowerCase().includes('mesaj')" class="ms-auto" [routerLink]="['/content-detail/',content.id]">{{"SEE_DETAIL"|translate}}</a>
                                    <a *ngIf="content.contentTypeName.trim().toLowerCase().includes('mesaj')" href="javascript:void(0)" (click)="showMessage(content)" class="ms-auto">
                                        <!-- <i class="fa-regular fa-message align-middle"></i> -->
                                         {{"SEE_DETAIL"|translate}}</a>
                                </div>
                                <hr>
                               
                            </div>
                        </ng-container>
                      
                    </div>
                    <div *ngIf="isLoading$|async">
                        <ng-container *ngFor="let item of skeletonArray">
                            <div class="announcements-wrapper">
                                <div class="announcement responsive">
                                    <div class="title p-0">
                                        <p-skeleton width="650px" height="26px"></p-skeleton>
                                    </div>
                                   <div class="d-flex justify-content-between">
                                    <div class="date">
                                        <p-skeleton width="69px" height="19px"></p-skeleton>
                                    </div>
                                   </div>
                                    
                                </div>
                                <div class="announcement d-flex align-items-center">
                                    <div class="date">
                                        <p-skeleton width="69px" height="19px"></p-skeleton>
                                    </div>
                                    <div class="title ">
                                        <p-skeleton width="650px" height="26px"></p-skeleton>
                                    </div>
                                </div>
                            </div>
                            <hr>
                        </ng-container>
                    </div>
                    <div *ngIf="!contents.length && !(isLoading$|async)">
                        <h3 class="color-main3">
                            {{"NO_TAB_CONTENT"|translate}}
                        </h3>
                    </div>
                </li>
                
            </ul>
            <div class="paginator card flex justify-content-center" *ngIf="contents">
                <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="contentsLength"></p-paginator>
            </div>
        </div>
       
        </div>
</section>
<div class="message-detail" *ngIf="messageBox">
    <div class="message-title p-2 d-flex justify-content-between align-items-center">
        <span>Avustrayla Diyanet Birliği</span>
        <span class="px-1 close" (click)="messageBox=false">X</span>
    </div>
    <div class="message-body py-3 px-2 h-100 d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <hr>
            <span>{{message.creationDate|date:'dd/MM/yyyy'}}</span>
            <hr>
        </div>
        <div class="d-flex mt-auto h-100">
            <img src="assets/img/favicon.png" class="" alt="">
            <div class="message p-3" >
                <p [innerHTML]="message.description" class="pb-3" style="color: white;"></p>
            </div>
        </div>
    </div>
</div>