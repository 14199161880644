import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ContentObject, ContentResponse } from '../view-models/models/content';
import { finalize } from 'rxjs/operators';
import { TabTypeList } from '../view-models/models/tab-type';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  isLoadingSubject:BehaviorSubject<boolean>;
  isLoading$:Observable<boolean>;

  newsSliderIsLoadingSubject:BehaviorSubject<boolean>;
  newsSliderIsLoading$:Observable<boolean>;
  private apiUrl:string=environment.apiUrl;

  constructor
  (
    private http:HttpClient
  ){
    this.isLoadingSubject=new BehaviorSubject<boolean>(false);
      this.isLoading$=this.isLoadingSubject.asObservable();
      this.newsSliderIsLoadingSubject=new BehaviorSubject<boolean>(false);
      this.newsSliderIsLoading$=this.newsSliderIsLoadingSubject.asObservable();
  }

  getContents(lang?:string,contentTypeId?:number,skip?:number,pageSize?:number):Observable<ContentResponse>{
    if(contentTypeId!=10){
      this.isLoadingSubject.next(true);
    }
    else{
      this.newsSliderIsLoadingSubject.next(true);
    }
    const params=new HttpParams()
    .set("LanguageCode",lang)
    .set("ContentTypeId",contentTypeId)
    .set("Skip",skip)
    .set("PageSize",pageSize)
    if(skip)
    params.set("Skip",skip)
    if(pageSize)
    params.set("PageSize",pageSize)

    const url=`${this.apiUrl}/GetAllContentResource`;
    return this.http.get<ContentResponse>(url,{params:params}).pipe(
      finalize(()=>{
        if(contentTypeId!=10){
          this.isLoadingSubject.next(false);
        }
        else{
          this.newsSliderIsLoadingSubject.next(false);
        }
      })
    );
  }

  getContent(id:string):Observable<ContentObject>{
    this.isLoadingSubject.next(true)
    const url=`${this.apiUrl}/GetContentById?id=${id}`;
    return this.http.get<ContentObject>(url).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }

  getAllTabType(langCode:string):Observable<TabTypeList>{
    this.isLoadingSubject.next(true)
    const url=`${this.apiUrl}/GetAllTabType?languageCode=${langCode}`;
    return this.http.get<TabTypeList>(url).pipe(
      finalize(()=>this.isLoadingSubject.next(false))
    );
  }
}
