import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, HostListener } from '@angular/core';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { NavbarMenuService } from 'src/app/services/navbar-menu.service';
import { AppSetting } from 'src/app/view-models/models/app-setting';

@Component({
  selector: 'app-header-style-seven',
  templateUrl: './header-style-seven.component.html',
  styleUrls: ['./header-style-seven.component.scss']
})
export class HeaderStyleSevenComponent implements OnInit {
  links: any[] = [];
  childs: any[] = [];
  sideChilds: any[] = [];
  sideLinks: any[] = [];

  dialogVisible:boolean=false;

  language=localStorage.getItem("language")

  facebook: AppSetting = null;
  instagram: AppSetting = null;

  ngOnInit(): void {
    this.setCurrentLanguage();
    this.getNavbarMenu();
    this.getAppSettings();
  }

  constructor(
    private navbarService: NavbarMenuService,
    private appSettingService: AppSettingService,
    private toastr:ToastrService
  ) { }

  getNavbarMenu() {
    this.navbarService.getMenuItems(this.language).subscribe(data => {
      this.links = data.data.filter(d => !d.parentId && d.isActive).sort((a, b) => a.order - b.order);
      this.childs = [];
      this.sideChilds = [];
      this.sideLinks = [];

      this.links.forEach(parent => {
        if (parent.inverseParent) {
          const sortedChilds = parent.inverseParent.filter(x => x.isActive).sort((a, b) => a.order - b.order);
          this.childs = this.childs.concat(sortedChilds);
          sortedChilds.forEach(child => {
            if (child.inverseParent) {
              const sortedSideChilds = child.inverseParent.filter(x => x.isActive).sort((a, b) => a.order - b.order);
              this.sideChilds = this.sideChilds.concat(sortedSideChilds);

              sortedSideChilds.forEach(sideChild => {
                if (sideChild.inverseParent) {
                  const sortedSideLinks = sideChild.inverseParent.filter(x => x.isActive).sort((a, b) => a.order - b.order);
                  this.sideLinks = this.sideLinks.concat(sortedSideLinks);
                }
              });
            }
          });
        }
      });
    });
  }

  getAppSettings() {
    this.appSettingService.getAppSettings().subscribe((data: AppSetting[]) => {
      data.forEach(appSetting => {
        switch (appSetting.key) {
          case "facebook":
            this.facebook = appSetting;
            break;
          case "instagram":
            this.instagram = appSetting;
            break;
        }
      })
    })
  }

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  changeIcon = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.changeIcon = true ? window.innerWidth <= 992 : window.innerWidth > 992;
  }


  currentLanguage: Language;
  languages: Language[] = [
    { ID: 1, Name: "TR", Flag: "tr.jpg", Code: "tr" },
    { ID: 2, Name: "EN", Flag: "aus.jpg", Code: "en" }
  ];

  returnLanguages(currentID) {
    return this.languages.filter(lang => lang.ID !== currentID)
  }

  changeLanguage(id:number) {
    const lang = this.languages.find(l=>l.ID==id);
    if(this.currentLanguage!=lang){
      this.currentLanguage = lang;
      localStorage.setItem("language", this.currentLanguage.Code);
      this.classApplied2 = false;
      window.location.href="/";
    }
  }

  setCurrentLanguage() {
    const lang = localStorage.getItem("language");
    if (lang == "tr")
      this.currentLanguage = this.languages.find(l=>l.Code=="tr")
    else
      this.currentLanguage = this.languages.find(l=>l.Code=="en")
  }

  getChildsByParentID(parentID: number) {
    return this.childs.filter(child => child.parentId === parentID);
  }

  getSideChilds(parentId: number) {
    return this.sideChilds.filter(x => x.parentId == parentId)
  }

  getSideLinks(parentId: number) {
    return this.sideLinks.filter(x => x.parentId == parentId)
  }

  navbarOpened = false;
  navbarToggle() {
    this.navbarOpened = !this.navbarOpened;
  }

  classApplied2: boolean = false;
  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }

  trigger(parent: { open: boolean; }) {
    parent.open = !parent.open;
  }
  childTrigger(child: { open: boolean; }) {
    child.open = !child.open;
  }
  sideChildTrigger(child: { open: boolean; }) {
    child.open = !child.open;
  }
  showDialog(){
    this.dialogVisible=!this.dialogVisible;
  }

  async copyToClipboard(copyType:number): Promise<void>{
    let copyText:string="";
    switch(copyType){
      case 1:
        copyText="Australian Diyanet Association";
        break;
      case 2:
        copyText="063-243";
        break;
      case 3:
        copyText="1035 5051";
        break;
    }
    try {
      await navigator.clipboard.writeText(copyText);
      this.toastr.success("Başarıyla kopyalandı.")
    } catch (err) {
      this.toastr.error("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
    }
  }
}

interface Language {
  ID: number,
  Name: string,
  Flag: string,
  Code: string
}