<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">Hutbeler</h1>
        </div>
    </div>
</div>

<section class="sermons my-5 px-4">
    <div class="container">
        <div class="row mb-5" *ngIf="sermons.length">
            <div class="col-xl-2 col-lg-3 col-md-4 mb-3" *ngFor="let sermon of sermons">
                <div class="sermon p-3 rounded shadow">
                    <div class="title">
                        <b>
                            {{sermon.title}}
                        </b>
                    </div>
                   <p [innerHTML]="sermon.description" class="description"> </p>
                   <p class="date">
                    {{sermon.creationDate | date:'dd/MM/yyyy'}}
                   </p>
                   <a [routerLink]="['/hutbe-detay/',sermon.id]">{{"SEE_DETAIL"|translate}}<i class="fa-solid fa-arrow-right ms-2"></i></a>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!sermons.length">
            <div class="data-not-found p-3 my-5 rounded shadow">
               <div class="row">
                    <div class="col-12">
                        <img src="assets/img/data-not-found.jpg" alt="">
                    </div>
                    <div class="col-12">
                        <h1>
                            {{"NO_DATA_FOUND_FOR_PAGE"|translate}}
                        </h1>
                    </div>
               </div>
            </div>
        </ng-container>
        <div class="card flex justify-content-center" *ngIf="sermons">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="sermonsCount"></p-paginator>
        </div>
    </div>
</section>