import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/department.service';
import { DepartmentUser, DepartmentUserList } from 'src/app/view-models/models/department';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-yonetim-kurulu',
  templateUrl: './yonetim-kurulu.component.html',
  styleUrls: ['./yonetim-kurulu.component.scss']
})
export class YonetimKuruluComponent implements OnInit{

  private language=localStorage.getItem("language");

  imageUrl:string=environment.serverUrl;
  boardOfDirectiors:DepartmentUser[]=[];

  constructor(
    private departmentService:DepartmentService
  ) {
  
  }

  ngOnInit(): void {
   this.getDirectors()
  }

  getDirectors(){
    this.departmentService.getAllDepartmentUsers("00000000-0000-0000-0000-000000000000",this.language,0,1).subscribe((data:DepartmentUserList)=>{
      this.boardOfDirectiors=data.data.filter(x=>x.isActive).sort((a,b)=>{
        return a.order-b.order;
      });
    })
  }
}
