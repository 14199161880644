import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Lightbox } from 'ngx-lightbox';
import { ContentService } from 'src/app/services/content.service';
import { Content, ContentObject } from 'src/app/view-models/models/content';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit{
  langCode=localStorage.getItem("language");
  news:Content;
  newsList:Content[]=[];
  isLoading$:Observable<boolean>;
  private _albums: Array<any> = [];
  serverUrl=environment.serverUrl;
constructor(private route:ActivatedRoute, private contentService:ContentService,private _lightbox: Lightbox) {
  this.isLoading$=contentService.isLoading$;
}
  ngOnInit(): void {
    this.getNewsList();
    this.getNewsDetail();
  }

  getNewsList(){
    this.contentService.getContents(this.langCode,10,0,10).subscribe(data=>{
      this.newsList=data.data
      .filter(a => a.isActive && a.id!=this.news.id)
      .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());;
    })
  }

  getNewsDetail(){
    this.route.data.subscribe(
      (data: { news: ContentObject }) => {
        if (data.news) {
          this.news=data.news.data 
          this.setLightboxAlbum();
        }
      });
  }

  setLightboxAlbum(){
    for (let i = 0; i < this.news?.contentImages.length; i++) {
      const src = this.serverUrl+this.news.contentImages[i].imagePath;
      const caption = `Görsel ${i+1}`;
      const thumb = this.serverUrl+this.news.contentImages[i].imagePath;
      const album = {
         src: src,
         caption: caption,
         thumb: thumb
      };
  
      this._albums.push(album);
    }
  }
  owlOptions: OwlOptions = {
    autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoHeight: true,
        autoplay: false,
        dots: true,
        loop: false,
        margin:100,
        items:1,
        nav: false,
        autoplayTimeout: 4000
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

}
