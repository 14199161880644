import { Injectable } from '@angular/core';
import { FooterResponse } from '../view-models/models/footer';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  private apiUrl:string=environment.apiUrl;
  constructor(
    private http:HttpClient
  ) { }

  //Footer menü linkleri
  getFooterItems(langCode:string):Observable<FooterResponse>{
    const url=`${this.apiUrl}/GetAllFooterItem?LanguageCode=${langCode}`;
    return this.http.get<FooterResponse>(url);
  }
}
