import { Component } from '@angular/core';
import { Pillar } from 'src/app/view-models/models/Pillar';

@Component({
  selector: 'app-imanin-sartlari',
  templateUrl: './imanin-sartlari.html',
  styleUrls: ['./imanin-sartlari.scss']
})
export class ImaninSartlariComponent {
  pillars:Pillar[] = [
    { ID: 1, tabId: 'tab', title: "Allah'a İman", description: `İslam'ın temel inanç esaslarından biridir ve tüm iman esaslarının merkezinde yer alır. İman eden kişi, Allah'ın varlığına ve birliğine, hiçbir ortağı ve benzeri olmadığına, O'nun yüce sıfatlarına ve isimlerine inanır. Kur'an'da şöyle buyrulmuştur: "O, göklerin ve yerin yaratıcısıdır. O'nun hiçbir dengi yoktur. O işitendir, görendir." (Şura 42:11)"`, icon: '1.png', thumb:"1.png", selected: true },
    { ID: 2, tabId: 'tab', title: 'Meleklere İman', description: `Allah'ın yaratmış olduğu ve tamamen O'na itaat eden, nurdan yaratılmış varlıklar olan meleklere inanmaktır. Melekler, Allah'ın emirlerini yerine getirir. Kur'an'da şöyle buyrulmuştur: "Kim Allah'a, meleklerine, kitaplarına, peygamberlerine ve ahiret gününe inanmazsa, şüphesiz derin bir sapıklık içine düşmüştür." (Nisa 4:136)`, thumb:"2.png", icon: '2.png', selected: false },
    { ID: 3, tabId: 'tab', title: 'Kitaplara İman', description: `Allah tarafından peygamberlere gönderilen kutsal kitaplara inanmayı içerir. Bu kitaplar, insanlara doğru yolu göstermek ve onları hidayete erdirmek için indirilmiştir. Müslümanlar, Allah'ın gönderdiği kitapların hepsine inanır, fakat Kur'an-ı Kerim'in son ve değişmez ilahi kitap olduğuna iman ederler. Kur'an'da şöyle buyrulmuştur: "Andolsun, biz peygamberlerimizi açık delillerle gönderdik ve onlarla birlikte Kitap ve mizan indirdik." (Hadid 57:25)`, thumb:"3.png", icon: '3.png', selected: false },
    { ID: 4, tabId: 'tab', title: 'Peygamberlere İman,', description: `Allah'ın, insanları doğru yola iletmek için gönderdiği elçilere inanmaktır. Bu peygamberler, Allah'ın emir ve yasaklarını insanlara bildirmişler ve onlara örnek olmuşlardır. Kur'an'da şöyle buyrulmuştur: "Peygamber, Rabbinden kendisine indirilene iman etti, müminler de. Her biri Allah'a, meleklerine, kitaplarına ve peygamberlerine iman etti." (Bakara 2:285)`, thumb:"4.png", icon: '4.png', selected: false },
    { ID: 5, tabId: 'tab', title: 'Ahirete İman', description: `Bu dünyadan sonra bir hayatın daha olduğuna ve herkesin yaptıklarından dolayı hesap vereceğine inanmaktır. Ahiret inancı, insanları adaletli ve ahlaklı olmaya teşvik eder. Kur'an'da şöyle buyrulmuştur: "Ahirete kesin olarak inananlar, doğru yoldadırlar." (Bakara 2:4)`, thumb:"5.png", icon: '5.png', selected: false },
    { ID: 6, tabId: 'tab', title: 'Kader ve Kazaya İman', description: `Her şeyin Allah'ın ilmi, iradesi ve kudretiyle meydana geldiğine inanmaktır. İman eden kişi, Allah'ın her şeyi önceden belirlediğini ve her şeyin O'nun kontrolünde olduğunu bilir. Kur'an'da şöyle buyrulmuştur: "Şüphesiz biz her şeyi bir ölçüye göre yarattık." (Kamer 54:49)`, thumb:"6.png", icon: '6.png', selected: false }

  ];

  constructor() { }

  setActiveTab(pillarID: number): void {
    this.pillars.forEach(pillar => {
      if (pillar.ID === pillarID) {
        pillar.selected = true;
      } else {
        pillar.selected = false;
      }
    });
  }

open:boolean=false;
pillarToggle(item:{open:boolean}){
  item.open=!item.open;
}
}
