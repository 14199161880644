import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { Content, ContentResponse } from 'src/app/view-models/models/content';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-dini-bilgiler',
  templateUrl: './dini-bilgiler.component.html',
  styleUrls: ['./dini-bilgiler.component.scss']
})
export class DiniBilgilerComponent implements OnInit{
  currentLanguage=localStorage.getItem("language");
  religiousInformations:Content[]=[];

  serverUrl:string=environment.serverUrl;

  totalCount:number=0;

  isLoading:Observable<boolean>;
  skeletonArray=Array(8);

  constructor(
    private contentService:ContentService
  ) {
    this.isLoading=contentService.isLoading$;
  }

  ngOnInit(): void {
    this.getAllReligiousInformations();
  }

  getAllReligiousInformations(){
    this.contentService.getContents(this.currentLanguage,37,0,1000).subscribe((data:ContentResponse)=>{
      this.religiousInformations=data.data
      .filter(x=>x.isActive)
      .sort((a,b)=>{
        return a.order-b.order
      });
      this.totalCount=data.totalCount;
    })
  }
  
}
