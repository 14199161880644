<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <h1 class="text-center color-optional3">
                {{"FITRAH/ZAKAH"|translate}}
            </h1>
        </div>
    </div>
</div>
<div class="container my-5 px-3">
    <div class="row">
        <div class="col-12 mb-3">
            <img *ngIf="language=='tr'" src="assets/img/homeIcon-pages/fitre-zekat.jpg" alt="">
            <img *ngIf="language=='en'" src="assets/img/homeIcon-pages/fitre-zekat-en.webp" alt="">
        </div>
        <div class="col-12">
            <h2 class="color-main3">{{"ACCOUNT_DETAILS"|translate}}</h2>
            <table [cellSpacing]="1" [cellPadding]="4" class="mb-4">
                <tr>
                    <td><b>{{"ACCOUNT_NAME"|translate}}&nbsp;:</b></td>
                    <td>Australian Diyanet Association</td>
                </tr>
                <tr>
                    <td><b>BSB&nbsp;:</b></td>
                    <td>063-243</td>
                </tr>
                <tr>
                    <td><b>ACC&nbsp;:</b></td>
                    <td>1035 5051</td>
                </tr>
                <tr>
                    <td><b>{{"FITRAH_AMOUNT"|translate}}&nbsp;:</b></td>
                    <td>25 AUD</td>
                </tr>
            </table>
            <div class="row">
                <ng-container *ngIf="language=='tr'">
                    <div class="col-md-6">
                        <h3 class="color-main3">
                            Zekat İşlemleri
                        </h3>
                        <p>
                            Zekat, İslam dininde önemli bir ibadettir ve mal varlığına sahip olan Müslümanlar için bir
                            yükümlülüktür. Zekatın işleyişi ve önemi hakkında daha fazla bilgi aşağıda sunulmaktadır:
                        </p>
                        <h5 class="color-main3">
                            Zekat Nedir?
                        </h5>
                        <p>
                            Zekat, Müslümanların yıllık olarak zenginliklerinin bir kısmını ihtiyaç sahiplerine verme eylemidir.
                            İslam dinine göre, zekat vermek, mal varlığının temizlenmesi ve fakirlerin desteklenmesi anlamına gelir.
                            Zekat, toplumda ekonomik dengeyi sağlamak için kullanılır. Altın, gümüş, para, ticari mallar ve diğer
                            yatırımlar zekata tabi olabilir.
                        </p>
                        <h5 class="color-main3">
                            Zekat Nasıl Hesaplanır?
                        </h5>
                        <p>
                            Zekat miktarı, sahip olduğunuz mal varlığının belirli bir yüzdesi olarak hesaplanır. Genellikle, altın,
                            gümüş, para, ticari mallar ve diğer yatırımlar zekata tabi tutulur. Zekatın doğru bir şekilde
                            hesaplanması için yerel dini otoriteler veya bilgin kişilerden yardım almak önemlidir. Zekatın
                            hesaplanması sırasında, borçlarınız, temel ihtiyaçlarınız ve ailenizin masrafları göz önüne alınır.
                        </p>
                        <h5 class="color-main3">
                            Zekat Verme Yöntemleri
                        </h5>
                        <p>
                            Zekat verme yöntemleri çeşitli olabilir. Zekatınızı doğrudan ihtiyaç sahiplerine, yetkilendirilmiş
                            kuruluşlar aracılığıyla veya yerel camilere vermek gibi farklı seçenekler bulunur. Zekatınızın en etkili
                            şekilde dağıtılması için güvenilir kaynaklara başvurmanız önemlidir. Ayrıca, zekat verme işlemini yılın
                            belirli zamanlarında gerçekleştirmek gelenektir, genellikle Ramazan ayı tercih edilir.
                        </p>
                        <h5 class="color-main3">
                            Zekatın Önemi
                        </h5>
                        <p class="mb-4">
                            Zekat, İslam'ın beş şartından biri olarak kabul edilir ve mal varlığının temizlenmesini ve yardıma
                            muhtaç olanlara destek sağlanmasını teşvik eder. Zekat vermek, kişinin sadaka verme ve yardımlaşma
                            sorumluluğunu yerine getirmesini sağlar ve toplumda ekonomik dengeyi korur. Ayrıca, zekat vermek, manevi
                            bir ibadet olarak kabul edilir ve Müslümanlar için önemli bir dini görevdir..
                        </p>
                    </div>
                    <div class="col-md-6">
                        <h3 class="color-main3">
                            Fitre İşlemleri
                        </h3>
                        <p>
                            Fitre, İslam dininde önemli bir ibadettir ve Ramazan ayının sonunda Müslümanlar için bir yükümlülüktür. Fitrenin işleyişi ve önemi hakkında daha fazla bilgi aşağıda sunulmaktadır:
                        </p>
                        <h5 class="color-main3">
                            Fitre Nedir?
                        </h5>
                        <p>
                            Fitre, Ramazan ayında oruç tutan Müslümanların, oruçlarını tamamladıktan sonra ihtiyaç sahiplerine verdikleri yardım miktarıdır. İslam dinine göre, fitre vermek, oruç ibadetini tamamlamanın bir parçası olarak kabul edilir ve yoksullara yardım etmeyi amaçlar. Fitre, toplumda dayanışmayı ve yardımlaşmayı teşvik eder. Belirli bir miktar para ya da temel gıda maddeleri fitre olarak verilebilir.
                        </p>
                        <h5 class="color-main3">
                            Fitre Nasıl Hesaplanır?
                        </h5>
                        <p>
                            Fitre miktarı, bir kişinin günlük temel gıda ihtiyacını karşılayacak düzeyde belirlenir. Yerel dini otoriteler genellikle fitre miktarını belirler ve bu miktar her yıl değişebilir. Fitre, ailenin tüm fertleri için hesaplanır ve Ramazan ayının sonuna kadar verilmesi gerekir. Bu bağlamda, temel gıda maddelerinin piyasa değeri göz önünde bulundurulur.
                        </p>
                        <h5 class="color-main3">
                            Fitre Verme Yöntemleri
                        </h5>
                        <p>
                            Fitre verme yöntemleri çeşitli olabilir. Fitreyi doğrudan ihtiyaç sahiplerine verebilir, yetkilendirilmiş kuruluşlar aracılığıyla dağıtabilir veya yerel camilere teslim edebilirsiniz. Fitrenin, özellikle Ramazan ayının son günlerinde verilmesi geleneksel bir uygulamadır. Fitrenizin en doğru ve etkili şekilde ulaşması için güvenilir kanallar üzerinden vermek önemlidir.
                        </p>
                        <h5 class="color-main3">
                            Fitrenin Önemi
                        </h5>
                        <p>
                            Fitre, İslam'ın temel ibadetlerinden biri olarak kabul edilir ve oruç ibadetini tamamlarken fakirlere yardım etmeyi teşvik eder. Fitre vermek, kişinin toplumsal sorumluluklarını yerine getirmesini sağlar ve toplumda sosyal adaleti destekler. Ayrıca, fitre vermek, manevi bir ibadet olarak kabul edilir ve Müslümanlar için Ramazan ayının sonuna kadar yerine getirilmesi gereken önemli bir dini görevdir.
                        </p>
                    </div>
                </ng-container>
                <ng-container *ngIf="language=='en'">
                    <div class="col-md-6">
                        <h3 class="color-main3">
                            Zakah Transactions
                        </h3>
                        <p>
                            Zakah is an important act of worship in Islam and an obligation for Muslims who have assets. More information on the functioning and importance of zakah is presented below:	
                        </p>
                        <h5 class="color-main3">
                            What is Zakah?
                        </h5>
                        <p>
                            Zakah is the act of Muslims annually giving a portion of their wealth to those in need. According to the religion of Islam, giving zakat means cleansing the wealth and supporting the poor. Zakah is used to ensure economic balance in society. Gold, silver, money, commercial goods and other investments may be subject to zakat.
                        </p>
                        <h5 class="color-main3">
                            How is Zakah Calculated?
                        </h5>
                        <p>
                            The amount of zakah is calculated as a certain percentage of the assets you own. Generally, gold, silver, money, commercial goods and other investments are subject to zakah. It is important to seek the help of local religious authorities or knowledgeable people to calculate zakat correctly. The calculation of zakah takes into account your debts, basic needs and family expenses.
                        </p>
                        <h5 class="color-main3">
                            Zakah Giving Methods
                        </h5>
                        <p>
                            There are various ways of giving zakah. There are different options such as giving your zakah directly to those in need, through authorised organisations or to local mosques. It is important to use reliable sources for the most effective distribution of your zakah. It is also customary to give zakat at certain times of the year, usually during Ramadan.
                        </p>
                        <h5 class="color-main3">
                            The Importance of Zakah
                        </h5>
                        <p class="mb-4">
                            Zakah is considered one of the five pillars of Islam and encourages the purification of wealth and support for those in need. Giving zakat enables a person to fulfil his/her responsibility of almsgiving and charity and maintains economic balance in society. In addition, giving zakat is considered a spiritual worship and is an important religious duty for Muslims.
                        </p>
                    </div>
                    <div class="col-md-6">
                        <h3 class="color-main3">
                            Fitrah Transactions
                        </h3>
                        <p>
                            Fitrah is an important act of worship in Islam and is an obligation for Muslims at the end of Ramadan. More information about the functioning and importance of Fitrah is presented below:
                        </p>
                        <h5 class="color-main3">
                            What is Fitrah?
                        </h5>
                        <p>
                            Fitrah is the amount of aid that Muslims who fast during Ramadan give to those in need after completing their fasting. According to the Islamic religion, giving Fitrah is considered a part of completing the worship of fasting and aims to help the poor. Fitrah encourages solidarity and solidarity in society. A certain amount of money or basic foodstuffs can be given as fitrah.
                        </p>
                        <h5 class="color-main3">
                            How is Fitrah Calculated?
                        </h5>
                        <p>
                            The amount of Fitrah is set at a level sufficient to meet a person's daily basic food needs. Local religious authorities usually determine the amount of Fitrah, which may change each year. Fitrah is calculated for all members of the family and must be given by the end of Ramadan. In this context, the market value of basic foodstuffs is taken into account.
                        </p>
                        <h5 class="color-main3">
                            Fitrah Giving Methods
                        </h5>
                        <p>
                            There are various ways of giving Fitrah. You can give Fitrah directly to those in need, distribute it through authorised organisations or deliver it to local mosques. It is a traditional practice to give Fitrah, especially in the last days of Ramadan. It is important to give through reliable channels to ensure that your Fitrah reaches the most accurate and effective way.
                        </p>
                        <h5 class="color-main3">
                            The Importance of Fitrah
                        </h5>
                        <p>
                            Fitrah is considered one of the basic acts of worship in Islam and encourages helping the poor while completing the fasting worship. Giving Fitrah enables one to fulfil one's social responsibilities and promotes social justice in society. In addition, giving Fitrah is considered a spiritual worship and is an important religious duty for Muslims that must be fulfilled until the end of Ramadan.
                        </p>
                    </div>
                </ng-container>
            </div>
            
        </div>
    </div>
</div>