import { Component } from '@angular/core';

@Component({
  selector: 'app-umre-kayit',
  templateUrl: './umre-kayit.component.html',
  styleUrls: ['./umre-kayit.component.scss']
})
export class UmreKayitComponent {

}
