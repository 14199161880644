import { Component, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { Content } from 'src/app/view-models/models/content';

@Component({
  selector: 'app-sermons',
  templateUrl: './sermons.component.html',
  styleUrls: ['./sermons.component.scss']
})
export class SermonsComponent implements OnInit{
  sermons:Content[]=[];
  langauge=localStorage.getItem("language")

  first: number = 0;
  rows: number = 18;
  
  sermonsCount:number=0;

  constructor
  (
    private contentService:ContentService,
  ) {}

  ngOnInit(): void {
    this.getSermons();
  }

  getSermons(){
    this.contentService.getContents(this.langauge,8,this.first,this.rows).subscribe(data=>{
      this.sermons=data.data .filter(a => a.isActive)
      .sort((a, b) => a.order-b.order);
      this.sermonsCount=data.totalCount;
      console.log(data.totalCount)
    })
  }

  onPageChange(event) {
      this.first = event.page;
      this.rows = event.rows;
      this.getSermons();
  }

}
