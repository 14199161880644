import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { ContentService } from 'src/app/services/content.service';
import { Content } from 'src/app/view-models/models/content';
import { TabType, TabTypeList } from 'src/app/view-models/models/tab-type';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tab-contents',
  templateUrl: './tab-contents.component.html',
  styleUrls: ['./tab-contents.component.scss']
})
export class TabContentsComponent implements OnInit {

  skeletonArray = Array(5);

  isLoading$: Observable<boolean>;

  index:number=1;

  contentsLength:number=0;

  contents: Content[] = [];

  tabTypes: TabType[] = [];

  message: Content;
  messageBox: boolean = false;
  contentTypeId:number;

  language = localStorage.getItem("language");

  imageUrl: string = environment.serverUrl;

  first: number = 0;

  rows: number = 12;

  onPageChange(event) {
      this.first = event.page;
      this.rows = event.rows;
      this.getContents();
  }

  setTab(contentTypeId:number,index:number){
    this.contentTypeId=contentTypeId;
    this.first=0;
    this.index=index;
    this.getContents();
  }

  owlOptions: OwlOptions = {
    autoplayHoverPause: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoHeight: true,
    autoplay: true,
    dots: false,
    loop: true,
    margin: 40,
    items: 1,
    nav: true,
    navText: [
      "<i class='fa-solid fa-left-long'></i>",
      "<i class='fa-solid fa-right-long'></i>"
    ],
    autoplayTimeout: 10000
  }

  constructor
    (
      private contentService: ContentService
    ) {
    this.isLoading$ = contentService.isLoading$;
  }
  ngOnInit(): void {
    this.getTabTypes();
  }

  getContents() {
    this.contentService.getContents(this.language, this.contentTypeId,this.first,this.rows).subscribe(data => {
      this.contents = data.data
        .filter(a => a.isActive)
        .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
        this.contentsLength=data.totalCount;
    });

  }

  getTabTypes() {
    this.contentService.getAllTabType(this.language).subscribe((data: TabTypeList) => {
      this.tabTypes = data.data.filter(x => x.isActive).sort((a, b) => {
        return a.order - b.order;
      })
      this.contentTypeId=this.tabTypes[0].id;
      this.getContents();
    })
    
  }

  showMessage(message: Content) {
    this.message = message;
    this.messageBox = true;
  }

  getFormattedTime(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }


}

