import { environment } from './../../../../environments/environment';
import { DepartmentList } from './../../../view-models/models/department';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DepartmentService } from 'src/app/services/department.service';
import { MosqueService } from 'src/app/services/mosque-service';
import { Department } from 'src/app/view-models/models/department';
import { Mosque } from 'src/app/view-models/models/Mosque';

@Component({
  selector: 'app-camiler',
  templateUrl: './camiler.component.html',
  styleUrls: ['./camiler.component.scss']
})
export class CamilerComponent implements OnInit{
  departments:Department[]=[];
  isLoading$:Observable<boolean>;
  imageUrl=environment.serverUrl;

  language=localStorage.getItem("language")

  skeletonArray=Array(3);

  constructor(
    private departmentService:DepartmentService,
    private router:Router
  ) {
    this.isLoading$=departmentService.isLoading$;
  }
  
  // mosques:Mosque[]=[];
  // constructor(
  //   private mosqueService:MosqueService,
  //   private router:Router
  // ){}

  ngOnInit(): void {
    // this.getMosques();
    this.getDepartments()
  }

  getDepartments(){
    this.departmentService.getDepartments(10,this.language).subscribe((data:DepartmentList)=>{
      this.departments=data.data.sort((a,b)=>{
        return a.order-b.order;
      });
    })
  }

  // getMosques(){
  //   this.mosques=this.mosqueService.getAll();
  // }

  detailPage(id:number){
    this.router.navigate([`/our-mosques/${id}`])
  }

  getGoogleMapsUrl(address: string): string {
    return `https://maps.google.com/?q=${encodeURIComponent(address)}`;
  }
}
